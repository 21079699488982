// const BaseUrl = "https://dev-api.miseenplacemeals.com/api/"; //production
// const BaseUrl = "https://localhost:7274/api/"; //development

const BaseUrl = process.env.REACT_APP_API_URL
const APIS = {
  LOGIN: "Admin/authenticate",
  GOOGLE_LOGIN: 'Admin/authenticate-with-google',
  REFRESH_TOKEN: BaseUrl + "Admin/refresh-token",
  ADD_USER: BaseUrl + "User",
  UPDATE_USER: BaseUrl + "User",
  FORGOT_PASSWORD: BaseUrl + "Admin/forgotPassword",
  CHANGE_USER_PASSWORD: BaseUrl + "Admin/changeUserPassword",
  GET_USER_BY_ID: BaseUrl + "User",
  GROCERY_LIST: BaseUrl + "User/GetIngredientsByUserId",
  EMAIL: BaseUrl + "User/SubscribeEmail",
  CONTACT_US: BaseUrl + "User/contact-us",

  // recipe url
  GET_ALL_RECIPE: BaseUrl + "Recipes/GetAll",
  GET_RECIPE_BY_ID: BaseUrl + "Recipes",
  ADD_USER_RECIPES: BaseUrl + "UserRecipes",
  GET_USER_RECIPE: BaseUrl + "UserRecipes/get-user-recipes",
  GET_USER_RECIPE_DETAIL: BaseUrl + "UserRecipes/get-user-recipes-detail",
  ADD_FAVORITE_RECIPE: BaseUrl + "UserToFavoriteRecipe",
  GET_FAVORITE_RECIPES:
    BaseUrl + "UserToFavoriteRecipe/get-user-favorite-recipes",
  PAST_RECIPES: BaseUrl + "UserRecipes/get-user-past-recipes-detail",

  //payment
  DO_CHECK: BaseUrl + "Payment/do-payment",
  CHECK_SUBCRIPTION: BaseUrl + "Payment/verify-subscription",
  CANCEL_SUBCRIPTION: BaseUrl + "Payment/cancel-subscription",

  //
  CHECK_VALID_ZIP: BaseUrl + "Kroger/CheckValidZip?",
  ORDER_GROCERRIES: BaseUrl + "Kroger/OrderGroceries",
  CHECK_IF_USER_CART_EXIST: BaseUrl + "Kroger/CheckIfUserCartExist",
};

export default APIS;
