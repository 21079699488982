import { makeStyles } from "@material-ui/core";
import { Box, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import FontStyles from "../../theme/font";
import Color from "../../theme/color";
import ButtonUI from "../buttonUi";

const useStyles = makeStyles((theme) => ({
  modal: {
    border: "none",
    borderColor: "white",
  },
}));

const SubcriptionModal = ({
  open,
  onCloseHandle,
  upgradeNowAction,
}: SubcriptionModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const styleFavouriteModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMatch ? "206px" : "406px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    paddingRight: 5,
    paddingLeft: 5,
    p: "32px",
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => onCloseHandle && onCloseHandle()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ root: classes.modal }}
      >
        <Box
          sx={{ ...styleFavouriteModal }}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Typography
            sx={{
              ...FontStyles.font20Prociono,
              color: Color.color4,
              width: "100%",
            }}
            textAlign={"center"}
          >
            Your 14-day trial hase ended
          </Typography>
          <Typography
            sx={{
              ...FontStyles.font14Prociono,
              color: Color.color5,
              width: "100%",
              marginTop: "8px",
            }}
            textAlign={"center"}
          >
            But it's not too late to take next step. upgrade to paid plan to
            keep using Mise En Place
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ marginTop: "12px" }}
          >
            <ButtonUI
              variant={"outlined"}
              label={"Upgrade Now"}
              fullWidth={false}
              style={{
                width: "180px",
                backgroundColor: Color.color1,
                borderColor: Color.color1,
                height: "44px",
              }}
              lableStyle={{ ...FontStyles.font18Prociono, color: Color.color4 }}
              action={upgradeNowAction}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default SubcriptionModal;
interface SubcriptionModalProps {
  open: boolean;
  onCloseHandle?: () => void;
  upgradeNowAction?: () => void;
}
