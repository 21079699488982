/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Color from "../../theme/color";
import FontStyles from "../../theme/font";
import manuserpng from "../../aasets/images/man-user.png";
import regularcheckcricle from "../../aasets/images/regularcheck-circle (2).png";
// import CardUi from "../../components/card";
import ButtonUI from "../../components/buttonUi";
import getUserData from "../../utils/getLocalStorageData";
import { GET, POST, PUT } from "../../config/https";
import APIS from "../../config/api";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import FullScreenLoadder from "../../components/fullScreenLoadder";
import { CheckLogin } from "../../utils/checkLoign";
import LoginModal from "../../components/login";
import { useNavigate, } from "react-router-dom";
import { isEmpty, validateImageBase64 } from "src/utils/Validator";
import ValidationError from "src/components/ValidationError";
import VectorRound from "../../aasets/images/VectorRound.png";

import { toast } from "react-hot-toast";
import FilePicker from "src/components/filePicker";
import CheckSubscription from "src/utils/checkSubscription";
import ProfileImageUPdate from "src/utils/profileImageUpdte";
import ReminigDays from "src/components/reminingDays";
import { usePersistentState } from "src/contexts/GlobalContext";
import { countryList } from "src/utils/countrtData";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: Color.color1, // Replace #f00 with the desired border color on hover
    },
  },
}));

const USA = "United States of America"


const Profile = () => {
  const [categoryItem, setCategoryItem] = useState(Category);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any>({});
  const [country, setCountry] = useState('')
  const [editDetails, setEditDetails] = useState(false);
  const [editDiet, setEditDiet] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  // const [file, setFile] = useState("");
  const [paymentStatus, setPaymentStuts] = useState<any>({});
  const [imageBase64, setImageBase64] = useState("");
  const [loadder, setLoader] = useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));
  const [openModalLogin, setOpenModalLogin] = useState({
    loginModalData: false,
    isProfile: false,
  });

  const [, dispatch]: any = usePersistentState();
  const classes = useStyles();
  const navigate = useNavigate();
  // const theme = useTheme();

  // const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch({ isSearch: false });
    let checkLoginData = CheckLogin();
    setSubcriptionStuts();
    if (!checkLoginData) {
      let temp = {
        loginModalData: true,
        isProfile: true,
      };
      setOpenModalLogin(temp);
    }
    getUserById();
  }, []);

  useEffect(() => {
    if (isValidation) {
      validator();
    }
  }, [userData, isValidation]);

  const setSubcriptionStuts = async () => {
    let temp: any = await CheckSubscription();
    setPaymentStuts(temp);
  };

  useEffect(() => {
    let temp: any = [...categoryItem];
    userData.dietAttributes &&
      Object.keys(userData.dietAttributes).forEach((key) => {
        let findIndex = temp.findIndex((x: any) => x.label === key);

        if (findIndex !== -1) {
          temp[findIndex].check = Boolean(userData.dietAttributes[key]);
        }

      });
  }, [userData]);

  const setDay = (id: number) => {
    if (!editDiet) return;
    let data = categoryItem.map((item) => {
      if (item.id === id) {
        return { ...item, check: !item.check };
      }
      return item;
    });
    setCategoryItem(data);
  };

  const getUserById = async () => {
    let tempUserData = getUserData();
    if (!tempUserData) return;
    setIsLoading(true);
    let params = {
      id: tempUserData.id,
    };
    await GET(APIS.GET_USER_BY_ID, params, tempUserData.jwtToken)
      .then((res: any) => {
        if (res) {
          setUserData(res.result);
          setCountry(res?.result?.country)
          // setUserProfile(res.result);
          // setUserValue(res.result);
        }
      })
      .catch((error) => { });
    setIsLoading(false);
  };

  const loginOnClose = () => {
    let temp = getUserData();
    if (temp) {
      setOpenModalLogin({
        loginModalData: false,
        isProfile: false,
      });
    }
    if (!temp) {
      navigate("/dashboard/app");
    }
  };

  const cancelSubcription = async () => {
    let userData = getUserData();
    if (!userData) return;
    setLoader(true);
    let params = {};
    await POST(APIS.CANCEL_SUBCRIPTION, params, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          setSubcriptionStuts();
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const userDetailsChange = (e: any, key: string) => {
    let newData = { ...userData };

    if (key === "name") {
      newData.name = e.target.value;
    }
    if (key === "phone") {
      newData.mobileNumber = e.target.value;
    }
    if (key === "country") {
      newData.country = e.target.value;
    }
    if (key === "zipCode") {

      let temp = e.target.value;
      temp = temp.trim();
      const expression = /^\d+$/
      const validated = expression.test(temp);
      if (validated) {
        newData.zipCode = temp;
      }
      if (!temp) {
        newData.zipCode = temp;
      }
    }
    setUserData(newData);
  };

  const validator = () => {
    const errors: Record<string, string> = {};
    if (isEmpty(userData.name)) {
      errors.name = "Please enter a name";
    }
    if (isEmpty(country)) {
      errors.country = "Please select country";
    }
    if (imageBase64 && !validateImageBase64(imageBase64)) {
      errors.file = "Please choose Image only png,jpg,jpeg ";
    }
    if (userData.zipCode && (userData.zipCode.length > 5 || userData.zipCode.length < 5)) {
      errors.zipCode = "Please enter a 5 digit zipcode";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const updateUserRequest = async () => {
    setIsValidation(true);
    if (!validator()) return;

    var object = categoryItem.reduce(
      (obj, item) => Object.assign(obj, { [item.label]: item.check }),
      {}
    );
    setIsLoading(true);
    let tempUserData = getUserData();
    if (!tempUserData) return;
    let params = {
      id: userData.id,
      name: userData.name,
      email: userData.email,
      mobileNumber: userData.mobileNumber,
      profilePicture: imageBase64?.split(",")[1] || "",
      dietAttributes: object,
      country: country ? country : userData.country,
      zipCode: country === USA ? userData.zipCode : ""
    };

    await PUT(APIS.UPDATE_USER, params, tempUserData?.jwtToken)
      .then(async (res: any) => {
        if (res.isSuccess) {
          setUserData(res.result);
          toast.success("Update Successfully ");
          let data = await ProfileImageUPdate(res.result.id, res?.result?.jwtToken);
          dispatch({ isProfile: data })
          navigate("/dashboard/profile")
        }
      })
      .catch((error) => {
        toast.error("user not update");
      })
      .finally(() => {
        setEditDetails(false);
        setEditDiet(false);
        setIsLoading(false);
      });

    // if (!updateUser) {
    //   setUpdateUser(true);
    // }
  };
  const handleFileChange = (event: any) => {
    // setFile(event.target.value);
    let temp = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64: any = e.target.result;
      setImageBase64(base64);
    };

    reader.readAsDataURL(temp);
  };

  function marginBottomCss() {
    let size = undefined;
    if (isMatch && (!editDetails || !editDiet)) {
      size = 1150;
    }
    if (isMatch && (editDetails || editDiet)) {
      size = 1250;
    }
    if (!isMatch && editDetails) {
      size = 750;
    }
    if (!isMatch && !editDetails) {
      size = 700;
    }

    return size;
  }

  function heightCssModal() {
    let size = undefined;
    if (isMatch && (!editDetails || !editDiet)) {
      size = 1200;
    }
    if (isMatch && (editDetails || editDiet)) {
      size = 1350;
    }
    if (!isMatch && editDetails) {
      size = 800;
    }
    if (!isMatch && !editDetails) {
      size = 700;
    }

    return size;
  }

  const hideShowUpgradeButton = () => {

    if (paymentStatus?.isSubscriptionCancelled) {
      return true
    }
    if (userData?.userSubscriptions && !userData?.userSubscriptions[0]?.isActive && paymentStatus?.remainingDaysForFreeTrial >= 0) {
      return true
    }
    return false
  }


  const renderZipCode = () => {
    if (country === USA) {
      return (
        <>
          {(!userData?.zipCode && editDetails) ||
            userData?.zipCode ? (
            <Typography
              sx={{
                marginTop: 2,
                ...FontStyles.font16Prociono,
                color: Color.color4,
              }}
            >
              Zip Code
            </Typography>
          ) : null}
          {editDetails ? (
            <>
              <TextField
                sx={{ borderColor: Color.color1, width: "100%" }}
                size="small"
                defaultValue={userData?.zipCode}
                value={userData?.zipCode}
                className={classes.textField}
                type="tel"
                inputProps={{
                  maxLength: 30,
                }}
                onChange={(e) => userDetailsChange(e, "zipCode")}
              />
              <ValidationError formErrors={formErrors.zipCode} />
            </>
          ) : (
            <Typography
              sx={{
                ...FontStyles.font16Prociono,
                color: Color.color10,
              }}
            >
              {userData?.zipCode}
            </Typography>
          )}
        </>
      )

    }

    return null

  }


  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: Color.color1,
              width: "100%",
              height: "220px",
              top: 20,
            }}
          >
            <Box
              sx={{ width: "100%", height: isMatch && isMatch ? "60%" : "50%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                sx={{ ...FontStyles.font32Prociono, color: Color.color4 }}
              >
                My Profile
              </Typography>
            </Box>
            <Box
              sx={{ width: "100%", height: isMatch && isMatch ? "40%" : "50%" }}
            ></Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Box
              sx={{
                backgroundColor: Color.white,
                position: "absolute",
                // height: isMatch && isMatch ? "1000px" : "700px",
                // height: isMatch ? "1200px" : "700px",
                height: heightCssModal(),
                width: isMatch ? "70%" : "50%",
                top: isMatch ? 180 : 150,
                borderRadius: 3,
                boxShadow: 3,
                padding: 3,
                paddingBottom: 2,
                flexGrow: 1,
              }}

            //   bottom: IsPhone() ? undefined : 300,
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  xl={4}
                  lg={4}
                  padding={1}
                  sx={{ backgroundColor: Color.color11, borderRadius: "8px" }}
                >
                  <Box sx={{ padding: "14px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {userData?.profilePicturePath ? (
                        <img
                          alt="profile"
                          src={userData?.profilePicturePath}
                          style={{
                            height: "200px",
                            width: "100%",
                            borderRadius: "4px",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: Color.white,
                            width: "100%",
                            height: "200px",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* <PersonIcon
                            style={{ width: "100%", height: "288px" }}
                          /> */}
                          <img
                            alt="profile"
                            src={manuserpng}
                            style={{
                              height: "180px",
                              width: "100%",
                            }}
                          />
                        </div>
                      )}

                    </div>
                    {/* </Typography> */}
                    {/* {profileData.map((x) => {
                    return (
                      <> */}
                    {editDetails ? (
                      <Box sx={{ marginTop: 1 }}>
                        <Typography
                          sx={{
                            marginTop: 2,
                            ...FontStyles.font16Prociono,
                            color: Color.color4,
                          }}
                        >
                          User Profile
                        </Typography>
                        <FilePicker onChange={handleFileChange} />

                        <ValidationError formErrors={formErrors.file} />
                      </Box>
                    ) : null}

                    <Typography
                      sx={{
                        marginTop: 2,
                        ...FontStyles.font16Prociono,
                        color: Color.color4,
                      }}
                    >
                      {/* {x.key} */}
                      Name
                    </Typography>
                    {editDetails ? (
                      <>
                        <TextField
                          sx={{ borderColor: Color.color1, width: "100%" }}
                          size="small"
                          defaultValue={userData?.name}
                          className={classes.textField}
                          inputProps={{
                            maxLength: 30,
                          }}
                          onChange={(e) => userDetailsChange(e, "name")}
                        />
                        <ValidationError formErrors={formErrors.name} />
                      </>
                    ) : (
                      <Typography
                        sx={{
                          ...FontStyles.font16Prociono,
                          color: Color.color10,
                        }}
                      >
                        {userData.name}
                      </Typography>
                    )}

                    <Typography
                      sx={{
                        marginTop: 2,
                        ...FontStyles.font16Prociono,
                        color: Color.color4,
                      }}
                    >
                      Email
                    </Typography>
                    {editDetails ? (
                      <>
                        <TextField
                          disabled
                          sx={{ borderColor: Color.color1, width: "100%" }}
                          size="small"
                          defaultValue={userData?.email}
                          className={classes.textField}
                          inputProps={{
                            maxLength: 30,
                          }}
                          onChange={(e) => userDetailsChange(e, "email")}
                        />
                        <ValidationError formErrors={formErrors.email} />
                      </>
                    ) : (
                      <Typography
                        sx={{
                          ...FontStyles.font16Prociono,
                          color: Color.color10,
                        }}
                      >
                        {userData?.email}
                      </Typography>
                    )}
                    {(!userData?.mobileNumber && editDetails) ||
                      userData?.mobileNumber ? (
                      <Typography
                        sx={{
                          marginTop: 2,
                          ...FontStyles.font16Prociono,
                          color: Color.color4,
                        }}
                      >
                        Phone
                      </Typography>
                    ) : null}
                    {editDetails ? (
                      <>
                        <TextField
                          sx={{ borderColor: Color.color1, width: "100%" }}
                          size="small"
                          defaultValue={userData?.mobileNumber}
                          className={classes.textField}
                          inputProps={{
                            maxLength: 30,
                          }}
                          onChange={(e) => userDetailsChange(e, "phone")}
                        />
                        <ValidationError formErrors={formErrors.phone} />
                      </>
                    ) : (
                      <Typography
                        sx={{
                          ...FontStyles.font16Prociono,
                          color: Color.color10,
                        }}
                      >
                        {userData?.mobileNumber}
                      </Typography>
                    )}
                    {(!userData?.country && editDetails) ||
                      userData?.country ? (
                      <Typography
                        sx={{
                          marginTop: 2,
                          ...FontStyles.font16Prociono,
                          color: Color.color4,
                        }}
                      >
                        Country
                      </Typography>
                    ) : null}
                    {editDetails ? (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={countryList}
                          size="small"
                          onChange={(event: any, newValue) => {
                            setCountry(newValue ? newValue : ''); // Update the selectedCountry state when a new value is selecte
                          }}
                          value={country}
                          sx={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <ValidationError formErrors={formErrors.country} />
                      </>
                    ) : (
                      <Typography
                        sx={{
                          ...FontStyles.font16Prociono,
                          color: Color.color10,
                        }}
                      >
                        {userData?.country}
                      </Typography>
                    )}

                    {renderZipCode()}

                    <ButtonUI
                      label={editDetails ? "Update Details" : "Edit Details"}
                      variant="outlined"
                      style={{
                        whiteSpace: "nowrap",
                        backgroundColor: Color.color1,
                        width: "100%",
                        marginTop: 15,
                        borderColor: Color.color1,
                      }}
                      action={() => {
                        if (editDetails) {
                          updateUserRequest();
                        } else {
                          setEditDetails(true);
                        }
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={8} xl={8} padding={1}>
                  <Grid container padding={2}>
                    <Grid xs={10} sm={6}>
                      <Typography
                        style={{
                          ...FontStyles.font24Prociono,
                          color: Color.color4,
                        }}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        Diet Attributes
                      </Typography>
                    </Grid>
                    <Grid
                      xs={2}
                      sm={6}
                      container
                      alignItems="center"
                      justifyContent={"flex-end"}
                    >
                      <div onClick={() => setEditDiet(!editDiet)}>
                        <Typography
                          textAlign={"right"}
                          style={{
                            ...FontStyles.font16Prociono,
                            color: Color.color6,
                            cursor: "pointer",
                          }}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Edit
                        </Typography>
                      </div>
                    </Grid>
                    <Box
                      sx={{
                        width: "100%",
                        height: 1,
                        border: 1,
                        marginTop: 1,
                        color: Color.color17,
                      }}
                    ></Box>
                    <Grid
                      container
                      spacing={{ xs: 1, md: 1, sm: 1 }}
                      columns={{ xs: 12, sm: 12, md: 12 }}
                    >
                      {categoryItem.map((x: any, index) => {
                        return (
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={4}
                            key={index}
                            sx={{ marginTop: 1 }}
                          >
                            <Box
                              sx={{
                                flexGrow: 1,
                                width: "110px",
                                height: "32px",
                              }}
                              display="inline-flex"
                              alignSelf={"center"}
                              alignItems={"center"}
                              justifyContent={"left"}
                            >
                              <Box
                                display="inline-flex"
                                flexDirection={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                alignSelf={"center"}
                                textAlign={"center"}
                                paddingLeft={1}
                                onClick={() => setDay(x.id)}
                              >
                                <IconButton sx={{ padding: 0 }}>
                                  {x.check ? (
                                    <img
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      alt="check"
                                      src={regularcheckcricle}
                                    />
                                  ) : (
                                    <img
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        paddingLeft: "2px",
                                        paddingRight: "2px",
                                      }}
                                      alt="check"
                                      src={VectorRound}
                                    />
                                  )}
                                </IconButton>
                                <Typography
                                  style={{
                                    ...FontStyles.font12Prociono,
                                    color: Color.color4,
                                    paddingLeft: 5,
                                  }}
                                >
                                  {x.name}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {editDiet ? (
                      <div style={{ marginTop: 5 }}>
                        {/* <ButtonUI
                          label="Update"
                          variant="outlined"
                          isLoading={isLoading}
                          style={{
                            backgroundColor: Color.color1,
                            borderColor: Color.color1,
                            height: "30px",
                          }}
                          lableStyle={{
                            ...FontStyles.font16Prociono,
                            color: Color.color4,
                          }}
                          action={() => {
                            updateUserRequest();
                          }}
                        /> */}
                        <ButtonUI
                          // isLoading={isLoading}
                          label="Update"
                          variant="outlined"
                          style={{
                            whiteSpace: "nowrap",
                            backgroundColor: Color.color1,
                            width: "50%",
                            height: "35px",
                            marginTop: 15,
                            borderColor: Color.color1,
                          }}
                          lableStyle={{
                            ...FontStyles.font16Prociono,
                            color: Color.color4,
                          }}
                          action={() => {
                            updateUserRequest();
                          }}
                        />
                        <ButtonUI
                          // isLoading={isLoading}
                          label="Cancel"
                          variant="text"
                          style={{
                            whiteSpace: "nowrap",

                            width: "30%",
                            height: "35px",
                            marginTop: 15,

                            marginLeft: 15,
                          }}
                          lableStyle={{
                            ...FontStyles.font16Prociono,
                            color: Color.red,
                          }}
                          action={() => {
                            setEditDiet(false);
                          }}
                        />
                      </div>
                    ) : null}
                    {/* <Grid container marginTop={3}>
                    <Grid xs={10} sm={6}>
                      <Typography
                        style={{
                          ...FontStyles.font24Prociono,
                          color: Color.color4,
                        }}
                      >
                        My Favorite
                      </Typography>
                    </Grid>
                    <Grid
                      xs={2}
                      sm={6}
                      container
                      alignItems="flex-end"
                      justifyContent={"flex-end"}
                    >
                      <Typography
                        textAlign={"right"}
                        style={{
                          ...FontStyles.font16Prociono,
                          color: Color.color6,
                        }}
                      >
                        Edit
                      </Typography>
                    </Grid>
                  </Grid> */}
                    <Box
                      sx={{
                        width: "100%",
                        height: 1,
                        border: 1,
                        marginTop: 1,
                        marginBottom: 1,
                        color: Color.color17,
                      }}
                    ></Box>
                    <Typography
                      style={{
                        ...FontStyles.font24Prociono,
                        color: Color.color4,
                        width: "100%",
                        marginTop: 1,
                      }}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      Subscription details
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        height: 1,
                        border: 1,
                        marginTop: 1,
                        color: Color.color17,
                      }}
                    ></Box>
                    <div style={{ width: "100%", marginTop: "10px" }}>
                      {paymentStatus?.remainingDaysForFreeTrial > 0 ? <Typography sx={{ ...FontStyles.font16Prociono, color: Color.color4, width: '100%' }}>
                        Status: Free Trial
                      </Typography> : <Typography sx={{ ...FontStyles.font16Prociono, color: Color.color4, width: '100%' }}>
                        Status: {userData?.userSubscriptions && userData?.userSubscriptions[0]?.isActive ? "Active" : "Not Active"}
                      </Typography>}

                      <ReminigDays
                        lable="Member Since:"
                        date={userData?.createdDate}
                      />
                      {!paymentStatus?.isFreeTrialEnded && paymentStatus?.remainingDaysForFreeTrial > 0 ? (
                        <Grid container spacing={0.5}>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{
                                ...FontStyles.font16Prociono,
                                color: Color.color4,
                              }}
                            >
                              Remaining Days For Free Trial:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{
                                ...FontStyles.font16Prociono,
                                color: Color.color10,
                              }}
                            >
                              {paymentStatus?.remainingDaysForFreeTrial}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : null}

                      {paymentStatus && paymentStatus?.isFreeTrialEnded &&
                        paymentStatus?.startDate &&
                        (
                          <ReminigDays
                            lable="Start Date :"
                            date={paymentStatus && paymentStatus?.startDate ? paymentStatus?.startDate : ''}
                          />
                        )}

                      {paymentStatus && !paymentStatus?.isSubscriptionRequired &&
                        paymentStatus?.endDate && !userData?.userSubscriptions[0]?.isActive ? (
                        <ReminigDays
                          lable="End Date :"
                          date={paymentStatus && paymentStatus?.endDate}
                        />
                      ) : null}
                    </div>
                    {paymentStatus && paymentStatus?.isSubscriptionCancelled && (
                      <Typography
                        variant="body1"
                        sx={{
                          ...FontStyles.font16Prociono,
                          color: Color.color6,
                          marginTop: "10px",
                          width: "100%",
                        }}
                      >
                        {"Your subscription has been cancelled"}
                      </Typography>
                    )}

                    {paymentStatus && paymentStatus?.isFreeTrialEnded &&
                      !paymentStatus?.isSubscriptionCancelled &&
                      !paymentStatus?.isSubscriptionRequired ? (
                      <ButtonUI
                        label="Cancel Subscription"
                        variant="outlined"
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor: Color.color1,
                          width: isMatch ? "170px" : "200px",
                          marginTop: 15,
                          borderColor: Color.color1,
                        }}
                        action={() => cancelSubcription()}
                        isLoading={loadder}
                      />
                    ) : null}

                    {hideShowUpgradeButton() ? (
                      <ButtonUI
                        label="Upgrade Now"
                        variant="outlined"
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor: Color.color1,
                          width: isMatch ? "170px" : "200px",
                          marginTop: 15,
                          borderColor: Color.color1,
                        }}
                        action={() => navigate("/dashboard/subscriptionplan")}
                      />
                    ) : null}

                    {/* <Grid container marginTop={2}>
                    {TabBar.map((item, index: number) => {
                      return (
                        <Box
                          sx={{
                            border: 1,
                            borderLeft: index == 0 ? 1 : 0,
                            borderColor: Color.color19,
                            backgroundColor:
                              recipeTab == index ? Color.color1 : null,
                            width: "20%",
                            height: 40,
                          }}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onClick={() => {
                            setRecipeTab(index);
                          }}
                        >
                          <Typography
                            textAlign={"center"}
                            style={{
                              ...FontStyles.font16Prociono,
                              color: Color.color4,
                            }}
                          >
                            {item.key}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Grid> */}
                    {/* <CardUi
                    headerAlignTop={false}
                    cardSize={cardSize}
                    gridSize={gridSize}
                    cardData={MealPrepRecipes}
                  /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ height: marginBottomCss() }}
        // isMatch ? 900 : 500
        ></Grid>
      </Grid>
      {openModalLogin ? (
        <LoginModal
          open={openModalLogin}
          onClose={() => {
            loginOnClose();
          }}
        />
      ) : null}
      <FullScreenLoadder open={isLoading} />
    </>
  );
};
export default Profile;

const Category = [
  {
    id: 1,
    name: "Vegetarian",
    label: "vegetarian",
    check: false,
  },
  {
    id: 2,
    name: "Nut-Free",
    label: "nutFree",
    check: false,
  },
  {
    id: 3,
    name: "Soy-Free",
    label: "soyFree",
    check: false,
  },
  {
    id: 4,
    name: "Egg-Free",
    label: "eggFree",
    check: false,
  },
  {
    id: 5,
    name: "Dairy Free",
    label: "dairyFree",
    check: false,
  },
  {
    id: 6,
    name: "Wheat Free",
    label: "wheatFree",
    check: false,
  },
];

// const TabBar = [
//   {
//     id: 1,
//     key: "Top Recipes",
//   },
//   {
//     id: 2,
//     key: "Dinner",
//   },
//   {
//     id: 3,
//     key: "Lunch",
//   },
//   {
//     id: 4,
//     key: "Breakfast",
//   },
//   {
//     id: 5,
//     key: "Snack",
//   },
// ];
