import { Navigate, useRoutes } from "react-router-dom";

import DashboardAppPage from "./pages/DashboardAppPage";
import DashboardLayout from "./layouts/dashboard/DashboradLayout";
import SimpleLayout from "./layouts/simple/SimpleLayout";
import Page404 from "./pages/Page404";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import SignUp from "./pages/signUp";
import ChoosePassword from "./pages/choosePassword";
import RecipeDetailsPage from "./pages/recipeDetailsPage";
import Recipes from "./pages/recipes";
import Grocery from "./pages/grocery";
import Favorites from "./pages/favorite";
import Profile from "./pages/profile";
import ComingSoon from "./pages/comingSoon";
import PastRecpies from "./pages/recipes/pastRecipes";
import AllPasteRecipes from "./pages/recipes/pastRecpiesCategories";
import SubscriptionPlan from "./pages/SubscriptionPlan";
import ContactUs from "./pages/contactus/index";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Home from "./pages/home";

// import Profile from "./pages/profile";
// import Favorites from "./pages/favorites";

// import RecipeDetailsPage from "./pages/recipeDetailsPage";
// import Profile from "./pages/profile";

// layouts

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    // {
    //   path: "*",
    //   element: <DashboardLayout />,
    //   children: [{ path: "", element: <DashboardAppPage /> }],
    // },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        {
          path: "*",
          element: <Navigate to="/dashboard/app" />,
          index: true,
        },
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <DashboardAppPage /> },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "recipedetail",
          element: <RecipeDetailsPage />,
        },
        {
          path: "myrecipes",
          element: <Recipes />,
        },
        {
          path: "pastrecipes",
          element: <PastRecpies />,
        },
        {
          path: "allpastrecipes",
          element: <AllPasteRecipes />,
        },
        {
          path: "subscriptionplan",
          element: <SubscriptionPlan />,
        },
        {
          path: "grocery",
          element: <Grocery />,
          children: [{
            element: <Navigate to="/dashboard/:code" />,
            index: true,
          }],
        },
        {
          path: "grocery/:code",
          element: <Grocery />,
        },
        {
          path: "favorite",
          element: <Favorites />,
        },
        {
          path: "contactus",
          element: <ContactUs />,
        },
        // {
        //   path: "terms",
        //   element: <Terms />,
        // },
        // {
        //   path: "privacy",
        //   element: <Privacy />,
        // },
        {
          path: "profile",
          element: <Profile />,
        },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        // {
        //   path: "*",
        //   element: <ComingSoon />,
        // },
        {
          path: "*",
          element: <Home />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "forgotpassword",
          element: <ForgotPassword />,
        },
        {
          path: "signup",
          element: <SignUp />,
        },
        {
          path: "ChoosePassword",
          element: <ChoosePassword />,
        },
        {
          path: "terms",
          element: <Terms />,
        },
        {
          path: "privacy",
          element: <Privacy />,
        },
        {
          path: "subscriptionplan",
          element: <SubscriptionPlan />,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ]);

  return routes;
}
