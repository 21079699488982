/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Link,
  Typography,
  IconButton,
  SnackbarOrigin,
} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";

import Styles from "./styles";
import comonStyles from "../comonStyles";
import Google from "../../aasets/images/Socialicon.png";
import TextBox from "../../components/textBox";
import ButtonUI from "../../components/buttonUi";
import ComonStyles from "../comonStyles";
import { isEmpty, isValidEmail } from "../../utils/Validator";
import { BASEURL, POST } from "../../config/https";
import APIS from "../../config/api";
import { usePersistentState } from "../../contexts/GlobalContext";
import ValidationError from "../../components/ValidationError";
import { toast } from "react-hot-toast";
import ProfileImageUPdate from "src/utils/profileImageUpdte";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { GOOGLE_LOGIN_KEY } from "src/utils/constant";
import FullScreenLoadder from "src/components/fullScreenLoadder";
export interface State extends SnackbarOrigin {
  open: boolean;
}

const Login = () => {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [user, setUser] = useState<any>([]);
  const [, dispatch]: any = usePersistentState();

  const navigate = useNavigate();

  useEffect(() => {
    if (isValidation) {
      validator();
    }
  }, [email, password, isValidation]);

  const onChangeText = (key: string, e: any) => {
    switch (key) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
    }
  };

  const signIn = async () => {
    setIsValidation(true);
    if (!validator()) return;
    setIsLoading(true);
    const params = {
      email: email,
      password: password,
    };

    await POST(APIS.LOGIN, params)
      .then(async (res: any) => {
        if (res.isSuccess) {
          if (res.result.roles[0].name === "User") {
            await localStorage.setItem("userData", JSON.stringify(res.result));
            await dispatch({ users: res.result });
            let temp = await ProfileImageUPdate(
              res?.result?.id,
              res?.result?.jwtToken
            );
            dispatch({ isProfile: temp })
            navigate("/dashboard/app");
          }
        } else {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
    setIsLoading(false);
  };

  const validator = () => {
    const errors: Record<string, string> = {};
    if (isEmpty(email)) {
      errors.email = "Please enter a email";
    }
    if (!isEmpty(email) && !isValidEmail(email)) {
      errors.email = "Please enter a valid email";
    }
    if (isEmpty(password)) {
      errors.password = "Please enter a password";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // setProfile(res.data);
        })
        .catch((err) => { });
    }
  }, [user]);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse: any) => googleWithLogin(codeResponse),
    onError: (error) => { },
  });

  const googleWithLogin = async (res: any) => {
    if (!res) return;
    let params = {
      accessToken: res.access_token,
    };
    setIsGoogleLogin(true);
    axios
      .post(BASEURL + APIS.GOOGLE_LOGIN, params, {
        headers: {
          Accept: "application/json",
          "x-api-key": GOOGLE_LOGIN_KEY,
        },
      })
      .then(async (response: any) => {
        setIsGoogleLogin(false);
        const res = response.data;
        if (res.isSuccess) {
          if (res?.result?.roles[0]?.name === "User") {
            await localStorage.setItem("userData", JSON.stringify(res.result));
            await dispatch({ users: res.result });
            let temp = await ProfileImageUPdate(
              res?.result?.id,
              res?.result?.jwtToken
            );
            dispatch({ isProfile: temp })
            navigate("/dashboard/app");
          }
        } else {
          setIsGoogleLogin(false);
          toast.error(res?.errors[0]);
        }
      })
      .catch((err) => {
        setIsGoogleLogin(false);
      })
      .finally(() => {
        setIsGoogleLogin(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs" sx={Styles.continer}>
      <Box sx={comonStyles.cardStyle}>
        <Typography style={comonStyles.headerText}>Sign In</Typography>
        <Typography style={comonStyles.headerDecText}>
          Please Sign In to Continue.
        </Typography>
        <TextBox
          id={"email"}
          placeholder={"Email"}
          required={true}
          fullWidth={true}
          label={"Email"}
          name={"email"}
          icon={"Email"}
          onChange={(e) => onChangeText("email", e)}
        />
        <ValidationError formErrors={formErrors.email} />

        <TextBox
          id={"password"}
          placeholder={"Password"}
          required={true}
          fullWidth={true}
          label={"Password"}
          name={"password"}
          icon={"LockIcon"}
          onChange={(e) => onChangeText("password", e)}
          passwordVisibaleData={true}
        />

        <ValidationError formErrors={formErrors.password} />
        <Box
          display="inline-flex"
          flexDirection={"row"}
          sx={{
            width: "100%",
            marginTop: "16px",
            height: 21,
            justifyContent: "space-between",
          }}
        >
          <Box display="inline-flex" flexDirection={"row"}>
            <IconButton
              onClick={() => setRememberMe(!rememberMe)}
              sx={{ padding: 0 }}
            >
              {rememberMe ? (
                <CheckBoxOutlinedIcon fontSize="small" />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
              )}
            </IconButton>
            <Typography style={Styles.remeberMeText}>Remember Me</Typography>
          </Box>
          <Link href="forgotpassword" style={Styles.forgetPasswordText} sx={{}}>
            Forgot password?
          </Link>
        </Box>

        <ButtonUI
          type={"submit"}
          label={"Sign In"}
          variant={"contained"}
          sx={{ mt: 3, mb: 2 }}
          style={ComonStyles.SignInUpButton}
          fullWidth={true}
          action={signIn}
          isLoading={isLoading}
        />
        <ButtonUI
          type={"submit"}
          label={"Sign in with Google"}
          variant={"outlined"}
          style={comonStyles.GoogleButtonTxt}
          fullWidth={true}
          image={Google}
          action={() => loginWithGoogle()}
        />
        <Box
          display="inline-flex"
          flexDirection={"row"}
          sx={{
            width: "100%",
            marginTop: "16px",
            height: 21,
            mt: 4,
          }}
        >
          <Typography style={comonStyles.anAccountText} textAlign="center">
            Don’t have an account yet?
            <Link href="signup" style={comonStyles.singUpInTextLinkButton}>
              Sign Up
            </Link>
          </Typography>
        </Box>
      </Box>
      <FullScreenLoadder open={isGoogleLogin} />
    </Container>
  );
};

export default Login;
