/* eslint-disable @typescript-eslint/no-unused-vars */
const GetUserProfile = () => {
  let temp: any = localStorage.getItem("profileImage");
  let image = "";
  if (temp) {
    let userData = JSON.parse(temp);
    return (image = userData);
  }

  return "";
};
export default GetUserProfile;
