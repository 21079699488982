/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FontStyles from "../../theme/font";
import Color from "../../theme/color";
import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import moment from "moment";
import selectedWeek from "../../aasets/images/selectedWeek.png";
import notSelectedWeek from "../../aasets/images/notSelectedWeek.png";
import { POST } from "../../config/https";
import APIS from "../../config/api";
import getUserData from "../../utils/getLocalStorageData";
import {
  cuurentWeeKFirstDateAndDay,
  nextWeekFistDay,
} from "../../utils/dateMethod";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { toast } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    border: "none",
  },
  hoverEffect: {
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#D3D3D3",
    },
  },
}));

const DaysModal = ({
  open,
  onClose,
  data,
  pageTitle,
  refresh,
}: DaysModalProps) => {
  const [checkedDay, setCheckedDay] = useState(Day14);
  const [recipeDateId, setRecipeDateId] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  let currentWeek = getFirstDayOfCurrentWeek();
  let nextWeek = getFirstDayOfNextWeek();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();

  const diff = currentDayOfWeek > 0 ? currentDayOfWeek - 1 : 6;

  let firstDayOfCurrentWeek = new Date(currentDate);
  firstDayOfCurrentWeek.setDate(currentDate.getDate() - diff);

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      getUserRecipe();
    }
  }, [open]);

  const recipeIdDateSet = (dataitem: any) => {
    const temp: any = checkedDay.map((x: any, index) => {
      let itemId =
        dataitem[index].recipeId === JSON.stringify(data)
          ? dataitem[index].recipeId
          : "";
      return {
        ...x,
        date: dataitem[index].date,
        itemId: itemId,
        check: itemId ? true : false,
      };
    });

    setCheckedDay(temp);
  };

  const getUserRecipe = async () => {
    let userData = getUserData();
    let token = userData?.jwtToken;

    if (!token) return;
    setLoader(true);
    let firstDate = cuurentWeeKFirstDateAndDay();
    let endDate = nextWeekFistDay();
    let params = {
      startDate: firstDate,
      endDate: endDate,
    };
    // let date_today = new Date();
    // let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate() - date_today.getDay()));

    // let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
    //   - date_today.getDay() + 6));

    // const firstDate = last_day_of_the_week
    // var nextWeekLastDate = new Date(firstDate.getTime() + 7 * 24 * 60 * 60 * 1000);

    // let params = {
    //   startDate: first_day_of_the_week,
    //   endDate: nextWeekLastDate,
    // };

    await POST(APIS.GET_USER_RECIPE, params, token)
      .then(async (res: any) => {
        if (res.isSuccess) {
          setRecipeDateId(res.result.recipes);
          await recipeIdDateSet(res.result.recipes);
        }
      })
      .catch((error) => { })
      .finally(() => {
        setLoader(false);
      });
  };

  const setDay = (isMatched: boolean, index: number) => {
    let current = new Date();
    let firstDate = moment(current).format("YYYY-MM-DD");
    let secondDate = moment(recipeDateId[index].date).format("YYYY-MM-DD");

    if (secondDate >= firstDate) {
      let newCheckDay = [...recipeDateId];
      let split = newCheckDay[index].recipeId.split();
      let totalIdLength = newCheckDay[index]?.recipeId?.length;

      if (isMatched) {
        // const findIndex = split.findIndex(
        //   (item: any) => item === JSON.stringify(data)
        // );
        const dataArray = split[0].split(",").map(Number);
        // const findIndex = dataArray.indexOf(data);
        const filteredArray = dataArray.filter((item: any) => item !== data);
        // split.splice(findIndex, 1);
        // newCheckDay[index].recipeId = split.join();
        newCheckDay[index].recipeId = filteredArray.join();
      } else {
        split.push(data);
        newCheckDay[index].recipeId =
          totalIdLength === 0 ? JSON.stringify(data) : split.join();
      }
      setCheckedDay(newCheckDay);
    }
  };

  function getFirstDayOfCurrentWeek() {
    const today = new Date();
    const currentDay = today.getDay();
    const diff = currentDay === 0 ? 0 : 0 - currentDay;
    const firstDayOfCurrentWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + diff
    );
    return firstDayOfCurrentWeek;
  }

  function getFirstDayOfNextWeek() {
    const today = new Date();
    const currentDay = today.getDay();
    const diff = 6 - currentDay;
    const firstDayOfNextWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + diff + 1
    );
    return firstDayOfNextWeek;
  }

  const addDaysApi = async () => {
    let userData = getUserData();
    let token = userData.jwtToken;
    let firstDate = cuurentWeeKFirstDateAndDay();
    let endDate = nextWeekFistDay();
    let params: any = {
      UserId: userData.id,
      StartDate: firstDate,
      EndDate: endDate,
      Recipes: recipeDateId,
    };
    await POST(APIS.ADD_USER_RECIPES, params, token)
      .then((res: any) => {
        toast.success("Recipe Added Successfully");
        setCheckedDay(Day14);
        onClose(true);
      })
      .catch((error) => { });
  };

  const styleDayModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMatch ? "288px" : "866px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 100,
    p: "24px",
  };

  const getColor = (isMatched: boolean, notCheckDate: boolean) => {
    if (isMatched) {
      return Color.color15;
    }
    if (notCheckDate) {
      return Color.color17;
    }
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ root: classes.modal }}
    >
      {loader ? (
        <Box
          sx={{
            ...styleDayModal,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
          }}
        >
          <CircularProgress style={{ color: Color.color15 }} />
        </Box>
      ) : (
        <Box sx={styleDayModal}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              style={{
                ...FontStyles.font20Prociono,
                color: Color.color10,
              }}
            >
              Select the day you would like to add this Recipe
            </Typography>
            <Typography>
              <IconButton onClick={() => onClose(false)}>
                <CloseOutlinedIcon sx={{ color: "black" }} />
              </IconButton>
            </Typography>
          </Box>
          <Typography
            sx={{
              ...FontStyles.font12Prociono,
              color: Color.color4,
              marginTop: "12px",
              marginBottom: "8px",
            }}
            color="text.secondary"
          >
            {`Week of  ${moment(currentWeek).format("MMMM Do")}`}
          </Typography>
          <Grid
            container
            spacing={{ xs: 1, md: 1, sm: 1 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            {recipeDateId &&
              recipeDateId.map((x: any, index: number) => {
                let current = new Date();
                let firstDate = moment(current).format("YYYY-MM-DD");
                let secondDate = moment(recipeDateId[index].date).format(
                  "YYYY-MM-DD"
                );

                const day = moment(new Date(x.date)).add(7, 'hours').format("dddd");
                // const day = moment(x.date).format("dddd");
                const itemId = x.recipeId.split(",");
                const isMatched = itemId.some((item: any) => {
                  return item === JSON.stringify(data);
                });

                const notCheckDate = secondDate >= firstDate ? false : true;

                const bgcolor = getColor(isMatched, notCheckDate);

                if (index < 7) {
                  return (
                    <Grid item xs={6} sm={6} md={12 / 7} key={index}>
                      <Box
                        // className={classes.hoverEffect}
                        sx={{
                          flexGrow: 1,
                          borderRadius: 2,
                          border: isMatched ? 0 : 1,
                          borderStyle: "dashed",
                          borderColor: Color.color16,
                          width: "110px",
                          height: "32px",
                          cursor: "pointer",
                          background: bgcolor,
                        }}
                        display="inline-flex"
                        alignSelf={"center"}
                        alignItems={"center"}
                        justifyContent={"left"}
                        onClick={() => setDay(isMatched, index)}
                      >
                        <Box
                          display="inline-flex"
                          flexDirection={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                          textAlign={"center"}
                          paddingLeft={1}
                        >
                          <IconButton sx={{ padding: 0 }}>
                            {isMatched ? (
                              <img
                                src={selectedWeek}
                                alt="check"
                                style={{ height: "16px", width: "16px" }}
                              />
                            ) : (
                              <img
                                src={notSelectedWeek}
                                alt="check"
                                style={{ height: "20px", width: "20px" }}
                              />
                            )}
                          </IconButton>
                          <Typography
                            style={{
                              ...FontStyles.font12Prociono,
                              color: Color.color4,
                              paddingLeft: 5,
                            }}
                          >
                            {day}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                }
              })}
          </Grid>
          <Typography
            sx={{
              ...FontStyles.font12Prociono,
              color: Color.color4,
              marginTop: 2,
              marginBottom: "8px",
            }}
            color="text.secondary"
          >
            {`Week of  ${moment(nextWeek).format("MMMM Do")}`}
          </Typography>
          <Grid
            container
            spacing={{ xs: 1, md: 1, sm: 1 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            {recipeDateId &&
              recipeDateId.map((x: any, index: number) => {
                let current = new Date();
                let firstDate = moment(current).format("YYYY-MM-DD");
                let secondDate = moment(recipeDateId[index].date).format(
                  "YYYY-MM-DD"
                );
                const day = moment(new Date(x.date)).add(7, 'hours').format("dddd");
                // const day = moment(x.date).format("dddd");
                const itemId = x.recipeId.split(",");
                const isMatched = itemId.some((item: any) => {
                  return item === JSON.stringify(data);
                });
                const notCheckDate = secondDate >= firstDate ? false : true;

                const bgcolor = getColor(isMatched, notCheckDate);

                if (index > 6) {
                  return (
                    <Grid item xs={6} sm={6} md={12 / 7} key={index}>
                      <Box
                        // className={classes.hoverEffect}
                        sx={{
                          flexGrow: 1,
                          borderRadius: 2,
                          border: isMatched ? 0 : 1,
                          borderStyle: "dashed",
                          borderColor: Color.color16,
                          width: "110px",
                          height: "32px",
                          cursor: "pointer",
                          background: bgcolor,
                        }}
                        display="inline-flex"
                        alignSelf={"center"}
                        alignItems={"center"}
                        justifyContent={"left"}
                        onClick={() => setDay(isMatched, index)}
                      >
                        <Box
                          display="inline-flex"
                          flexDirection={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                          textAlign={"center"}
                          paddingLeft={1}
                        >
                          <IconButton
                            // onClick={() => setDaySecond(x.id)}
                            sx={{ padding: 0 }}
                          >
                            {isMatched ? (
                              <img
                                src={selectedWeek}
                                alt="check"
                                style={{ height: "16px", width: "16px" }}
                              />
                            ) : (
                              <img
                                src={notSelectedWeek}
                                alt="check"
                                style={{ height: "20px", width: "20px" }}
                              />
                            )}
                          </IconButton>
                          <Typography
                            style={{
                              ...FontStyles.font12Prociono,
                              color: Color.color4,
                              paddingLeft: 5,
                            }}
                          >
                            {day}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                }
              })}
          </Grid>
          <Button
            style={{
              marginTop: 20,
              backgroundColor: Color.color1,
              ...FontStyles.font12Prociono,
              color: Color.color4,
              borderRadius: 5,
              textTransform: "none",
            }}
            variant="contained"
            onClick={addDaysApi}
          >
            {"Save"}
          </Button>
          {/* </Box> */}{" "}
        </Box>
      )}
    </Modal>
  );
};
export default DaysModal;

interface DaysModalProps {
  open: boolean;
  onClose: (refreshData: boolean) => void;
  data: string;
  pageTitle: string;
  refresh?: (refreshData: boolean) => void;
}

const Day14 = [
  {
    id: 0,
    name: "Sunday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 1,
    name: "Monday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 2,
    name: "Tuesday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 3,
    name: "Wednesday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 4,
    name: "Thursday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 5,
    name: "Friday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 6,
    name: "Saturday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 7,
    name: "Sunday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 8,
    name: "Monday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 9,
    name: "Tuesday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 10,
    name: "Wednesday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 11,
    name: "Thursday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 12,
    name: "Friday",
    check: false,
    date: "",
    itemId: "",
  },
  {
    id: 13,
    name: "Saturday",
    check: false,
    date: "",
    itemId: "",
  },
];
