import { Button, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import Color from "../../theme/color";
import FontStyles from "../../theme/font";
import GroceryList from "../../components/groceryList";
import APIS from "../../config/api";
import getUserData from "../../utils/getLocalStorageData";
import { GET, POST } from "../../config/https";
import { useEffect, useState } from "react";
import {
  addDateDays,
  cuurentWeeKFirstDateAndDay,
  getNextWeekFirstDate,
  nextWeekFistDay,
  selectedDateToTwoDate,
} from "../../utils/dateMethod";
import moment from "moment";
import FullScreenLoadder from "../../components/fullScreenLoadder";
import { CheckLogin } from "../../utils/checkLoign";
import LoginModal from "../../components/login";
import { useNavigate } from "react-router-dom";
import SubcriptionModal from "../../components/subscriptionModal";
import { Box } from "@mui/system";

import { usePersistentState } from "../../contexts/GlobalContext";
import { makeStyles } from "@material-ui/core";
import GrocerySuccessModal from "src/components/GrocerySuccessModal";
import IsPhone from "src/utils/phoneDesktopSize";
import GroceryOrderConfirmation from "src/components/GroceryOrderConfirmation";
import { Redirect_Url } from "src/utils/constant";

const useStyles = makeStyles((theme) => ({
  button: {
    background: Color.color1,
    color: "white",
    border: "2px solid black",
    "&:hover": {
      background: Color.color1,
      color: Color.color10,
      border: "2px solid #BFBF60",
    },
    "&:disabled": {
      background: "gray",
      color: "white",
      border: "2px solid darkgray",
    },
    "&:disabled:hover": {
      background: "gray",
      color: "white",
      border: "2px solid darkgray",
      cursor: "not-allowed",
    },
  },
}));


type cartDataProps = {
  canOrder: boolean
  products: any[]
}


const Grocery = () => {
  const [grocery, setGrocery] = useState([]);
  const [weekDate, setWeekDate] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrderEveything, setisLoadingOrderEveything] = useState(false);
  const [isLoadingOrderRemaining, setisLoadingOrderRemaining] = useState(false);
  const [, dispatch]: any = usePersistentState();
  const [openModalLogin, setOpenModalLogin] = useState({
    loginModalData: false,
    isGrocery: false,
  });
  const [subcriptionModal, setSubcriptiomModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [groceryOrderConfirmation, setGroceryOrderConfirmation] = useState(false);
  const initCartData = {
    canOrder: false,
    products: []
  }
  const [krogerCode, setkrogerCode] = useState<string>("");
  const [cartData, setcartData] = useState<cartDataProps>(initCartData);
  const [modalType, setModalType] = useState('Kroger purchase groceries');
  const [isVisibleByGroceryBtn, setIsVisibleByGroceryBtn] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));
  let currentDate = selectedDateToTwoDate(new Date());

  const classes = useStyles();
  const isPhone = IsPhone();

  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    var code = getParameterByName('code');
    if (code && code?.length > 0) {
      setkrogerCode(code)
      orderGrocery(code)
    }
  }, [])

  useEffect(() => {
    checkValidZip()
  }, [])

  useEffect(() => {
    dispatch({ isSearch: false });
    let checkLoginData = CheckLogin();
    if (!checkLoginData) {
      let temp = {
        loginModalData: true,
        isGrocery: true,
      };
      setOpenModalLogin(temp);
    }
    let tempUserData = getUserData();
    if (tempUserData) {
      let data = addDateDays(14);
      if (data && !openModalLogin.loginModalData) {
        // setSubcriptiomModal(true);
      }
    }
    fetachAllGrocery();
    let currentDate = selectedDateToTwoDate(new Date());
    let datetest = getNextWeekFirstDate(new Date());
    let finalDate = {
      firstCuurentWeek: currentDate.currentWeekStartDate,
      nextWeekDate: datetest,
    };
    setWeekDate(finalDate);
  }, []);

  const orderGrocery = async (code: string) => {
    const orderEverything = localStorage.getItem("orderEverything");
    if (orderEverything === null) return
    let orderStatus = JSON.parse(orderEverything)

    let userData = getUserData();
    if (!userData) return;

    let firstDate = cuurentWeeKFirstDateAndDay();
    let endDate = nextWeekFistDay();

    const params = {
      "code": code,
      "startDate": firstDate,
      "endDate": endDate,
      "orderEverything": orderStatus
    }

    if (orderEverything) {
      setisLoadingOrderEveything(true)
    } else {
      setisLoadingOrderRemaining(true)
    }

    setIsLoading(true);
    await POST(APIS.ORDER_GROCERRIES, params, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          setGroceryOrderConfirmation(false);
          setTimeout(() => {
            openKrogerCartConnect()
          }, 200);
        }
      })
      .catch((error) => {
        setisLoadingOrderEveything(false)
        setisLoadingOrderRemaining(false)
        setIsLoading(false);
      })
      .finally(() => {
        setisLoadingOrderEveything(false)
        setisLoadingOrderRemaining(false)
        setIsLoading(false);
      });
  }

  const checkValidZip = async () => {
    let userData = getUserData();
    if (!userData) return;
    setIsLoading(true);
    let params = {
      id: userData?.id,
    };
    await GET(APIS.GET_USER_BY_ID, params, userData.jwtToken)
      .then(async (res: any) => {
        if (res) {
          const { isSuccess, result } = res
          if (isSuccess && result?.zipCode) {
            const URL = APIS.CHECK_VALID_ZIP + "zipCode=" + result?.zipCode
            const param = {}
            await GET(URL, param, userData.jwtToken)
              .then((res: any) => {
                if (res?.result) {
                  setIsVisibleByGroceryBtn(true)
                } else {
                  setIsVisibleByGroceryBtn(false)
                }
              })
              .catch((error) => {
                setIsLoading(false);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  const fetachAllGrocery = async () => {
    let userData = getUserData();

    if (!userData) return;
    setIsLoading(true);
    let currentDate = selectedDateToTwoDate(new Date());
    let params = {
      StartDate: currentDate.currentWeekStartDate,
      EndDate: currentDate.nextWeekLastDate,
    };

    await GET(APIS.GROCERY_LIST, params, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          setGrocery(res.result.ingredients);
        }
      })
      .catch((error) => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loginOnClose = () => {
    let temp = getUserData();
    if (temp) {
      setOpenModalLogin({
        loginModalData: false,
        isGrocery: false,
      });
    }
    if (!temp) {
      navigate("/dashboard/app");
    }
  };

  useEffect(() => {
    if (openSuccessModal && modalType === 'Kroger purchase groceries') {
      setTimeout(() => {
        // const redirect_uri = process.env.REACT_APP_TITLE === 'DEVELOPMENT'
        //   ? "https://miseenplacemeals.com/dashboard/grocery"
        //   : "https://dev.miseenplacemeals.com/dashboard/grocery"
        // const redirect_uri = "http://localhost:3000/dashboard/grocery"
        const client_id = "miseenproduction-120dda0ab4dfc80a968893f278caf90c3039819515313725509"
        const KrogerSite = `https://api.kroger.com/v1/connect/oauth2/authorize?scope=cart.basic:write&response_type=code&client_id=${client_id}&redirect_uri=${Redirect_Url}`
        window.open(KrogerSite, "_self")
      }, 3000);
    }

    if (openSuccessModal && modalType === 'Kroger Cart') {
      setTimeout(() => {
        if (!openSuccessModal) return
        setOpenSuccessModal(false)
        const KrogerSite = `https://www.kroger.com/cart`
        window.open(KrogerSite, "_self")
        setModalType('Kroger purchase groceries')
      }, 3000);
    }
  }, [openSuccessModal, modalType])

  const openKrogerCartConnect = () => {
    setIsLoading(false);
    setModalType('Kroger Cart')
    setOpenSuccessModal(true)
  }

  const openKrogerConnect = async () => {
    let userData = getUserData();
    if (!userData) return;
    setIsLoading(true);

    let currentDate = selectedDateToTwoDate(new Date());
    let checkCardParams = {
      StartDate: currentDate.currentWeekStartDate,
      EndDate: currentDate.nextWeekLastDate,
      code: ''
    };

    await POST(APIS.CHECK_IF_USER_CART_EXIST, checkCardParams, userData.jwtToken)
      .then(async (res: any) => {
        if (res.isSuccess) {
          const { canOrder, products } = res.result
          if (canOrder) {
            await localStorage.setItem("orderEverything", JSON.stringify(true));
            setModalType('Kroger purchase groceries')
            setOpenSuccessModal(true)
          } else {
            setcartData(res.result)
            setGroceryOrderConfirmation(true)
          }
        }
      })
      .catch((error) => { })
      .finally(() => {
        setIsLoading(false);
      });
  }



  const RenderGroceryButton = () => {
    if (grocery.length && isVisibleByGroceryBtn) {
      return (
        <Button
          onClick={() => openKrogerConnect()}
          className={classes.button}
          sx={{
            height: "34px",
            width: "190px",
            borderColor: Color.color1,
            backgroundColor: Color.color1,
            ...FontStyles.font24Prociono,
            fontSize: isMatch ? "18px" : "20px",
            color: Color.white,
            marginTop: 1
          }}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "200px",
          }}
        >
          Buy Grocery
        </Button>
      )
    }
    return null
  }

  return (
    <Container
      component="main"
      sx={{
        marginTop: "11px",
        marginBottom: "40px",
        width: "100%",
        color: Color.color10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography
            sx={{
              width: "100%",
              ...FontStyles.font24Prociono,
              fontFamily: "Playfair Display SC",
              color: Color.color4,
              marginTop: 5,
            }}
          >
            List of groceries
          </Typography>
          <Typography
            sx={{
              width: "100%",
              ...FontStyles.font16Prociono,
              color: Color.color5,
              marginTop: 1,
            }}
          >
            {`List of groceries for the two weeks(${moment(
              weekDate.firstCuurentWeek
            ).format("MMMM Do")} to ${moment(currentDate.nextWeekLastDate).format(
              "MMMM Do"
            )})`}
          </Typography>
        </div>
        {!isPhone ? <RenderGroceryButton /> : null}
      </div>
      {isPhone ? <RenderGroceryButton /> : null}
      {!grocery.length ? (
        <Box
          sx={{ height: "100px" }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            sx={{ ...FontStyles.font20Prociono, color: Color.color4 }}
            textAlign={"center"}
          >
            {isLoading ? "" : "No Record Found"}
          </Typography>
        </Box>
      ) : (
        <GroceryList item={grocery} />
      )}
      {openModalLogin ? (
        <LoginModal
          open={openModalLogin}
          onClose={() => {
            loginOnClose();
          }}
        />
      ) : null}
      <SubcriptionModal
        open={subcriptionModal}
        onCloseHandle={() => setSubcriptiomModal(false)}
      />

      <FullScreenLoadder open={isLoading} />

      <GrocerySuccessModal
        open={openSuccessModal}
        handleClose={() => {
          setOpenSuccessModal(false);
        }}
        modalType={modalType}
      />

      <GroceryOrderConfirmation
        open={groceryOrderConfirmation}
        handleClose={() => {
          setGroceryOrderConfirmation(false);
        }}
        cartData={cartData}
        onPressOrder={async (orderEverything) => {
          if (orderEverything) {
            await localStorage.setItem("orderEverything", JSON.stringify(true));
          } else {
            await localStorage.setItem("orderEverything", JSON.stringify(false));
          }
          setGroceryOrderConfirmation(false);
          setModalType('Kroger purchase groceries')
          setOpenSuccessModal(true)
        }}
        isLoadingOrderEveything={isLoadingOrderEveything}
        isLoadingOrderRemaining={isLoadingOrderRemaining}
      />
    </Container>
  );
};
export default Grocery;
