/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Container, Link, Typography } from "@mui/material";

import IsPhone from "../../utils/phoneDesktopSize";
import TextBox from "../../components/textBox";
import ButtonUI from "../../components/buttonUi";
import comonStyles from "../comonStyles";

import { isEmpty, isValidEmail } from "../../utils/Validator";
import ValidationError from "../../components/ValidationError";
import { POST } from "../../config/https";
import APIS from "../../config/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);

  const navigate = useNavigate();
  const isPhone = IsPhone();

  useEffect(() => {
    if (isValidation) {
      validator();
    }
  }, [email, isValidation]);

  const onChangeText = (e: any) => {
    setEmail(e.target.value);
  };

  const forgetPassword = async () => {
    setIsValidation(true);
    if (!validator()) return;

    const params = {
      email: email,
    };

    await POST(APIS.FORGOT_PASSWORD, params)
      .then(async (res: any) => {
        if (res.isSuccess) {
          toast.success("Successfully send email. Please check your email.");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const validator = () => {
    const errors: Record<string, string> = {};

    if (isEmpty(email)) {
      errors.email = "Please enter a email";
    }
    if (!isEmpty(email) && !isValidEmail(email)) {
      errors.email = "Please enter a valid email";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            ...comonStyles.cardStyle,
            marginTop: isPhone ? 18 : 25,
            marginBottom: isPhone ? 18 : 25,
          }}
        >
          <Typography style={comonStyles.headerText} textAlign="center">
            Forget Password
          </Typography>
          <Typography
            style={comonStyles.headerDecText}
            textAlign="center"
            sx={{ paddingX: 4 }}
          >
            Enter your register email id. we will send you the link for reset
            password
          </Typography>

          <TextBox
            id={"email"}
            placeholder={"Register Email"}
            required={true}
            fullWidth={true}
            label={"Email Address"}
            name={"email"}
            icon={"Email"}
            onChange={onChangeText}
          />

          <ValidationError formErrors={formErrors.email} />

          <ButtonUI
            type={"submit"}
            label={"Submit"}
            variant={"contained"}
            sx={{ mt: 3, mb: 2 }}
            style={comonStyles.SignInUpButton}
            fullWidth={true}
            action={forgetPassword}
          />
          <Box
            display="inline-flex"
            flexDirection={"row"}
            sx={{
              width: "100%",
              marginTop: "16px",
              height: 21,
              mt: 3,
            }}
          >
            <Typography style={comonStyles.anAccountText} textAlign="center">
              Don’t receive link yet?
              <Link
                style={comonStyles.singUpInTextLinkButton}
                onClick={forgetPassword}
              >
                Re-send
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ForgotPassword;
