import { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";

import IsPhone from "../../utils/phoneDesktopSize";
import TextBox from "../../components/textBox";
import ButtonUI from "../../components/buttonUi";
import comonStyles from "../comonStyles";
import Styles from "./styles";
import { isEmpty } from "../../utils/Validator";
import APIS from "../../config/api";
import { POST } from "../../config/https";
import ValidationError from "../../components/ValidationError";
import getUserData from "../../utils/getLocalStorageData";

const ChoosePassword = () => {
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userData, setUserData] = useState<any>({});

  const isPhone = IsPhone();

  useEffect(() => {
    let temp = getUserData();
    setUserData(temp);
  }, []);

  useEffect(() => {
    if (isValidation) {
      validator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldPassword, newPassword, confirmPassword, isValidation]);

  const onChangeText = (key: string, e: any) => {
    switch (key) {
      case "OldPassword":
        setOldPassword(e.target.value);
        break;
      case "NewPassword":
        setNewPassword(e.target.value);
        break;
      case "ConfirmPassword":
        setConfirmPassword(e.target.value);
        break;
    }
  };

  const choosePassword = async () => {
    setIsValidation(true);
    if (!validator()) return;

    const params = {
      email: userData.email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    await POST(APIS.CHANGE_USER_PASSWORD, params, userData.jwtToken)
      .then(async (res: any) => {})
      .catch((error) => {});
  };

  const validator = () => {
    const errors: Record<string, string> = {};
    if (isEmpty(oldPassword)) {
      errors.oldPassword = "Please enter a Old Password";
    }
    if (isEmpty(newPassword)) {
      errors.newPassword = "Please enter a New Password";
    }
    if (isEmpty(confirmPassword)) {
      errors.confirmPassword = "Please enter a Confirm Password ";
    }
    if (!isEmpty(confirmPassword) && confirmPassword !== newPassword) {
      errors.confirmPassword = "Passwords do not match ";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            ...comonStyles.cardStyle,
            marginTop: isPhone ? 15 : 22,
            marginBottom: isPhone ? 15 : 22,
          }}
        >
          <Typography style={comonStyles.headerText}>
            Choose a Password
          </Typography>

          <Typography style={Styles.desTxt}>
            Must be at least 8 characters.
          </Typography>

          <Box sx={{ mt: 1 }} style={{ width: "100%" }}>
            <TextBox
              id={"OldPassword"}
              placeholder={"Old Password"}
              required={true}
              fullWidth={true}
              label={"Old Password"}
              name={"OldPassword"}
              icon={"LockIcon"}
              onChange={(e) => onChangeText("OldPassword", e)}
              passwordVisibaleData={true}
            />
            <ValidationError formErrors={formErrors.oldPassword} />
            <TextBox
              id={"NewPassword"}
              placeholder={"New Password"}
              required={true}
              fullWidth={true}
              label={"New Password"}
              name={"NewPassword"}
              icon={"LockIcon"}
              onChange={(e) => onChangeText("NewPassword", e)}
              passwordVisibaleData={true}
            />
            <ValidationError formErrors={formErrors.newPassword} />
            <TextBox
              id={"ConfirmPassword"}
              placeholder={"Confirm Password"}
              required={true}
              fullWidth={true}
              label={"Confirm Password"}
              name={"ConfirmPassword"}
              icon={"LockIcon"}
              onChange={(e) => onChangeText("ConfirmPassword", e)}
              passwordVisibaleData={true}
            />
            <ValidationError formErrors={formErrors.confirmPassword} />
            <ButtonUI
              type={"submit"}
              label={"Submit"}
              variant={"contained"}
              sx={{ mt: 6, mb: 2 }}
              style={comonStyles.SignInUpButton}
              fullWidth={true}
              action={choosePassword}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ChoosePassword;
