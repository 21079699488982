/* eslint-disable array-callback-return */
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import FontStyles from "../../theme/font";
import Color from "../../theme/color";
import calanderIcon from "../../aasets/images/regularcalendar-plus.png";
import likeHeart from "../../aasets/images/heartCard.png";
import { makeStyles } from "@material-ui/core";

const responsiveTypographyStyles = {
  wordWrap: "break-word",
  fontSize: "18px",
  lineHeight: "1.5",

  // "@media (max-width: 768px)": {
  //   fontSize: "14px",
  //   lineHeight: "1.4",
  // },
};
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#D3D3D3",
    },
    width: "160px", // Initial width for larger screens
    [theme.breakpoints.down("sm")]: {
      width: "160px", // Adjust width for smaller screens (width < 600px)
    },
    [theme.breakpoints.down("xs")]: {
      width: "160px", // Adjust width for smaller screens (width < 600px)
    },
  },
  hoverEffect: {
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#D3D3D3",
    },
  },
  twoLineText: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
}));

// const useStyles = makeStyles({
// hoverEffect: {
//   cursor: "pointer",
//   transition: "background-color 0.3s",
//   "&:hover": {
//     backgroundColor: "#D3D3D3",
//   },
// },
// twoLineText: {
//   display: "-webkit-box",
//   "-webkit-line-clamp": 2,
//   "-webkit-box-orient": "vertical",
//   overflow: "hidden",
// },
// });

const categoryList = [
  {
    value: Color.color1,
    label: "dinner",
  },
  {
    value: Color.color15,
    label: "lunch",
  },
  {
    value: Color.color10,
    label: "breakfast",
  },
];

const RecipeCard = ({
  data,
  notData,
  recipeDetail,
  addFavorite,
  addDays,
  tabsId,
  test,
  lastDate,
  isDummyCard
}: FavoriteCardProps) => {
  // let nextWeek = getFirstDayOfNextWeek();
  const classes = useStyles();

  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const categoryColor = (name: string) => {
    let color = "";
    categoryList.map((item) => {
      if (item.label === name) {
        return (color = item.value);
      }
    });

    return color ? color : "#F3A72E";
  };

  const ellipsisStyles = {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  };

  return (
    <div style={{ marginBottom: 10 }}>
      {/* {!notData && tabsId === 1 ? ( */}
      {/* {tabsId === 1 ? ( */}
      <Grid
        container
        spacing={0}
        marginTop={isMatch ? 0 : 2}
      // columns={{ xs: 4, sm: 9, md: 12 }}
      >
        {data.map((x: any, index: number) => {
          // let day = moment(x.date).format("dddd");
          const day = isDummyCard ?
            moment(new Date(x.date)).format("dddd") :
            moment(new Date(x.date)).add(7, 'hours').format("dddd");

          if (index < 7) {
            return (
              // <Grid item xs={12 / 7}>
              <Grid
                item
                xs={12 / 2}
                sm={12 / 3}
                md={12 / 4}
                lg={12 / 7}
                xl={12 / 7}
                key={index}
              >
                <Grid item xs={12}>
                  <Box
                    sx={{
                      borderTop: 2,
                      borderBottom: 2,
                      ...FontStyles.font28PlayfairDisplaySC,
                      fontSize: isMatch ? "14px" : "26px",
                      // height: "80px",
                      padding: "18px",
                      borderColor: Color.color17,
                      marginTop: isMatch ? 1 : null,
                    }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {day}
                  </Box>
                </Grid>
                {x.recipe.map((item: any, index: number) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      paddingRight={1}
                      paddingLeft={1}
                      sx={{
                        marginTop: 2,
                      }}
                      key={index}
                      display={"flex"}
                      alignItems={"center"}
                      justifyItems={"center"}
                      justifyContent={"center"}
                    >
                      <Box
                        // className={classes.card}
                        sx={{
                          pading: 10,
                          // height: " 176px",
                          boxShadow: 3,
                          borderBottomRightRadius: 10,
                          borderBottomLeftRadius: 10,
                          height: "188px",
                        }}
                        className={classes.card}
                      >
                        <Box
                          onClick={() => recipeDetail && recipeDetail(item.id)}
                        >
                          <img
                            src={item.imagePath}
                            style={{ width: "145px", height: "88px" }}
                            alt="ss"
                          />
                        </Box>
                        <Box
                          sx={{
                            paddingRight: 1,
                            paddingLeft: 1,
                            height: "55px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...FontStyles.font14Prociono,
                              color: Color.color4,
                              ...ellipsisStyles,
                              ...responsiveTypographyStyles,
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginTop: 1,
                            paddingRight: 1,
                            paddingLeft: 1,
                            paddingBottom: 0.5,
                            marginBottom: "2px",
                          }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            {item.type && (
                              <Box
                                sx={{
                                  width: "60px",
                                  height: "26px",
                                  border: 1,
                                  borderColor: categoryColor(item.type),
                                  borderRadius: 3,
                                  ...FontStyles.font12Prociono,
                                  color: Color.color1,
                                }}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                <Typography
                                  sx={{
                                    ...FontStyles.font12Prociono,
                                    color: categoryColor(item.type),
                                  }}
                                >
                                  {item?.type?.charAt(0).toUpperCase() +
                                    item?.type?.slice(1)}
                                </Typography>
                              </Box>
                            )}
                          </Box>

                          <Typography
                            textAlign={"end"}
                            style={{
                              ...FontStyles.font8Prociono,
                              color: Color.color4,
                            }}
                          >
                            <IconButton
                              size="small"
                              sx={{
                                padding: 0,
                              }}
                              onClick={() => addDays && addDays(item.id)}
                            >
                              <img
                                alt="calander"
                                src={calanderIcon}
                                style={{ width: "17px", height: "20px" }}
                              />
                            </IconButton>
                            <IconButton
                              size="small"
                              sx={{ padding: 0.5 }}
                              onClick={() =>
                                addFavorite && addFavorite(item.id, item.name)
                              }
                            >
                              <img
                                alt="heart"
                                src={likeHeart}
                                style={{ width: "12px", height: "16px" }}
                              />
                            </IconButton>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
                {/* {x.recipe.legnth === undefined ? (
                    <div
                      style={{
                        width: "100%",
                        height: isMatch ? 300 : 450,
                      }}
                    ></div>
                  ) : null} */}
              </Grid>
            );
          }
        })}
      </Grid>
      {/* ) : null} */}
      <Typography
        sx={{
          width: "auto",
          whiteSpace: "nowrap",
          ...FontStyles.font24PlayfairDisplaySC,
          fontSize: isMatch ? "18px" : "28px",
          marginTop: 5,
          cursor: "pointer",
        }}
        textAlign={"center"}
      >
        {`Week of ${moment(lastDate).format("MMMM Do")} Recipes`}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: 1,
          border: 1,
          marginTop: "11px",
          color: Color.color17,
        }}
      ></Box>
      <Grid
        container
        spacing={0}
        marginTop={2}
      // columns={{ xs: 4, sm: 9, md: 12 }}
      >
        {data.map((x: any, index: number) => {
          // let day = moment(x.date).format("dddd");
          const day = moment(new Date(x.date)).add(7, 'hours').format("dddd");

          if (index > 6) {
            return (
              // <Grid item xs={12 / 7}>
              <Grid
                item
                xs={12 / 2}
                sm={12 / 3}
                md={12 / 4}
                lg={12 / 7}
                xl={12 / 7}
                key={index}
              >
                <Grid item xs={12}>
                  <Box
                    sx={{
                      borderTop: 2,
                      borderBottom: 2,
                      ...FontStyles.font28PlayfairDisplaySC,
                      fontSize: isMatch ? "14px" : "26px",
                      // height: "80px",
                      padding: "18px",
                      borderColor: Color.color17,
                      marginTop: isMatch ? 1 : 1,
                    }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {day}
                  </Box>
                </Grid>
                {x.recipe.map((item: any, index: number) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      paddingRight={1}
                      paddingLeft={1}
                      sx={{
                        marginTop: 2,
                      }}
                      key={index}
                      display={"flex"}
                      alignItems={"center"}
                      justifyItems={"center"}
                      justifyContent={"center"}
                    >
                      <Box
                        // className={classes.card}
                        sx={{
                          pading: 10,
                          // height: " 176px",
                          boxShadow: 3,
                          borderBottomRightRadius: 10,
                          borderBottomLeftRadius: 10,
                          height: "188px",
                        }}
                        className={classes.card}
                      >
                        <Box
                          onClick={() => recipeDetail && recipeDetail(item.id)}
                        >
                          <img
                            src={item.imagePath}
                            style={{ width: "100%", height: "88px" }}
                            alt="ss"
                          />
                        </Box>
                        <Box
                          sx={{
                            paddingRight: 1,
                            paddingLeft: 1,
                            height: "55px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...FontStyles.font14Prociono,
                              color: Color.color4,
                              ...ellipsisStyles,
                              ...responsiveTypographyStyles,
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginTop: 1,
                            paddingRight: 1,
                            paddingLeft: 1,
                            paddingBottom: 0.5,
                            marginBottom: "2px",
                          }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            {item.type && (
                              <Box
                                sx={{
                                  width: "60px",
                                  height: "26px",
                                  border: 1,
                                  borderColor: categoryColor(item.type),
                                  borderRadius: 3,
                                  ...FontStyles.font12Prociono,
                                  color: Color.color1,
                                }}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                <Typography
                                  sx={{
                                    ...FontStyles.font12Prociono,
                                    color: categoryColor(item.type),
                                  }}
                                >
                                  {item?.type?.charAt(0).toUpperCase() +
                                    item?.type?.slice(1)}
                                </Typography>
                              </Box>
                            )}
                          </Box>

                          <Typography
                            textAlign={"end"}
                            style={{
                              ...FontStyles.font8Prociono,
                              color: Color.color4,
                            }}
                          >
                            <IconButton
                              size="small"
                              sx={{
                                padding: 0,
                              }}
                              onClick={() => addDays && addDays(item.id)}
                            >
                              <img
                                alt="calander"
                                src={calanderIcon}
                                style={{ width: "17px", height: "20px" }}
                              />
                            </IconButton>
                            <IconButton
                              size="small"
                              sx={{ padding: 0.5 }}
                              onClick={() =>
                                addFavorite && addFavorite(item.id, item.name)
                              }
                            >
                              <img
                                alt="heart"
                                src={likeHeart}
                                style={{ width: "12px", height: "16px" }}
                              />
                            </IconButton>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
                {/* {x.recipe.legnth === undefined ? (
                    <div
                      style={{
                        width: "100%",
                        height: isMatch ? 300 : 450,
                      }}
                    ></div>
                  ) : null} */}
              </Grid>
            );
          }
        })}
      </Grid>
      {/* {tabsId === 2 ? (
        <Grid container spacing={0} columns={{ xs: 4, sm: 9, md: 12 }}>
          {data.map((x: any, index: number) => {
            let day = moment(x.date).format("dddd");
            if (index > 6) {
              return (
                <Grid item xs={2} sm={3} md={12 / 5} key={index}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        borderTop: 2,
                        borderBottom: 2,
                        ...FontStyles.font28PlayfairDisplaySC,
                        fontSize: isMatch ? "14px" : "26px",
                        // height: "80px",
                        padding: "18px",
                        borderColor: Color.color17,
                        marginTop: isMatch ? 1 : null,
                      }}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {day}
                    </Box>
                  </Grid>
                  {x.recipe.map((item: any, index: number) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        paddingRight={1}
                        paddingLeft={1}
                        sx={{
                          borderTop: 2,
                          borderBottom: 2,
                          // marginTop: 1,
                          ...FontStyles.font28PlayfairDisplaySC,
                          fontSize: isMatch ? "14px" : "26px",
                          padding: "18px",
                          borderColor: Color.color17,
                          marginTop: isMatch ? 1 : null,
                          backgroundColor: index === 2 ? "red" : undefined,
                        }}
                        key={index}
                      >
                        <Box
                          sx={{
                            pading: 10,
                            // height: " 176px",
                            boxShadow: 3,
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            height: "188px",
                          }}
                          className={classes.hoverEffect}
                        >
                          <Box
                            onClick={() =>
                              recipeDetail && recipeDetail(item.id)
                            }
                          >
                            <img
                              src={item.imagePath}
                              style={{ width: "100%", height: "88px" }}
                              alt="ss"
                            />
                          </Box>
                          <Box
                            sx={{
                              paddingRight: 1,
                              paddingLeft: 1,
                              height: "55px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...FontStyles.font14Prociono,
                                color: Color.color4,
                                ...ellipsisStyles,
                                ...responsiveTypographyStyles,
                              }}
                            >
                              {item.name}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              marginTop: 1,
                              paddingRight: 1,
                              paddingLeft: 1,
                              paddingBottom: 0.5,
                              marginBottom: "2px",
                            }}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Box>
                              {item.type && (
                                <Box
                                  sx={{
                                    width: "60px",
                                    height: "26px",
                                    border: 1,
                                    borderColor: categoryColor(item.type),
                                    borderRadius: 3,
                                    ...FontStyles.font12Prociono,
                                    color: Color.color1,
                                  }}
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    sx={{
                                      ...FontStyles.font12Prociono,
                                      color: categoryColor(item.type),
                                    }}
                                  >
                                    {item?.type?.charAt(0).toUpperCase() +
                                      item?.type?.slice(1)}
                                  </Typography>
                                </Box>
                              )}
                            </Box>

                            <Typography
                              textAlign={"end"}
                              style={{
                                ...FontStyles.font8Prociono,
                                color: Color.color4,
                              }}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  padding: 0,
                                }}
                                onClick={() => addDays && addDays(item.id)}
                              >
                                <img
                                  alt="calander"
                                  src={calanderIcon}
                                  style={{ width: "17px", height: "20px" }}
                                />
                              </IconButton>
                              <IconButton
                                size="small"
                                sx={{ padding: 0.5 }}
                                onClick={() =>
                                  addFavorite && addFavorite(item.id, item.name)
                                }
                              >
                                <img
                                  alt="heart"
                                  src={likeHeart}
                                  style={{ width: "12px", height: "16px" }}
                                />
                              </IconButton>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            }
          })}
        </Grid>
      ) : null} */}
    </div>
  );
};
export default RecipeCard;

interface FavoriteCardProps {
  data: any;
  notData?: boolean;
  recipeDetail?: (id: string) => void;
  addFavorite?: (id: string, title?: string) => void;
  addDays?: (id: string) => void;
  tabsId?: number;
  test?: boolean;
  lastDate?: any;
  isDummyCard?: boolean
}
