import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Autocomplete, Box, Container, Link, MenuItem, TextField, Typography } from "@mui/material";

import TextBox from "../../components/textBox";
import ButtonUI from "../../components/buttonUi";
import Styles from "./styles";
import Google from "../../aasets/images/Socialicon.png";
import comonStyles from "../comonStyles";
import APIS from "../../config/api";
import { isEmpty, isValidEmail } from "../../utils/Validator";
import { BASEURL, POST } from "../../config/https";

import ValidationError from "../../components/ValidationError";
import { toast } from "react-hot-toast";
import { useGoogleLogin } from "@react-oauth/google";
import { GOOGLE_LOGIN_KEY } from "src/utils/constant";
import axios from "axios";
import ProfileImageUPdate from "src/utils/profileImageUpdte";
import { usePersistentState } from "src/contexts/GlobalContext";
import FullScreenLoadder from "src/components/fullScreenLoadder";
import "./signup.css"
import TextBoxDropDown from "src/components/drop-down";

const formDataType = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  selectedCountry: "",
  zipCode: ''
};
const USA = "United States of America"

const SignUp = () => {
  const [formData, setFormData] = useState(formDataType);
  const [, dispatch]: any = usePersistentState();

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState<string>(USA);
  const handleChangeCountry = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCountry(event.target.value as string); // Cast the value to string
  };
  useEffect(() => {
    if (isValidation) {
      validator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.email,
    formData.password,
    formData.name,
    formData.phoneNumber,
    formData.confirmPassword,
    formData.zipCode,
    selectedCountry,
    isValidation,
  ]);

  const onChangeText = (key: string, e: any) => {
    let newData = { ...formData };
    switch (key) {
      case "name":
        newData.name = e.target.value;
        break;
      case "email":
        newData.email = e.target.value;
        break;
      case "password":
        newData.password = e.target.value;
        break;
      case "confirmPassword":
        newData.confirmPassword = e.target.value;
        break;
      case "phoneNumber":
        newData.phoneNumber = e.target.value;
        break;
      case "zipCode":
        let temp = e.target.value;
        temp = temp.trim();
        const expression = /^\d+$/
        const validated = expression.test(temp);
        if (validated) {
          newData.zipCode = temp;
        }
        if (!temp) {
          newData.zipCode = temp;
        }
        break;
      case "country":
        newData.selectedCountry = e.target.value;
        break;
    }

    setFormData(newData);
  };

  const signUp = async () => {
    setIsValidation(true);
    if (!validator()) return;
    setIsLoading(true);
    const params = {
      name: formData.name,
      email: formData.email,
      mobileNumber: formData.phoneNumber,
      password: formData.password,
      dietAttributes: {
        vegetarian: false,
        nutFree: false,
        dairyFree: false,
        soyFree: false,
        eggFree: false,
        wheatFree: false,
        sesameFree: false,
      },
      country: selectedCountry,
      zipCode: formData.zipCode === USA ? formData.zipCode : ""
    };

    await POST(APIS.ADD_USER, params)
      .then(async (res: any) => {
        if (res.isSuccess === true) {
          setIsLoading(false);
          toast.success("Successfully Register");
          navigate("/login");
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const validator = () => {
    const errors: Record<string, string> = {};
    if (isEmpty(formData.name)) {
      errors.name = "Please enter a name";
    }
    if (isEmpty(formData.email)) {
      errors.email = "Please enter a email";
    }
    if (!isEmpty(formData.email) && !isValidEmail(formData.email)) {
      errors.email = "Please enter a valid email";
    }
    if (isEmpty(formData.password)) {
      errors.password = "Please enter a password";
    }
    if (isEmpty(formData.confirmPassword)) {
      errors.confirmPassword = "Please enter a confirm passowrd";
    }
    if (isEmpty(formData.confirmPassword)) {
      errors.confirmPassword = "Please enter a confirm passowrd";
    }
    if (isEmpty(selectedCountry)) {
      errors.country = "Please Select Country";
    }
    if (!isEmpty(formData.zipCode) && (formData.zipCode.length > 5 || formData.zipCode.length < 5)) {
      errors.zipCode = "Please enter a 5 digit zipcode";
    }
    if (
      !isEmpty(formData.confirmPassword) &&
      formData.confirmPassword !== formData.password
    ) {
      errors.confirmPassword = "Passwords do not match";
    }
    // if (isEmpty(formData.phoneNumber)) {
    //   errors.phoneNumber = "Please enter a phone number";
    // }
    // if (!isEmpty(formData.phoneNumber) && !isNumeric(formData.phoneNumber)) {
    //   errors.phoneNumber = "Please enter a valid phone number";
    // }
    // if (formData.phoneNumber && formData.phoneNumber.length > 10) {
    //   errors.phoneNumber =
    //     "Your phone number should be of 10 digits. Please also ensure it does not start with 0.";
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse: any) => googleWithLogin(codeResponse),
    onError: (error) => { },
  });

  const googleWithLogin = async (res: any) => {
    if (!res) return;
    let params = {
      accessToken: res.access_token,
    };
    setIsGoogleLogin(true);
    axios
      .post(BASEURL + APIS.GOOGLE_LOGIN, params, {
        headers: {
          Accept: "application/json",
          "x-api-key": GOOGLE_LOGIN_KEY,
        },
      })
      .then(async (response: any) => {
        setIsGoogleLogin(false);
        const res = response.data;
        if (res.isSuccess) {
          if (res?.result?.roles[0]?.name === "User") {
            await localStorage.setItem("userData", JSON.stringify(res.result));
            await dispatch({ users: res.result });
            let temp = await ProfileImageUPdate(
              res?.result?.id,
              res?.result?.jwtToken
            );
            dispatch({ isProfile: temp })
            navigate("/dashboard/app");
          }
        } else {
          setIsGoogleLogin(false);
          toast.error(res?.errors[0]);
        }
      })
      .catch((err) => {
        setIsGoogleLogin(false);
      })
      .finally(() => {
        setIsGoogleLogin(false);
      });
  };



  return (
    <>
      <Container component="main" maxWidth="xs" sx={Styles.continer}>
        <Box sx={comonStyles.cardStyle}>
          <Typography style={comonStyles.headerText}>Sign Up</Typography>
          <Typography style={comonStyles.headerDecText}>
            Please Sign up to Continue.
          </Typography>
          <TextBox
            id={"name"}
            placeholder={"Name"}
            required={true}
            fullWidth={true}
            label={"Name"}
            name={"name"}
            icon={"Smile"}
            onChange={(e) => onChangeText("name", e)}
            extraLableStyle={{ marginTop: "20px" }}
          />
          <ValidationError formErrors={formErrors.name} />
          <TextBox
            id={"email"}
            placeholder={"Email"}
            required={true}
            fullWidth={true}
            label={"Email"}
            name={"email"}
            icon={"Email"}
            onChange={(e) => onChangeText("email", e)}
          />
          <ValidationError formErrors={formErrors.email} />
          <TextBox
            id={"password"}
            placeholder={"Password"}
            required={true}
            fullWidth={true}
            label={"Password"}
            name={"password"}
            icon={"LockIcon"}
            onChange={(e) => onChangeText("password", e)}
            passwordVisibaleData={true}
          />
          <ValidationError formErrors={formErrors.password} />
          <TextBox
            id={"confrimPassword"}
            placeholder={"Confirm Password"}
            required={true}
            fullWidth={true}
            label={"Confirm Password"}
            name={"confrimPassword"}
            icon={"LockIcon"}
            onChange={(e) => onChangeText("confirmPassword", e)}
            passwordVisibaleData={true}
          />
          <ValidationError formErrors={formErrors.confirmPassword} />
          <TextBox
            id={"phone"}
            placeholder={"Phone Number(Optional)"}
            required={true}
            fullWidth={true}
            label={"Phone Number"}
            name={"phoneNumber"}
            icon={"Phone"}
            type="number"
            onChange={(e) => onChangeText("phoneNumber", e)}
          />

          <TextBoxDropDown select={true} label="Country" value={selectedCountry} fullWidth onChange={(event) => {
            handleChangeCountry(event)
          }} />
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={countryList}
            onChange={(event: any, newValue) => {
              setCountry(newValue ? newValue : ''); // Update the selectedCountry state when a new value is selected
            }}
            value={country}
            sx={{ width: '100%', boxShadow: 50 }}
            renderInput={(params) => <TextField {...params} />}
          /> */}
          <ValidationError formErrors={formErrors.country} />
          {selectedCountry === USA ?
            <>
              <TextBox
                id={"ZipCode"}
                placeholder={"Zip Code"}
                required={true}
                fullWidth={true}
                label={"Zip Code"}
                name={"Zip Code"}
                value={formData.zipCode}
                onChange={(e) => onChangeText("zipCode", e)}
              />
              <ValidationError formErrors={formErrors.zipCode} />
            </>
            : null}
          <ButtonUI
            type={"submit"}
            label={"Sign Up"}
            variant={"contained"}
            sx={{ mt: 3, mb: 2 }}
            style={comonStyles.SignInUpButton}
            fullWidth={true}
            action={signUp}
            isLoading={isLoading}
          />
          <ButtonUI
            type={"submit"}
            label={"Sign Up with Google"}
            variant={"outlined"}
            style={comonStyles.GoogleButtonTxt}
            fullWidth={true}
            image={Google}
            action={() => loginWithGoogle()}
          />
          <Box
            display="inline-flex"
            flexDirection={"row"}
            sx={{
              width: "100%",
              marginTop: "16px",
              height: 21,
              mt: 4,
            }}
          >
            <Typography style={comonStyles.anAccountText} textAlign="center">
              Already have an account?
              <Link href="login" style={comonStyles.singUpInTextLinkButton}>
                Sign In
              </Link>
            </Typography>
          </Box>
        </Box>
        <FullScreenLoadder open={isGoogleLogin} />
      </Container>
    </>
  );
};

export default SignUp;
function googleWithLogin(codeResponse: any): void {
  throw new Error("Function not implemented.");
}

