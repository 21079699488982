import {
  Box,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ButtonUI from "../buttonUi";
import FontStyles from "../../theme/font";
import Color from "../../theme/color";
import { makeStyles } from "@material-ui/core";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const useStyles = makeStyles((theme) => ({
  modal: {
    border: "none",
  },
}));

const FavoriteModal = ({
  onClickAddFavorite,
  onCloseHandle,
  open,
  titleRecipeName,
  isRemove,
  isLoading,
}: FavoriteModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const styleFavouriteModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMatch ? "322px" : "322px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    paddingRight: 5,
    paddingLeft: 5,
    p: "20px",
  };

  return (
    <Modal
      open={open}
      onClose={() => onCloseHandle()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ root: classes.modal }}
    >
      <Box sx={{ ...styleFavouriteModal }}>
        <Box display={"flex"} flexDirection={"row"}>
          <Box
            display={"flex"}
            sx={{
              width: "90%",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              style={{
                ...FontStyles.font20Prociono,
                color: Color.color10,
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
              }}
              textAlign={"center"}
              flexDirection={"column"}
              display={"flex"}
            >
              Do you want to {isRemove ? "remove " : "add"}
              <Typography
                variant="body1"
                style={{
                  ...FontStyles.font20Prociono,
                  color: Color.color15,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  wordWrap: "break-word",
                }}
                textAlign={"center"}
              >
                {titleRecipeName}
              </Typography>
              to your favorites?
            </Typography>
          </Box>
          <Typography
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-end"}
            sx={{ width: "10%" }}
          >
            <IconButton onClick={() => onCloseHandle()}>
              <CloseOutlinedIcon sx={{ color: "black" }} />
            </IconButton>
          </Typography>
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          sx={{ marginTop: "18px" }}
        >
          {/* <Button
            sx={{
              ...FontStyles.font12Prociono,
              color: Color.color6,
              fontWeight: "400",
              marginRight: 1,
            }}
            variant="text"
            onClick={() => onClickRemove()}
          >
            Remove
          </Button> */}
          <ButtonUI
            style={{
              backgroundColor: Color.color1,
              ...FontStyles.font12Prociono,
              color: Color.color4,
              borderRadius: 5,
              textTransform: "none",
            }}
            lableStyle={{
              ...FontStyles.font16Prociono,
              color: Color.color2,
              fontWeight: "400",
            }}
            variant="contained"
            label={isRemove ? "remove" : "ok"}
            size="small"
            sx={{ height: 30 }}
            action={() => onClickAddFavorite()}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Modal>
  );
};
export default FavoriteModal;

interface FavoriteModalProps {
  open: boolean;
  onCloseHandle: () => void;
  onClickRemove: () => void;
  onClickAddFavorite: () => void;
  titleRecipeName?: string;
  isRemove?: boolean;
  isLoading?: boolean;
}
