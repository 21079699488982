import comonStyles from "../comonStyles";

const styles = {
  continer: {
    boxShadow: 20,
    borderRadius: 2,
    px: 2,
    py: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 22,
    marginBottom: 22,
  },

  desTxt: {
    ...comonStyles.headerDecText,
    marginTop: 12,
  },
};
export default styles;
