import moment from "moment";
import getUserData from "./getLocalStorageData";

export function getFirstDayOfCurrentWeek() {
  const today = new Date();
  const currentDay = today.getDay();
  const diff = currentDay === 0 ? 0 : 0 - currentDay;
  const firstDayOfCurrentWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + diff
  );
  return firstDayOfCurrentWeek;
}

export function getFirstDayOfNextWeek() {
  const today = new Date();
  const currentDay = today.getDay();
  const diff = 6 - currentDay;
  const firstDayOfNextWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + diff + 1
  );
  return firstDayOfNextWeek;
}

export function twoWeekDate() {
  let date = Array.from(Array(14).keys()).map((idx) => {
    const d = new Date();

    d.setDate(d.getDate() - d.getDay() + idx);
    return d;
  });
  return date;
}

export function twoWeekDateSelected(dateNew: any) {
  let date = Array.from(Array(14).keys()).map((idx) => {
    const d = new Date(dateNew);

    d.setDate(d.getDate() - d.getDay() + idx);
    return d;
  });
  return date;
}

export function endOfWeek() {
  const currentDate = new Date();
  const nextWeekLastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 7 + (6 - currentDate.getDay() + 1)
  );
  const formattedDate = nextWeekLastDate.toISOString().split("T")[0];
  let date = new Date(formattedDate);
  return date;
}

export function utcFormatConvert(date: Date) {
  const currentDateIST = moment(date).utcOffset("+05:30");
  const currentDateUTC = currentDateIST.utc().format();
  return currentDateUTC;
}

export function cuurentWeeKFirstDateAndDay() {
  // +7 time add
  const currentDateUTC = moment.utc().add(7, 'hours');

  const startOfWeekUTC = currentDateUTC.startOf("week").format();
  return startOfWeekUTC;
}

export function getNextWeekFirstDate(selectedDate: any) {
  const firstDate = new Date(selectedDate);
  firstDate.setDate(selectedDate.getDate() + (7 - selectedDate.getDay()));

  return firstDate;
}

export function nextWeekFistDay() {
  // +7 time add
  const currentDateUTC = moment.utc().add(7, 'hours');
  const endOfNextWeekUTC = currentDateUTC
    .add(1, "weeks")
    .endOf("week")
    .format();
  return endOfNextWeekUTC;
}

export function getDatesArray(startDate: any, endDate: any) {
  const datesArray = [];
  let currentDate = moment.utc(startDate);

  while (currentDate.isSameOrBefore(moment.utc(endDate), "day")) {
    datesArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return datesArray;
}

export function selectedDateToTwoDate(date: any) {
  const currentDate = moment(date);

  const currentWeekStartDate = currentDate.startOf("week").format("YYYY-MM-DD");

  const nextWeekLastDate = currentDate
    .add(1, "week")
    .endOf("week")
    .format("YYYY-MM-DD");

  return { currentWeekStartDate, nextWeekLastDate };
}

export function addDateDays(addDays: number) {
  let currentDateFirst = new Date();
  let tempUserData = getUserData();

  const currentDate = new Date(tempUserData.createdDate);

  const numberOfDaysToAdd = addDays;

  const currentTimestamp = currentDate.getTime();

  const updatedTimestamp =
    currentTimestamp + numberOfDaysToAdd * 24 * 60 * 60 * 1000;

  const updatedDate = new Date(updatedTimestamp);

  const day = updatedDate.getDate();
  const month = updatedDate.getMonth();
  const year = updatedDate.getFullYear();

  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthName = monthsOfYear[month];
  let datFinal = new Date(`${monthName} ${day}, ${year}`);

  if (tempUserData) {
    if (datFinal && datFinal < currentDateFirst) {
      return true;
    }
  }
  return false;
}
