import { Box, Grid, IconButton, Typography } from "@mui/material";
// import Card from "@mui/material/Card";
// import { styled } from "@mui/material/styles";

import plusicon from "../aasets/images/plus-circle.png";
import likeHeart from "../aasets/images/heartCard.png";
import { createStyles, makeStyles } from "@material-ui/core";
import FontStyles from "src/theme/font";
import Color from "src/theme/color";
import IsPhone from "src/utils/phoneDesktopSize";

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      width: "201.49px",
      height: "159px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      "&:hover": {
        backgroundColor: "#D3D3D3",
      },
      [theme.breakpoints.down("sm")]: {
        width: "180px",
        height: "159px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "160px",
        height: "159px",
      },
    },
  })
);

// const CustomCardRoot = styled(Card)(({ theme }) => ({
//   height: "170px",

//   // boxShadow: "none",
// }));

const CardUiTest = ({
  headerAlignTop,
  cardSize,
  gridSize,
  cardData,
  handleAdd,
  handleFavourite,
  recipeDetail,
  isloading,
}: cardUi) => {
  const classes = useStyles();
  const isPhone = IsPhone();

  const firstCharUperCase = (str: string) => {
    let data = "";
    if (str && str.trim().length > 0) {
      data = str?.charAt(0).toUpperCase() + str?.slice(1);
    }
    return data;
  };
  // const theme = useTheme();
  // const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));
  const responsiveTypographyStyles = {
    wordWrap: "break-word",
    fontSize: "18px",
    lineHeight: "1.5",

    // "@media (max-width: 768px)": {
    //   fontSize: "14px",
    //   lineHeight: "1.4",
    // },
  };

  const ellipsisStyles = {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2, // Show up to three lines before ellipsis
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  };

  return (
    <>
      {/* display={"flex"} justifyContent={"center"} */}
      <Box
        sx={{
          marginTop: 1,
          // alignItems: "center",
          // alignSelf: "center",
          // backgroundColor: "yellow",
        }}
        // alignSelf={"center"}
        // alignItems={"center"}
        justifyItems={"center"}
        justifyContent={"center"}
        // width={"100%"}
        display={"flex"}
      >
        {/* <Grid
          container
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
          justifyItems={"center"}
          alignSelf={"center"}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {cardData.map((card: any, index: number) => (
            <Grid
              item
              width={isMatch ? 150 : 180.82}
              sx={{
                margin: 0.7,
              }}
            >
              <Box
                className={classes.hoverEffect}
                sx={{
                  width: isMatch ? 150 : 180.82,
                  height: 163,
                  boxShadow: 2,
                  borderRadius: 1,
                  paddingTop: 0.5,
                }}
              >
                <Box
                  sx={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  <div style={{ height: " 9px" }}>
                    <Typography
                      sx={{
                        ...responsiveTypographyStyles,
                        ...ellipsisStyles,
                        ...FontStyles.font10Prociono,
                        color: Color.color4,
                        fontWeight: "bold",
                      }}
                    >
                      {firstCharUperCase(card.name)}
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      ...FontStyles.font10Prociono,
                      color: Color.color5,
                      marginTop: 10,
                    }}
                  >
                    {firstCharUperCase(card.type)}
                  </Typography>
                </Box>
                <div
                  onClick={() => recipeDetail && recipeDetail(card.id)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography>
                    <img
                      alt="recipe"
                      src={card.imagePath}
                      style={{
                        width: "100%",
                        height: "98px",
                        marginTop: "2px",
                      }}
                      loading="lazy"
                    />
                  </Typography>
                </div>

                <div
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    textAlign={"start"}
                    sx={{
                      ...FontStyles.font8Prociono,
                      color: Color.color4,
                      width: "50%",
                      ...responsiveTypographyStyles,
                      ...ellipsisStyles,
                      ...FontStyles.font10Prociono,
                    }}
                  >
                    {card.cookTime}
                  </Typography>

                  <Typography
                    style={{
                      ...FontStyles.font8Prociono,
                      color: Color.color4,
                      width: "50%",
                      paddingRight: 20,
                    }}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <IconButton
                      size="small"
                      style={{ padding: 0, paddingRight: 4 }}
                      onClick={() => handleAdd && handleAdd(card.id)}
                    >
                      <img
                        alt="plus"
                        src={plusicon}
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      style={{
                        paddingRight: 1,
                        padding: 0,
                      }}
                      onClick={() =>
                        handleFavourite && handleFavourite(card.id, card.name)
                      }
                    >
                      <img
                        alt="heart"
                        src={likeHeart}
                        style={{ width: "12px", height: "16px" }}
                      />
                    </IconButton>
                  </Typography>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid> */}

        <Grid container
          spacing={{ md: 2, xs: 1, sm: 1, lg: 2 }}
        >
          {cardData.map((x: any, index: number) => {
            return (
              <Grid
                item
                xs={12 / 2}
                sm={12 / 3}
                md={12 / 5}
                lg={12 / 6}
                container
                justifyContent="center"
                alignItems="center"
                key={index}
              >
                <Box
                  className={classes.card}
                  sx={{
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  {headerAlignTop ? (
                    <Box
                      sx={{ height: "30px", width: "158px" }}
                      padding={0.5}
                      flexGrow={1}
                    >
                      <Typography
                        sx={{
                          ...responsiveTypographyStyles,
                          ...ellipsisStyles,
                          ...FontStyles.font10Prociono,
                          color: Color.color4,
                          fontWeight: "bold",
                        }}
                      >
                        {firstCharUperCase(x.name)}
                      </Typography>
                    </Box>
                  ) : null}

                  <Box onClick={() => recipeDetail && recipeDetail(x.id)}>
                    <img
                      alt="recipe"
                      src={x.imagePath}
                      style={{
                        width: isPhone ? '100%' : "178px",
                        height: "96px",
                        cursor: "pointer",
                      }}
                      loading="lazy"
                    />
                  </Box>

                  <Box
                    sx={{
                      height: "20px",
                    }}
                    flexDirection={"row"}
                    padding={0.6}
                    paddingTop={0}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <div style={{ width: "50%" }}>
                      <Typography
                        textAlign={"start"}
                        sx={{
                          ...FontStyles.font8Prociono,
                          ...responsiveTypographyStyles,
                          ...ellipsisStyles,
                          WebkitLineClamp: 1,
                          ...FontStyles.font8Prociono,
                          color: Color.color4,
                          width: "100px",
                        }}
                      >
                        {x.cookTime}
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        ...FontStyles.font8Prociono,
                        color: Color.color4,
                        width: "50%",
                      }}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                    >
                      <IconButton
                        size="small"
                        style={{ padding: 0, paddingRight: 4 }}
                        onClick={() => handleAdd && handleAdd(x.id)}
                      >
                        <img
                          alt="plus"
                          src={plusicon}
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        size="small"
                        style={{
                          paddingRight: 1,
                          padding: 0,
                        }}
                        onClick={() =>
                          handleFavourite && handleFavourite(x.id, x.name)
                        }
                      >
                        <img
                          alt="heart"
                          src={likeHeart}
                          style={{ width: "12px", height: "16px" }}
                        />
                      </IconButton>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              // </div>
            );
          })}
        </Grid>
      </Box>
      {!cardData.length && !isloading ? (
        <Box
          sx={{
            width: "100%",
            height: "100px",
            marginBottom: 1,
          }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            textAlign={"center"}
            sx={{ ...FontStyles.font20Prociono, color: Color.color4 }}
          // sx={{ paddingTop: 20, paddingBottom: 20 }}
          >
            No Recipes found
          </Typography>
        </Box>
      ) : null}
    </>
  );
};
export default CardUiTest;
interface cardUi {
  headerAlignTop?: boolean;
  cardSize?: cardSize;
  gridSize?: gridSize;
  // cardData: cardDataProps[];
  cardData: any;
  isloading?: boolean;
  handleAdd?: (id: string) => void;
  handleFavourite?: (id?: string, titleRecipe?: string) => void;
  recipeDetail?: (id?: string) => void;
}
type cardSize = {
  xs: number;
  sm: number;
  md: number;
};
type gridSize = {
  xs: number;
  sm: number;
  md: number;
};
