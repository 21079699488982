const Styles = {
  continer: {
    marginTop:5,
    marginBottom: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    width: "100%",
  },
};
export default Styles;
