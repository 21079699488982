import Color from "../theme/color";
import FontStyles from "../theme/font";

const comonStyles = {
  cardStyle: {
    boxShadow: 4,
    borderRadius: 2,
    px: 2,
    py: 3,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  headerText: {
    ...FontStyles.font28Prociono,
    color: Color.color4,
  },
  headerDecText: {
    ...FontStyles.font16Prociono,
    color: Color.color5,
    marginBottom: 2,
  },
  SignInUpButton: {
    backgroundColor: Color.color1,
    color: Color.color4,
    boxShadow: "none",
    ...FontStyles.font14Prociono,
  },
  GoogleButtonTxt: {
    backgroundColor: Color.white,
    color: Color.color4,
    boxShadow: "none",
    ...FontStyles.font14Prociono,
    borderColor: "#BFBF60",
  },
  anAccountText: {
    ...FontStyles.font14Prociono,
    color: Color.color4,
    textDecoration: "none",
    width: "100%",
  },
  singUpInTextLinkButton: {
    ...FontStyles.font14Prociono,
    color: Color.color6,
    paddingLeft: 5,
    textDecoration: "none",
  },
};
export default comonStyles;
