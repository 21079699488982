import { useMediaQuery } from "@material-ui/core";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ButtonUI from "src/components/buttonUi";

import DaysModal from "src/components/daysModal";
import FavoriteModal from "src/components/favoriteModal";
import PaymentCard from "src/components/payment/home";

import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import SubcriptionModal from "src/components/subscriptionModal";
import APIS from "src/config/api";
import { GET, POST } from "src/config/https";
import Color from "src/theme/color";
import FontStyles from "src/theme/font";
import CheckSubscription from "src/utils/checkSubscription";
import {
  cuurentWeeKFirstDateAndDay,
  nextWeekFistDay,
  selectedDateToTwoDate,
} from "src/utils/dateMethod";
import getUserData from "src/utils/getLocalStorageData";
import CardUiTest from "../demo";
import FullScreenLoadder from "src/components/fullScreenLoadder";
import { usePersistentState } from "src/contexts/GlobalContext";

const PastRecpies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [addFavoriteModal, setAddFavoriteModal] = useState(false);
  const [daysModal, setDaysModal] = useState(false);
  const [recipeDetailPageId, setRecipeDetailPageid] = useState("");
  const [finalRecipe, setFinalRecipe] = useState<any>({});
  const [recipeName, setRecipeName] = useState("");
  const [allRecipe, setAllRecipe] = useState({});
  const [paymentStatus, setPaymentStuts] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [, dispatch]: any = usePersistentState();
  const [subcriptionModal, setSubcriptiomModal] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  useEffect(() => {
    dispatch({ isSearch: false });
    fetchRecipeInDetail();
    featchAllRecipe();
  }, []);

  useEffect(() => {
    let temp: any = { ...allRecipe };
    if (temp) {
      let breakfast =
        temp?.breakfast &&
        temp?.breakfast.filter((x: any, index: number) => x && index < 6);
      let dinner =
        temp?.dinner &&
        temp?.dinner.filter((x: any, index: number) => x && index < 6);
      let lunch =
        temp?.lunch &&
        temp?.lunch.filter((x: any, index: number) => x && index < 6);
      setFinalRecipe({ breakfast, dinner, lunch });
    }
  }, [allRecipe]);

  const featchAllRecipe = async () => {
    let temp = getUserData();
    if (!temp) return;
    setIsLoading(true);
    let date = cuurentWeeKFirstDateAndDay();
    let params: any = {
      Date: date,
      IsBreakfast: true,
      IsLunch: true,
      IsDinner: true,
    };
    await GET(APIS.PAST_RECIPES, params, temp?.jwtToken)
      .then((res: any) => {
        setAllRecipe(res.result);
      })
      .catch((error) => {
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchRecipeInDetail = async (selectDate?: any) => {
    let userData = getUserData();
    if (!userData) return;
    // let selectdata = selectDate && selectedDateToTwoDate(selectDate);

    // let firstDate = cuurentWeeKFirstDateAndDay();
    // let endDate = nextWeekFistDay();
    // // setLoader(true);
    // let param = {
    //   startDate: firstDate,
    //   endDate: endDate,
    // };
    let currentDate = selectedDateToTwoDate(new Date());
    let params = {
      startDate: currentDate.currentWeekStartDate,
      endDate: currentDate.nextWeekLastDate,
    };

    await POST(APIS.GET_USER_RECIPE_DETAIL, params, userData?.jwtToken)
      .then((res: any) => {
        if (!res.result) {
          // setRecipe([]);
        }
        if (res.result) {
          // setRecipe(res.result.recipes);
        }
      })
      .catch((error) => { })
      .finally(() => {
        // setLoader(false);
      });
  };

  const addFavoriteRecipe = async () => {
    let userData = getUserData();
    if (!userData) return;

    let params = {
      id: recipeDetailPageId,
      isFavorite: true,
    };

    await POST(APIS.ADD_FAVORITE_RECIPE, params, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          toast.success("Successfully add in favorite list");
          setAddFavoriteModal(false);
        }
        if (!res.isSuccess && res.errors) {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setAddFavoriteModal(false);
        setRecipeDetailPageid("");
      });
  };

  const setSubcriptionStuts = async () => {
    let temp = await CheckSubscription();
    setPaymentStuts(temp?.isSubscriptionRequired);
  };
  const handaleSubcription = () => {
    // setSubcriptiomModal(false);
    // setPaymentModal(true);
    navigate("/dashboard/subscriptionplan");
  };

  const recipeData = (category: string) => {
    let newData: any = [];
    if (category === "DINNER") {
      if (finalRecipe.dinner !== undefined) {
        return (newData = finalRecipe.dinner);
      }
    }
    if (category === "LUNCH") {
      if (finalRecipe.lunch !== undefined) {
        return (newData = finalRecipe.lunch);
      }
    }
    if (category === "BREAKFAST") {
      if (finalRecipe.breakfast !== undefined) {
        return (newData = finalRecipe.breakfast);
      }
    }

    return newData;
  };

  return (
    <div>
      <Container
        component="main"
        sx={{
          marginTop: "20px",
          width: "100%",
          color: Color.color10,
        }}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button sx={{ width: "205px" }}></Button>
          <Typography
            sx={{
              ...FontStyles.font40Prociono,
              fontFamily: "Playfair Display SC",
              color: Color.color10,
            }}
          >
            Past Recipes
          </Typography>
          <ButtonUI
            variant={"outlined"}
            label={"THIS WEEKS RECIPES"}
            style={{
              backgroundColor: Color.color1,
              borderColor: Color.color1,
              width: "205px",
            }}
            lableStyle={{ ...FontStyles.font16Prociono, color: Color.white }}
          />
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Button sx={{ width: "205px" }}> */}{" "}
          <Box style={{ width: isMatch ? "50px" : "205px" }}>
            <div onClick={() => navigate("/dashboard/myrecipes")}>
              <KeyboardBackspaceOutlinedIcon style={{ cursor: "pointer" }} />
            </div>
          </Box>
          {/* </Button> */}
          <div
            onClick={() => {
              navigate("/dashboard/pastrecipes");
            }}
          >
            <Typography
              sx={{
                ...FontStyles.font40Prociono,
                fontSize: isMatch ? "24px" : "40px",
                fontFamily: "Playfair Display SC",
                color: Color.color10,
                cursor: "pointer",
              }}
            >
              Past Recipes
            </Typography>
          </div>
          {/* {!isMatch ? (
          <ButtonUI
            variant={"outlined"}
            label={"THIS WEEKS RECIPES"}
            style={{
              backgroundColor: Color.color1,
              borderColor: Color.color1,
              width: isMatch ? "100px" : "205px",
            }}
            lableStyle={{
              ...FontStyles.font16Prociono,
              fontSize: isMatch ? "10px" : "16px",
              color: Color.white,
            }}
          />
        ) : null} */}
          {isMatch ? (
            <Box
              sx={{ backgroundColor: "red" }}
              style={{ width: isMatch ? "50px" : "205px" }}
            ></Box>
          ) : (
            <ButtonUI
              variant={"outlined"}
              label={"THIS WEEKS RECIPES"}
              style={{
                backgroundColor: Color.color1,
                borderColor: Color.color1,
                width: isMatch ? "100px" : "205px",
              }}
              lableStyle={{
                ...FontStyles.font16Prociono,
                fontSize: isMatch ? "10px" : "16px",
                color: Color.white,
              }}
              action={() => {
                navigate("/dashboard/myrecipes");
              }}
            />
          )}
        </div>
        <Box
          sx={{
            border: 1,
            borderColor: Color.color17,
            height: 1,
            marginTop: 1,
            marginBottom: isMatch ? 1 : 0,
          }}
        ></Box>
        {isMatch ? (
          <ButtonUI
            variant={"outlined"}
            label={"THIS WEEKS RECIPES"}
            style={{
              backgroundColor: Color.color1,
              borderColor: Color.color1,
              width: isMatch ? "150px" : "205px",
            }}
            lableStyle={{
              ...FontStyles.font16Prociono,
              fontSize: isMatch ? "10px" : "16px",
              color: Color.white,
            }}
            action={() => {
              navigate("/dashboard/myrecipes");
            }}
          />
        ) : null}
        <div style={{ marginBottom: 2 }}>
          {category.map((item) => {
            let data = recipeData(item);
            return (
              <div>
                <Box
                  sx={{
                    border: 1,
                    borderColor: Color.color17,
                    height: 1,
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                ></Box>
                <div
                  onClick={() => {
                    navigate("/dashboard/allpastrecipes", {
                      state: {
                        recipesPage: true,
                        title: item,
                      },
                    });
                  }}
                >
                  <Typography
                    textAlign={"center"}
                    sx={{
                      ...FontStyles.font36PlayfairDisplaySC,
                      fontSize: isMatch ? "20px" : "36px",
                      color: Color.color10,
                      cursor: "pointer",
                    }}
                  >
                    {item}
                  </Typography>
                </div>
                <Box
                  sx={{
                    border: 1,
                    borderColor: Color.color17,
                    height: 1,
                    marginTop: 1,
                  }}
                ></Box>
                <div>
                  {recipeData(item) ? (
                    <CardUiTest
                      headerAlignTop={true}
                      cardData={recipeData(item)}
                      handleAdd={(id) => {
                        setRecipeDetailPageid(id);
                        if (!paymentStatus) {
                          setDaysModal(true);
                        }
                        if (paymentStatus) {
                          setSubcriptiomModal(true);
                        }
                      }}
                      handleFavourite={(id, titleRecipe) => {
                        setRecipeDetailPageid(id ? id : "");
                        setRecipeName(titleRecipe ? titleRecipe : "");
                        if (!paymentStatus) {
                          setAddFavoriteModal(true);
                        }
                        if (paymentStatus) {
                          setSubcriptiomModal(true);
                        }
                      }}
                      recipeDetail={(id) => {
                        setRecipeDetailPageid(id ? id : "");
                        navigate("/dashboard/recipedetail", {
                          state: {
                            recipePage: true,
                            recipeId: id,
                          },
                        });
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "red",
                        height: "159px",
                      }}
                    >
                      <Typography
                        sx={{ ...FontStyles.font20Prociono }}
                        textAlign={"center"}
                      >
                        No Record
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <FavoriteModal
          open={addFavoriteModal}
          onClickAddFavorite={() => addFavoriteRecipe()}
          onClickRemove={() => {
            setAddFavoriteModal(false);
            setRecipeDetailPageid("");
          }}
          onCloseHandle={() => setAddFavoriteModal(false)}
          titleRecipeName={recipeName}
        />
        <DaysModal
          open={daysModal}
          onClose={(close) => {
            setDaysModal(false);
            if (close) {
              // fetchRecipeInDetail();
            }
          }}
          data={recipeDetailPageId}
          pageTitle="recipes"
        />
        <SubcriptionModal
          open={subcriptionModal}
          onCloseHandle={() => setSubcriptiomModal(false)}
          upgradeNowAction={handaleSubcription}
        />
        <PaymentCard
          open={paymentModal}
          handleClose={() => {
            setPaymentModal(false);
            setSubcriptionStuts();
          }}
        />
        <FullScreenLoadder open={isLoading} />
      </Container>
    </div>
  );
};
export default PastRecpies;

const category = ["DINNER", "LUNCH", "BREAKFAST"];
