import React, { useEffect, useState } from "react";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import CardInput from "./card";
// import { loadStripe } from "@stripe/stripe-js";
import CardInput from "./card";
import { POST } from "src/config/https";
import APIS from "src/config/api";
import getUserData from "src/utils/getLocalStorageData";
import { toast } from "react-hot-toast";
import Color from "src/theme/color";
import FontStyles from "src/theme/font";
import CheckSubscription from "src/utils/checkSubscription";
import tik from "../../aasets/images/accept.png";
import ButtonUI from "../buttonUi";
import CustomTyphogrphy from "./customTyphogrphy";
import { PlanIdPro, StripePlanId, SubcriptionPrice, SubcriptionTax } from "src/utils/constant";
import palette from "src/theme/palette";
import { useNavigate } from "react-router-dom";
import logo from '../../aasets/images/Logo32.png'

// const stripePromise = loadStripe(
//   "pk_test_51NSwdeDo5PnTumNXjpUNoypbzGM5zY9xmLthAfzKdkUUzTvcauHteCyb77OPZidPxaV2JknIEvE546Cjx9lRTHBA00dQU6swgy"
// );
const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: "20vh auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    // justifyContent: "space-between",
  },
  button: {
    margin: "2em auto 1em",
  },
  customSpinner: {
    color: "#ffffff", // Replace with your custom spinner color
  },
  //Mise In place logo
  hoverEffect: {
    cursor: "pointer",
    // transition: "color 0.10s",
    "&:hover": {
      // color: "black",
      // backgroundColor: Color.color1,
    },
  },
  customButton: {
    "&:hover": {
      background: " #BFBF60",
      color: Color.color10,
      border: "2px solid #BFBF60",
    },
  },
  hoverEffectBox: {
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#D3D3D3",
    },
  },
});

function PaymentCard({ handleClose, open, lable }: HomePageProps): JSX.Element {
  const classes = useStyles();
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStuts] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMatch ? 300 : 400,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 2,
    height: isMatch ? 350 : undefined,
  };

  useEffect(() => {
    setSubcriptionStatus();
  }, []);

  const setSubcriptionStatus = async () => {
    await CheckSubscription();
    // setPaymentStuts(temp?.isSubscriptionRequired);
  };

  const handleSubmitSub = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const result: any = await stripe.createPaymentMethod({
      type: "card",
      card: elements && elements.getElement(CardElement)!,
      billing_details: {
        name: name,
      },
    });
    if (name.length < 1) {
      toast.error("Please enter card holder name");
      return;
    }
    if (result.error) {
      toast.error(result.error.message);
      return;
    } else {
      // const res = await axios.post(
      //   "https://dev-api.miseenplacemeals.com/api/Payment/do-payment",
      //   {
      //     paymentIntentId: result.paymentMethod!.id,
      //     // email,
      //   }
      // );
      let userData = getUserData();
      if (!userData) return;

      if (!result.paymentMethod!.id) return;
      setIsLoading(true);
      let params = {
        paymentIntentId: result.paymentMethod!.id,
        planId: StripePlanId
      };

      const res: any = await POST(APIS.DO_CHECK, params, userData.jwtToken);

      // const { result, status } = res;

      if (res.isSuccess) {
        setIsLoading(false);
        setSubcriptionStatus();
        setPaymentStuts(true);
        // toast.success("Payment is successful. Your subscription starts today");
        // navigate("/dashboard/app");
        setName("");
        // handleClose();
      } else {
        setPaymentStuts(false);
        setIsLoading(false);
        toast.error(res.errors[0]);
        setName("");
        // handleClose();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setPaymentStuts(false);
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {paymentStatus ? (
        <Box sx={style}>
          <Typography textAlign={"center"}>
            <img
              src={tik}
              alt={"fil"}
              style={{
                width: 70,
                height: 70,
                marginTop: 5,
              }}
            />
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{
              ...FontStyles.font20Prociono,
            }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            Payment is successful
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{
              ...FontStyles.font20Prociono,
            }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            Your subscription starts today
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <ButtonUI
              style={{
                backgroundColor: Color.color1,
                borderColor: Color.color1,
                width: "50%",
              }}
              lableStyle={{
                ...FontStyles.font20Prociono,
                color: Color.white,
              }}
              variant="outlined"
              label="Continue"
              action={() => {
                setPaymentStuts(false);
                handleClose();
                navigate("/dashboard/app");
              }}
            />
          </div>
        </Box>
      ) : (
        <Box
          sx={style}
          style={{
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Typography
            sx={{
              ...Styles.headerTitle,
              fontSize: isMatch ? "20px" : "28px",
              flexGrow: isMatch ? 1 : null,
              width: !isMatch ? "10%" : null,
              marginLeft: 0.5,
              color: Color.color10,
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              whiteSpace: "nowrap",
              cursor: "pointer",
              alignItems: 'center'
            }}
            style={{ width: "100%" }}
            className={classes.hoverEffect}
            display={'flex'}
            flexDirection={'row'}
          >
            <img
              src={logo}
              style={{
                width: "35px",
                height: "35px",
                marginRight: 10
              }}
            />
            Mise en Place
          </Typography>

          <Box sx={{ boxShadow: 2, borderRadius: 2, padding: 2, marginTop: 2 }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                paddingLeft: 0.5,
              }}
            >
              Order details
            </Typography>
            <Divider style={{ marginTop: 5 }} />
            <CustomTyphogrphy
              lable="Misen En Place Monthly Subscription"
              price={`$${SubcriptionPrice}`}
              styles={{
                marginTop: 15,
                color: Color.color4,
                fontWeight: "300",
                fontSize: "14px",
              }}
            />
          </Box>

          <Box sx={{ boxShadow: 2, borderRadius: 2, padding: 2, marginTop: 2 }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                paddingLeft: 0.5,
              }}
            >
              Summary
            </Typography>
            <Divider style={{ marginTop: 5 }} />
            <CustomTyphogrphy
              lable="Original Price "
              price={`$${SubcriptionPrice}`}
              styles={{
                marginTop: 15,
                color: Color.color4,
                fontSize: "14px",
                fontWeight: "300",
              }}
            />

            <Divider style={{ marginTop: 15 }} />

            <CustomTyphogrphy
              lable="Subtotal"
              price={`$${SubcriptionPrice}`}
              styles={{ marginTop: 15, fontWeight: "500" }}
            />

            <CustomTyphogrphy
              lable={`Tax(${SubcriptionTax}%):`}
              price={`+$${SubcriptionTax}.00`}
              styles={{
                marginTop: 15,
                color: Color.color4,
                fontSize: "14px",
                fontWeight: "300",
              }}
            />
            <Divider style={{ marginTop: 15 }} />
            <CustomTyphogrphy
              lable="Total"
              price={`$${SubcriptionPrice}`}
              styles={{ marginTop: 15, fontWeight: "500" }}
            />
          </Box>

          <Box
            sx={{
              boxShadow: 2,
              borderRadius: 2,
              padding: 2,
              marginTop: 2,
              paddingBottom: 2,
            }}
          >
            <TextField
              label="Name"
              id="outlined-email-input"
              helperText="Name you'll receive updates and receipts on"
              variant="outlined"
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
            <div style={{ marginTop: "8px" }}>
              <CardInput />
            </div>
          </Box>
          <div className={classes.div} style={{ marginTop: "15px" }}>
            <Button
              variant="outlined"
              color="primary"
              // className={classes.button}
              style={{}}
              // onClick={handleSubmitPay}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              // color="primary"
              // disabled={isLoading}
              className={classes.button}
              onClick={handleSubmitSub}
              sx={{
                height: "40px",
                width: "130px",
                marginLeft: "15px",
              }}
            >
              {isLoading ? (
                <CircularProgress
                  size={20}
                  style={{ color: Color.white }}
                  className={classes.customSpinner}
                />
              ) : (
                "Subscribe"
              )}
            </Button>
          </div>
        </Box>
      )}
    </Modal>
  );
}

export default PaymentCard;

const Styles = {
  headerTitle: {
    ...FontStyles.font28PlayfairDisplaySC,
    color: palette.mise_in_place.fontColor,
    fontWeight: "600px",
  },
};

interface HomePageProps {
  handleClose: () => void;
  open: boolean;
  lable?: string;
}
