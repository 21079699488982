export const FreeSubcriptionPrice = 0.0;
export const SubcriptionPrice = 9.99;
export const SubcriptionTax = 0;
export const GOOGLE_LOGIN_KEY = 'eyJhbGciOiJIUzI1NiIsv2Blcnj4jXOPMTu';
// export const GoogleLoginApiKey =
//   "656633880640-over3og0voko3diqbpnfv1jcff7j1ldi.apps.googleusercontent.com";
export const GoogleLoginApiKey =
  "285974718660-hln4kd8ast79kuub1egfa0fp3sk8lael.apps.googleusercontent.com";

//Production environment:
export const StripePublicKeyPro =
  "pk_live_51NTA9cJm23YWtWZPDcIj7GTZNOpQzhHhB2PAPZzTMnwlsgE2i7idjlEkGQ3TlifS0U8oAKqitjztVjNIoBLz0LWf00x5Aw0bFn";
export const PlanIdPro = "price_1NkOxoJm23YWtWZPv7rsOgCe"

//Devlopment environment:
export const StripePublicKeyDev =
  "pk_test_51NTA9cJm23YWtWZPUSUEMCfAGtNMQn3woztLuLlUqFIy3cq30tlfkiKa6weR8XEyzOJksUc1fMVKzB0Dp1rU6gp300QXIn0myL";
export const PlanIdDev = "price_1NkP4CJm23YWtWZPxXDZphJ2"

export const Redirect_Url =
  process.env.REACT_APP_TITLE === 'DEVELOPMENT'
    ? "https://dev.miseenplacemeals.com/dashboard/grocery"
    : "https://miseenplacemeals.com/dashboard/grocery"

const isProduction = true
export const StripePublicKey = isProduction ? StripePublicKeyPro : StripePublicKeyDev
export const StripePlanId = isProduction ? PlanIdPro : PlanIdDev

