import { Outlet } from "react-router-dom";

import Header from "./header";
import Footer from "./footer";
import "./dashborad.css";
// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

const DashboardLayout = () => {
  return (
    <div className="wrapper">
      <header>
        <Header />
      </header>
      <main style={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden" }}>
        <Outlet />
      </main>
      <footer
        style={{ backgroundColor: "#f1f1f1", bottom: 0, marginTop: "30px" }}
      >
        <Footer />
      </footer>
    </div>
  );
};
export default DashboardLayout;

// <Box sx={{ width: "100%" }} display={"flex"} flexDirection={"column"}>
// <Header />
// <main style={{ flexGrow: 1, overflowY: "auto" }}>
//   {/* <Outlet /> */}
//   <h1>main</h1>
// </main>
// {/* <Box
//   sx={{
//     position: "relative",
//     width: "100%",
//     bottom: 0,
//     color: Color.white,
//   }}
// > */}
// <footer style={{ bottom: 0 }}>
//   {/* <Footer /> */}
//   <h1>fe</h1>
// </footer>
// {/* </Box> */}
// </Box>
