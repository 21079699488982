import LandingHeader from "./LandingHeader";
import LandingVideo from "./LandingVideo";
import { Box, Divider } from "@mui/material";
import HowItWorks from "./HowItWorks";
import GetStarted from "./GetStarted";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("userData")) {
      navigate("/dashboard/app");
    }
  }, []);
  return (
    <>
      <Box
        px={{
          xs: 2,
          md: 0,
        }}
        overflow={"hidden"}
      >
        <Box
          pt={{
            md: 4,
          }}
          pb={{
            xs: 2,
            md: 0,
          }}
        >
          <LandingHeader />
        </Box>
        <Divider />
        <Box my={4}>
          <LandingVideo />
        </Box>
        <Box
          px={{
            xs: 0,
            md: 8,
          }}
        >
          <HowItWorks />
        </Box>
        <Divider />
        <Box
          pt={{
            md: 4,
          }}
          px={{
            xs: 0,
            md: 16,
          }}
        >
          <GetStarted />
        </Box>
      </Box>
    </>
  );
};
export default Home;
