import { makeStyles } from "@material-ui/core";
import { OutlinedInputProps, TextField } from "@mui/material";
import Color from "src/theme/color";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: Color.color1, // Replace #f00 with the desired border color on hover
    },
  },
}));

const FilePicker = ({ onChange }: InputFilesProps) => {
  const classes = useStyles();
  return (
    <TextField
      id="recipe-name"
      type="file"
      variant="outlined"
      style={{ width: "100%" }}
      onChange={onChange}
      size="small"
      className={classes.textField}
    />
  );
};
export default FilePicker;

interface InputFilesProps {
  onChange?: OutlinedInputProps["onChange"];
}
