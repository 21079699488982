/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import TextBox from "src/components/textBox";
import { usePersistentState } from "src/contexts/GlobalContext";
import Color from "src/theme/color";
import FontStyles from "src/theme/font";
import comonStyles from "../comonStyles";
import Styles from "./styles";
import ButtonUI from "src/components/buttonUi";
import { useNavigate } from "react-router-dom";
import { POST } from "src/config/https";
import APIS from "src/config/api";

import { isEmpty, isValidEmail } from "src/utils/Validator";
import ValidationError from "src/components/ValidationError";
import { TextField, makeStyles } from "@material-ui/core";

import FullScreenLoadder from "src/components/fullScreenLoadder";
import { toast } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  boxWithShadow: {
    backgroundColor: "#fff",

    boxShadow: ` 0px 5px 5px -3px rgba(0, 0, 0, 0.2),  /* Top */
      0px -5px 5px -3px rgba(0, 0, 0, 0.2), /* Bottom */
      5px 0px 5px -3px rgba(0, 0, 0, 0.2),  /* Right */
      -5px 0px 5px -3px rgba(0, 0, 0, 0.2)   /* Left */`,
  },
  textarea: {
    resize: "vertical",
  },
}));

const ContactUs = () => {
  const [, dispatch]: any = usePersistentState();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const classes = useStyles();
  useEffect(() => {
    dispatch({ isSearch: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addDetails = async () => {
    setIsValidation(true);
    if (!validator()) return;
    setIsLoading(true);
    let params = {
      name: name,
      email: email,
      message: message,
    };
    await POST(APIS.CONTACT_US, params)
      .then((res) => {
        toast.success("Admin will contact you shortly.");
        setEmail("");
        setMessage("");
        setName("");
        setFormErrors({});
        setIsValidation(false);
      })
      .catch((error) => {
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isValidation) {
      validator();
    }
  }, [email]);

  const validator = () => {
    const errors: Record<string, string> = {};
    if (isEmpty(name)) {
      errors.name = "Please enter a name";
    }
    if (isEmpty(email)) {
      errors.email = "Please enter a email";
    }
    if (!isEmpty(email) && !isValidEmail(email)) {
      errors.email = "Please enter a valid email";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const saveRichBox = (data: any) => {
    setMessage(data);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container component="main" maxWidth="sm" sx={Styles.continer}>
        <Box
          sx={{ ...comonStyles.cardStyle, marginTop: 5 }}
          className={classes.boxWithShadow}
        >
          <Typography
            style={{
              ...FontStyles.font24PlayfairDisplaySC,
              color: Color.color4,
            }}
            textAlign={"center"}
          >
            Contact Us
          </Typography>
          <TextBox
            id={"name"}
            value={name}
            placeholder={"Name"}
            required={true}
            fullWidth={true}
            label={"Name"}
            name={"Name"}
            icon={"Name"}
            onChange={(e) => setName(e.target.value)}
          />
          <ValidationError formErrors={formErrors.name} />
          <TextBox
            id={"email"}
            placeholder={"Email"}
            value={email}
            required={true}
            fullWidth={true}
            label={"Email"}
            name={"email"}
            icon={"Email"}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ValidationError formErrors={formErrors.email} />
          <Typography
            textAlign={"start"}
            alignItems={"flex-start"}
            sx={{
              fontFamily: "Prociono",
              fontSize: 14,
              fontWeight: 400,
              width: "100%",
              marginTop: "16px",
              color: Color.black,
            }}
          >
            Message
          </Typography>

          <TextField
            id="outlined-textarea"
            placeholder="Message"
            value={message}
            multiline
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{ className: classes.textarea }}
            onChange={(e) => setMessage(e.target.value)}
          />
          <ValidationError formErrors={formErrors.stepsToMake} />
          <ButtonUI
            type={"submit"}
            label={"Submit"}
            variant={"contained"}
            sx={{ mt: 3, mb: 2 }}
            style={comonStyles.SignInUpButton}
            fullWidth={true}
            action={addDetails}
          />
        </Box>
        <FullScreenLoadder open={isLoading} />
      </Container>
    </div>
  );
};
export default ContactUs;
