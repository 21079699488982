import APIS from "src/config/api";
import { GET } from "src/config/https";

const ProfileImageUPdate = async (id: number, token: any) => {
  let response: any = {};
  let params = {
    id: String(id),
  };

  await GET(APIS.GET_USER_BY_ID, params, token)
    .then(async (res: any) => {
      //   if (res.isSuccess) {
      response = res;

      localStorage.setItem(
        "profileImage",
        JSON.stringify(res.result.profilePicturePath)
      );

      return response.result.profilePicturePath;
    })
    .catch((error) => {
      console.error("error", error);
    });
  return response.result.profilePicturePath;
};
export default ProfileImageUPdate;
