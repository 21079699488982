import { Box, Typography } from "@mui/material";
import * as React from "react";
import Color from "src/theme/color";

const LandingVideo = () => {
  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          color={Color.color10}
          fontFamily={"Playfair Display SC"}
          fontSize={{
            xs: "1.5rem",
            md: "2.5rem",
          }}
          fontWeight={400}
          fontStyle={"normal"}
          lineHeight={"normal"}
          textTransform={"uppercase"}
          mb={4}
          align="center"
        >
          Discover the joy of effortless home cooking
        </Typography>
        <Box
          width={{
            md: "40%",
          }}
          height={{
            xs: "14rem",
            md: "27.625rem",
          }}
        >
          <iframe
            src="https://drive.google.com/file/d/1LGefENDY4Ys2N1GgSC9WxhxxKga0Mimb/preview"
            width={"100%"}
            height={"100%"}
          ></iframe>
        </Box>
      </Box>
    </>
  );
};
export default LandingVideo;
