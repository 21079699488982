import { Box, Modal, Typography, IconButton, Link } from "@mui/material";
import { BASEURL, POST } from "../../config/https";
import APIS from "../../config/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { isEmpty, isValidEmail } from "../../utils/Validator";
import comonStyles from "../../pages/comonStyles";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import ButtonUI from "../buttonUi";
import Styles from "./styles";
import ValidationError from "../ValidationError";
import TextBox from "../textBox";
import Google from "../../aasets/images/Socialicon.png";
import { makeStyles } from "@material-ui/core";
import { usePersistentState } from "../../contexts/GlobalContext";
import IsPhone from "../../utils/phoneDesktopSize";
import { toast } from "react-hot-toast";
import ProfileImageUPdate from "src/utils/profileImageUpdte";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { GOOGLE_LOGIN_KEY } from "src/utils/constant";
import FullScreenLoadder from "../fullScreenLoadder";
import Color from "src/theme/color";

const useStyles = makeStyles((theme) => ({
  modal: {
    border: "none",
    borderColor: "white",
  },
}));
// eslint-disable-next-line react-hooks/rules-of-hooks

const LoginModal = ({
  open,
  onClose,
  data,
  openWeekModal,
  openFavoriteModal,
}: LoginModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch]: any = usePersistentState();
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [user, setUser] = useState<any>([]);
  let ismatch = IsPhone();
  const navigate = useNavigate();

  useEffect(() => {
    if (isValidation) {
      validator();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, isValidation]);

  const onChangeText = (key: string, e: any) => {
    switch (key) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
    }
  };

  const signIn = async () => {
    setIsValidation(true);
    if (!validator()) return;
    setIsLoading(true);
    const params = {
      email: email,
      password: password,
    };
    await POST(APIS.LOGIN, params)
      .then(async (res: any) => {
        if (res.isSuccess && res.result.roles[0].name === "User") {
          await dispatch({ users: res.result });
          await localStorage.setItem("userData", JSON.stringify(res.result));
          await dispatch({ users: res.result });
          let temp = await ProfileImageUPdate(
            res?.result?.id,
            res?.result?.jwtToken
          );

          dispatch({ isProfile: temp })
          if (res.result) {
            if (open.isDeatil && data) {
              navigate("/dashboard/recipedetail", {
                state: {
                  recipePage: true,
                  recipeId: data,
                },
              });
              window.location.reload();
            }
            if (open.isRecipes) {
              navigate("/dashboard/myrecipes");
              window.location.reload();
            }
            if (open.isGrocery) {
              navigate("/dashboard/grocery");
              window.location.reload();
            }
            if (open.isFavorite && open.isLogin) {
              navigate("/dashboard/favorite");
              window.location.reload();
            }
            if (open.isAdd) {
              openWeekModal && openWeekModal();
            }

            if (open.isFavorite) {
              openFavoriteModal && openFavoriteModal();
            } else {
              navigate("/dashboard/app");
              window.location.reload();
            }
          } else {
            navigate("/dashboard/app");
            window.location.reload();
          }
        } else {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
    setIsLoading(false);
  };

  const validator = () => {
    const errors: Record<string, string> = {};
    if (isEmpty(email)) {
      errors.email = "Please enter a email";
    }
    if (!isEmpty(email) && !isValidEmail(email)) {
      errors.email = "Please enter a valid email";
    }
    if (isEmpty(password)) {
      errors.password = "Please enter a password";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: ismatch ? 300 : 400,
    bgcolor: "background.paper",
    border: "none",
    borderColor: "white",
    borderRadius: "8px",
    boxShadow: 24,
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse: any) => googleWithLogin(codeResponse),
    onError: (error: any) => { },
  });

  const googleWithLogin = async (res: any) => {
    if (!res) return;
    let params = {
      accessToken: res.access_token,
    };
    setIsGoogleLogin(true);
    axios
      .post(BASEURL + APIS.GOOGLE_LOGIN, params, {
        headers: {
          Accept: "application/json",
          "x-api-key": GOOGLE_LOGIN_KEY,
        },
      })
      .then(async (response: any) => {
        const res = response.data;
        setIsGoogleLogin(false);
        if (res.isSuccess) {
          await dispatch({ users: res.result });
          await localStorage.setItem("userData", JSON.stringify(res.result));
          await dispatch({ users: res.result });
          let temp = await ProfileImageUPdate(
            res?.result?.id,
            res?.result?.jwtToken
          );
          dispatch({ isProfile: temp })
          if (res.result) {
            if (open.isDeatil && data) {
              navigate("/dashboard/recipedetail", {
                state: {
                  recipePage: true,
                  recipeId: data,
                },
              });
              window.location.reload();
            }
            if (open.isRecipes) {
              navigate("/dashboard/myrecipes");
              window.location.reload();
            }
            if (open.isGrocery) {
              navigate("/dashboard/grocery");
              window.location.reload();
            }
            if (open.isFavorite && open.isLogin) {
              navigate("/dashboard/favorite");
              window.location.reload();
            }
            if (open.isAdd) {
              setTimeout(() => {
                openWeekModal && openWeekModal();
              }, 3000)
            }

            if (open.isFavorite) {
              setTimeout(() => {
                openFavoriteModal && openFavoriteModal();
              }, 3000)

            }
            else {
              navigate("/dashboard/app");
              window.location.reload();
            }
          } else {
            navigate("/dashboard/app");
            window.location.reload();
          }
        } else {
          toast.error(res.error);
        }
      })
      .catch((err) => {
        setIsGoogleLogin(false);
      })
      .finally(() => {
        setIsGoogleLogin(false);
      });
  };

  return (
    <>
      <Modal
        classes={{ root: classes.modal }}
        sx={{ border: "none" }}
        style={{ border: "none" }}
        open={open.loginModalData}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={comonStyles.cardStyle}>
            <Typography style={comonStyles.headerText}>Sign In</Typography>
            <Typography style={comonStyles.headerDecText}>
              Please Sign In to Continue.
            </Typography>
            <TextBox
              id={"email"}
              placeholder={"Email"}
              required={true}
              fullWidth={true}
              label={"Email"}
              name={"email"}
              icon={"Email"}
              onChange={(e) => onChangeText("email", e)}
            />
            <ValidationError formErrors={formErrors.email} />

            <TextBox
              id={"password"}
              placeholder={"Password"}
              required={true}
              fullWidth={true}
              label={"Password"}
              name={"password"}
              icon={"LockIcon"}
              onChange={(e) => onChangeText("password", e)}
              passwordVisibaleData={true}
            />

            <ValidationError formErrors={formErrors.password} />
            <Box
              display="inline-flex"
              flexDirection={"row"}
              sx={{
                width: "100%",
                marginTop: "16px",
                height: 21,
                justifyContent: "space-between",
              }}
            >
              <Box display="inline-flex" flexDirection={"row"}>
                <IconButton
                  //   onClick={() => setRememberMe(!rememberMe)}
                  sx={{ padding: 0 }}
                >
                  {rememberMe ? (
                    <CheckBoxOutlinedIcon fontSize="small" />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
                <Typography style={Styles.remeberMeText}>
                  Remember Me
                </Typography>
              </Box>
              <Link
                onClick={() => navigate("/forgotpassword")}
                style={Styles.forgetPasswordText}
              >
                Forgot password?
              </Link>
            </Box>

            <ButtonUI
              type={"submit"}
              label={"Sign In"}
              variant={"contained"}
              sx={{ mt: 3, mb: 2 }}
              style={comonStyles.SignInUpButton}
              fullWidth={true}
              action={signIn}
              isLoading={isLoading}
            />
            <ButtonUI
              type={"submit"}
              label={"Sign in with Google"}
              variant={"outlined"}
              style={comonStyles.GoogleButtonTxt}
              fullWidth={true}
              image={Google}
              isLoading={isGoogleLogin}
              lodderColor={Color.color1}
              action={() => loginWithGoogle()}
            />
            <Box
              display="inline-flex"
              flexDirection={"row"}
              sx={{
                width: "100%",
                marginTop: "16px",
                height: 21,
                mt: 4,
              }}
            >
              <Typography style={comonStyles.anAccountText} textAlign="center">
                Don’t have an account yet?
                <Link
                  onClick={() => navigate("/signup")}
                  style={comonStyles.singUpInTextLinkButton}
                >
                  Sign Up
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
      <FullScreenLoadder open={isGoogleLogin} />
    </>
  );
};
export default LoginModal;

interface LoginModalProps {
  open: any;
  onClose: () => void;
  data?: string | null;
  openWeekModal?: () => void;
  openFavoriteModal?: () => void;
}
