import { Outlet } from "react-router-dom";

import Header from "../dashboard/header";
import Footer from "../dashboard/footer";

export default function SimpleLayout() {
  return (
    <div className="wrapper">
      <header>
        <Header />
      </header>
      <main style={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden" }}>
        <Outlet />
      </main>
      <footer
        style={{ backgroundColor: "#f1f1f1", bottom: 0, marginTop: "30px" }}
      >
        <Footer />
      </footer>
    </div>
  );
}
