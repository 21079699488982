import axios from "axios";

import { parseAxiosError } from "./parseAxiosError";
import { RequestRefreshToken } from "./RequestRefreshToken";
import { toast } from "react-hot-toast";

const commonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const BASEURL = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: BASEURL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error) => {
    const originalRequest = error?.config;
    if (error.response.status === 400) {
      const { data } = error?.response;
      if (data?.errors[0]) {
        toast.error(data.errors[0]);
      }
    }

    originalRequest._retry = originalRequest._retry ?? 1;
    if (error.response.status === 401 && originalRequest._retry <= 2) {
      originalRequest._retry++;
      const getData: any = await RequestRefreshToken();
      const newToken = getData?.result;
      if (newToken) {
        // Update the authorization header with the new access token
        originalRequest.headers.Authorization = `Bearer ${newToken.jwtToken}`;
        return axiosInstance(originalRequest);
      }
      return Promise.reject(error);
    }
    if (error.response.status === 404) {
      return Promise.reject(error);
    }
    // if (error.response.status === 401) {
    //   return Promise.reject(error);

    //   // return Promise.reject(error);
    // }
  }
);

export const GET = async (
  url: string,
  params?: { [name: string]: string },
  token?: string
) => {
  // const authorization = token ? "Bearer" + token : "";
  const authorization = token ? token : "";

  return new Promise(function (resolve, reject) {
    axiosInstance({
      params,
      method: "get",
      url: url,
      headers: {
        ...commonHeaders,
        Authorization: authorization,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch(async (e) => {
        const error = await parseAxiosError(e);
        reject(error);
      });
  });
};

export const POST = async (url: string, data?: any, token?: string) => {
  // const authorization = token ? "Bearer" + token : "";
  const authorization = token ? `Bearer ${token}` : "";

  return new Promise(function (resolve, reject) {
    axiosInstance({
      data,
      method: "post",
      url: url,
      headers: {
        ...commonHeaders,
        Authorization: authorization,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch(async (e) => {
        const error = await parseAxiosError(e);
        reject(error);
      });
  });
};

export const PUT = async (url: string, data?: any, token?: string) => {
  // const authorization = token ? "Bearer" + token : "";
  const authorization = token ? `Bearer ${token}` : "";

  return new Promise(function (resolve, reject) {
    axiosInstance({
      data,
      method: "put",
      url: url,
      headers: {
        ...commonHeaders,
        Authorization: authorization,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch(async (e) => {
        const error = await parseAxiosError(e);
        reject(error);
      });
  });
};
