import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import imageCheck from "../../aasets/images/regularcheck-circle (1).png";
import FontStyles from "../../theme/font";
import Color from "../../theme/color";

const GroceryList = ({ item }: GroceryListProps) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));
  const firstCharUperCase = (str: string) => {
    const data = str.charAt(0).toUpperCase() + str.slice(1);
    return data;
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, sm: 4, md: 12 }}
        sx={{
          marginTop: 5,
        }}
      >
        {item.map((x: any) => {
          return (
            <Grid container item sm={6} md={12 / 5} xs={6}>
              <Grid xs={3}>
                <Typography
                  alignItems={"center"}
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ marginTop: "2px" }}
                >
                  <img
                    src={imageCheck}
                    style={{ width: "20px", height: "20px" }}
                  />
                </Typography>
              </Grid>
              <Grid xs={9}>
                <Grid xs={12}>
                  <Typography
                    sx={{
                      ...FontStyles.font16Prociono,
                      color: Color.color4,
                      paddingLeft: isMatch ? 0.5 : 0,
                    }}
                  >
                    {firstCharUperCase(x.name.split(",")[0])}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography
                    sx={{
                      ...FontStyles.font16Prociono,
                      color: Color.color16,
                      paddingLeft: isMatch ? 0.5 : 0,
                    }}
                  >
                    {`${x.quantity} ${x.unit}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
export default GroceryList;

interface GroceryListProps {
  item?: any;
}
