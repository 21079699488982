/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import VectorRound from "../../../aasets/images/man-user.png";

import Styles from "./styles";
import Color from "../../../theme/color";
import ButtonUI from "../../../components/buttonUi";

import FontStyles from "../../../theme/font";
import { usePersistentState } from "../../../contexts/GlobalContext";
import { makeStyles } from "@material-ui/core";
import getUserData from "../../../utils/getLocalStorageData";
import { CheckLogin } from "../../../utils/checkLoign";
import GetUserProfile from "src/utils/getUserProfile";

const navItems = ["All Recipes", "My Recipes", "Grocery List", "Favorites"];
const drawerWidth = 240;

const useStyles = makeStyles({
  hoverEffect: {
    cursor: "pointer",
    // transition: "color 0.10s",
    "&:hover": {
      // color: "black",
      // backgroundColor: Color.color1,
    },
  },
  customButton: {
    "&:hover": {
      background: " #BFBF60",
      color: Color.color10,
      border: "2px solid #BFBF60",
    },
  },
  hoverEffectBox: {
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#D3D3D3",
    },
  },
});

export default function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchField, setSearchField] = useState(false);

  const [state, dispatch]: any = usePersistentState();
  const [userData, setUserData] = useState<any>({});
  const [imageProfile, setImageProfile] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const navItemsPhone = [
    "All Recipes",
    "My Recipes",
    "Grocery List",
    "Favorites",
    "My Profile",
    userData ? "Log Out" : "Login",
  ];

  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    let temp: any = getUserData();
    let data = GetUserProfile();
    dispatch({ isProfile: data })
    setImageProfile(String(data));
    setUserData(temp);
  }, []);



  useEffect(() => {
    dispatch({ search: "" });
    if (location.pathname === "/recipes") {
      dispatch({ isSearch: false });
    }
    if (location.pathname === "/grocery") {
      dispatch({ isSearch: false });
    }
    if (location.pathname === "/myprofile") {
      dispatch({ isSearch: false });
    }
    if (location.pathname === "/pastrecipes") {
      dispatch({ isSearch: false });
      setSearchField(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const navigateLogin = () => {
    let temp = getUserData();
    if (!temp) {
      navigate("/login");
    }
  };

  useEffect(() => {
  }, [state.isProfile])

  const logOut = () => {
    localStorage.clear();
    window.localStorage.clear();
    // window.location.reload();
    // navigate("dashborad/app");
  };

  const navigateMenu = async (key: string) => {
    if (key === "All Recipes") {
      setSearchField(false);

      navigate("/dashboard/app");
    }
    if (location.pathname === "/login") return;

    let temp = CheckLogin();
    if (!temp && location.pathname !== "/") {
      dispatch({ isLogin: true });
      return;
    }

    if (key === "All Recipes") {
      setSearchField(false);

      navigate("/dashboard/app");
    }
    if (key === "My Recipes") {
      setSearchField(false);
      navigate("/dashboard/myrecipes");
    }

    if (key === "Grocery List") {
      setSearchField(false);
      navigate("/dashboard/grocery");
    }
    if (key === "Favorites") {
      setSearchField(false);
      navigate("/dashboard/favorite");
    }
    if (key === "My Profile") {
      navigate("/dashboard/profile");
      handleClose();
    }
    if (key === "Log Out") {
      localStorage.clear();
      window.localStorage.clear();
      // navigate("/dashboard/app");
      navigate("/login");
    }
    if (key === "Login") {
      await localStorage.clear();
      navigate("/login");
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", height: 56 }}>
      <Box onClick={() => navigate("/")}>
        <Typography
          variant="h6"
          sx={{
            my: 2,
            fontFamily: "layfair Display SC",
            color: Color.color10,
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            cursor: "pointer",
          }}
        >
          Mise-En-Place
        </Typography>
      </Box>
      <Divider />
      <List>
        {navItemsPhone.map((item) => {
          if (item === "My Profile" && !userData) {
            return;
          }
          return (
            <ListItem key={item} disablePadding>
              <ListItemButton
                sx={{ textAlign: "center" }}
                onClick={() => navigateMenu(item)}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <>
      <React.Fragment>
        <Box
          sx={{
            ...Styles.appbarContiner,
            borderBottom: 3,
            borderColor: Color.color13,
            display: "flex",
            flex: 1,
            height: "56px",
          }}
        >
          <Grid container xs={12} justifyContent="center" alignSelf="center">
            <Grid xs={isMatch ? 5 : 3} alignSelf={"center"}>
              <Box
                onClick={() => navigate("/")}
                display="flex"
                flexDirection="row"
              // justifyContent={searchField && isMatch ? "flex-end" : undefined}
              >
                {/* {state.isSearch && searchField && isMatch ? // </Box> //   /> //     } //       await dispatch({ search: e.target.value }) //     onChange={async (e) => //     }} //       width: "100%", //       bottom: 0, //       paddingBottom: 0, //       marginBottom: 0, //     sx={{ //     fullWidth //     variant="standard" //     type="search" //     placeholder="Search" //     id="outlined-search" //     size="small" //   <TextField // <Box sx={{ marginLeft: 2 }} width={"100%"}> */}
                {/* null : ( */}
                <Box>
                  <Link sx={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        ...Styles.headerTitle,
                        fontSize: isMatch ? "20px" : "28px",
                        flexGrow: isMatch ? 1 : null,
                        width: !isMatch ? "10%" : null,
                        marginLeft: isMatch ? "10px" : "28px",
                        color: Color.color10,
                        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                      }}
                      style={{ width: "100%" }}
                      className={classes.hoverEffect}
                    >
                      Mise en Place
                    </Typography>
                  </Link>
                </Box>
                {/* )} */}
              </Box>
            </Grid>
            <Grid
              xs={isMatch ? 7 : 9}
              style={{
                textAlign: "end",
                paddingTop: 3,
              }}
            >
              {isMatch && state.isSearch ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems={"center"}
                  >
                    {state.isSearch && searchField && isMatch ? (
                      <Box sx={{ marginLeft: 2 }} width={"100%"}>
                        <TextField
                          size="small"
                          id="outlined-search"
                          placeholder="Search"
                          type="search"
                          variant="standard"
                          fullWidth
                          sx={{
                            marginBottom: 0,
                            paddingBottom: 0,
                            bottom: 0,
                            width: "100%",
                          }}
                          onChange={async (e) =>
                            await dispatch({ search: e.target.value })
                          }
                        />
                      </Box>
                    ) : null}
                    {/* {isMatch && !searchField ? ( */}
                    <IconButton
                      size="large"
                      aria-label="search"
                      color="inherit"
                      onClick={async () => {
                        setSearchField(!searchField);
                      }}
                      style={{ paddingLeft: 0 }}
                    >
                      <SearchIcon style={Styles.searchIcon} />
                    </IconButton>
                    {/* ) : null} */}
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={Styles.menuIconContiner}
                      style={{ paddingRight: 1, paddingLeft: 1 }}
                    >
                      <MenuIcon style={Styles.menuIcon} />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <Box display="flex" justifyContent="flex-end">
                  {isMatch ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={Styles.menuIconContiner}
                    >
                      <MenuIcon style={Styles.menuIcon} />
                    </IconButton>
                  ) : null}
                </Box>
              )}

              {!isMatch ? (
                <Box
                  sx={{
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                >
                  <Box sx={{ ...Styles.headerTabContiner }}>
                    {navItems.map((item, index) => (
                      <ButtonUI
                        key={index}
                        label={item}
                        variant="text"
                        sx={Styles.headerButtonBody}
                        style={{ height: "44px", top: "5px" }}
                        action={() => navigateMenu(item)}
                      />
                    ))}

                    <Box
                      sx={{
                        marginLeft: 2,
                        marginRight: 1,
                      }}
                      display="inline-flex"
                      flexDirection={"row"}
                    >
                      {searchField && state.isSearch ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <TextField
                            size="small"
                            id="outlined-search"
                            placeholder="Search"
                            type="search"
                            variant="standard"
                            onChange={async (e) =>
                              await dispatch({ search: e.target.value })
                            }
                          />
                        </Box>
                      ) : null}
                      {state.isSearch && (
                        <Box>
                          <IconButton
                            size="medium"
                            onClick={() => setSearchField(!searchField)}
                          >
                            <SearchIcon style={Styles.searchIcon} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        borderRight: 1,
                        borderColor: Color.color12,
                        height: "40px",
                      }}
                    ></Box>
                    <Box
                      alignSelf={"center"}
                      display="inline-flex"
                      flexDirection={"row"}
                      sx={{
                        marginLeft: 1,
                        marginRight: "24px",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        onClick={() => navigateLogin()}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          {/* <IconButton size="medium" sx={{backgroundColor:'red',paddingRight:0}}> */}
                          {/* {imageProfile ? ( */}
                          {state.isProfile ? (
                            // <img
                            //   alt=""
                            //   src={imageProfile}
                            //   style={{ width: "25px", height: "25px" }}
                            // />
                            <Avatar
                              alt="Remy Sharp"
                              style={{ width: "25px", height: "25px" }}
                              src={state.isProfile}
                            />
                          ) : (
                            // <AccountCircleRoundedIcon
                            //   style={Styles.searchIcon}
                            // />

                            <Avatar
                              alt="Remy Sharp"
                              style={{ width: "25px", height: "25px" }}
                              src={VectorRound}
                            />
                            //   <img
                            //   alt=""
                            //   src={VectorRound}
                            //   style={{ width: "25px", height: "25px" }}
                            // />
                          )}
                          {/* </IconButton> */}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            sx={{ textTransform: "none" }}
                            onClick={handleClick}
                          >
                            <Typography
                              textAlign={"left"}
                              style={{
                                ...FontStyles.font16Prociono,
                                fontFamily: "Playfair Display SC",
                                color: Color.color10,
                                whiteSpace: "nowrap",
                              }}
                              className={classes.hoverEffect}
                            >
                              {userData ? userData.name : "Login"}
                            </Typography>
                          </Button>
                          <Popover
                            id={id}
                            open={open && userData?.name}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Box sx={{ width: 200 }}>
                              <Box onClick={() => navigateMenu("My Profile")}>
                                <Button
                                  sx={{
                                    ...FontStyles.font16Prociono,
                                    textTransform: "none",
                                    color: Color.color4,
                                    width: "100%",
                                    height: 42,
                                  }}
                                >
                                  <Typography
                                    textAlign={"center"}
                                    sx={{
                                      marginTop: 1,
                                      marginBottom: 1,
                                      ...FontStyles.font16Prociono,
                                    }}
                                  >
                                    My Profile
                                  </Typography>
                                </Button>
                              </Box>
                              <Divider sx={{ mb: 0 }} />
                              <Divider />
                              <Button
                                disabled
                                sx={{
                                  ...FontStyles.font16Prociono,
                                  textTransform: "none",
                                  color: Color.color4,
                                  width: "100%",
                                  height: 42,
                                }}
                              >
                                <Typography
                                  textAlign={"center"}
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    color: Color.color4,
                                    ...FontStyles.font16Prociono,
                                  }}
                                >
                                  {userData?.email}
                                </Typography>
                              </Button>
                            </Box>
                            <Divider sx={{ mb: 0 }} />
                            <Divider />

                            <Box sx={{ m: 1 }}>
                              <Box sx={{ m: 1 }}>
                                <Button
                                  color="primary"
                                  fullWidth
                                  onClick={logOut}
                                  sx={{
                                    color: Color.white,
                                    backgroundColor: Color.color1,
                                    height: 33,
                                  }}
                                  className={classes.customButton}
                                >
                                  <LogoutIcon sx={{ mr: 1 }} />
                                  Sign out
                                </Button>
                              </Box>
                            </Box>
                          </Popover>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </React.Fragment>
    </>
  );
}
