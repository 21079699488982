import Color from "../../theme/color";
import FontStyles from "../../theme/font";

const Styles = {
  continer: {
    marginTop: 15,
    marginBottom: 15,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    width: "100%",
  },
  body: {
    boxShadow: 4,
    borderRadius: 2,
    px: 2,
    py: 3,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  forgetPassword: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "right",
  },
  googleSignIn: {
    mt: 1,
    mb: 1,
    borderColor: Color.color1,
    fontFamily: "Prociono",
    color: Color.black,
  },

  remeberMeText: {
    ...FontStyles.font14Prociono,
    color: Color.color4,
    paddingLeft: "8px",
  },
  forgetPasswordText: {
    ...FontStyles.font14Prociono,
    color: Color.color6,
    textDecoration: "none",
  },
};
export default Styles;
