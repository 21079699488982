import axios from "axios";
import getUserData from "../utils/getLocalStorageData";
import APIS from "./api";
import SetLocalStorge from "../utils/setLocalStorage";

export const RequestRefreshToken = async () => {
  let data = getUserData();
  if (!data) return;
  axios.interceptors.request.use((request) => {
    return request;
  });

  axios.defaults.headers.common["refreshToken"] = data.refreshToken;
  const param = {};
  return new Promise(function (resolve, reject) {
    axios
      .post(APIS.REFRESH_TOKEN, param)
      .then(async (res: any) => {
        if (res.data.isSuccess) {
          let data = res.data.result;
          await localStorage.clear();
          await SetLocalStorge(data);
          resolve(res.data);
        } else {
          await localStorage.clear();
          window.location.href = "/dashboard";
          reject(res);
        }
      })
      .catch(async (e: any) => {
        reject(e);
        // window.location.href = "/dashboard";
        // await localStorage.clear();
        // window.location.assign("/login");
      });
  });
};
