
import {
    Box,
    Modal,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Color from "src/theme/color";
import FontStyles from "src/theme/font";
import IsPhone from "src/utils/phoneDesktopSize";
import ButtonUI from "../buttonUi";


function GrocerySuccessModal({ handleClose, open, modalType }: HomePageProps): JSX.Element {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

    const isPhone = IsPhone();


    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isMatch ? 300 : 400,
        bgcolor: "background.paper",
        border: "2px solid white",
        boxShadow: 24,
        p: 2,
        height: 150,
    };

    return (
        <Modal
            open={open}
            onClose={() => { }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <Typography
                        textAlign={"center"}
                        sx={{
                            ...FontStyles.font20Prociono,
                        }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        {modalType === 'Kroger purchase groceries' ?
                            "You will redirected in 3 seconds to Kroger's Website to purchase groceries" :
                            "You will redirected to Kroger's Cart in 3 seconds to complete your order"}
                    </Typography>
                </div>
                {/* <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 10,
                    }}
                >
                    <ButtonUI
                        style={{
                            backgroundColor: Color.color6,
                            borderColor: Color.color6,
                            width: "50%",
                        }}
                        lableStyle={{
                            ...FontStyles.font20Prociono,
                            color: Color.white,
                        }}
                        variant="outlined"
                        label="Cancel"
                        action={() => {
                            handleClose();
                        }}
                    />
                </div> */}
            </Box>

        </Modal>
    );
}

export default GrocerySuccessModal;

interface HomePageProps {
    handleClose: () => void;
    open: boolean;
    lable?: string;
    modalType?: string
}
