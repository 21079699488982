import { Typography, useMediaQuery, useTheme } from "@mui/material";

import { makeStyles } from "@material-ui/core";
import FontStyles from "src/theme/font";

const useStyles = makeStyles((theme) => ({
  boxWithShadow: {
    // Set the size and background color of the box
    // width: '200px',
    // height: '100px',
    backgroundColor: "#fff",

    // Apply box shadow to the bottom and top sides
    boxShadow: ` 0px 5px 5px -3px rgba(0, 0, 0, 0.2),  / Top /
      0px -5px 5px -3px rgba(0, 0, 0, 0.2), / Bottom /
      5px 0px 5px -3px rgba(0, 0, 0, 0.2),  / Right /
      -5px 0px 5px -3px rgba(0, 0, 0, 0.2)   / Left /`,
  },
}));

const Privacy = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "80%" }}>
        <Typography sx={{ ...FontStyles.font20Prociono, marginTop: 7 }}>
          Mise en Place Privacy Policy & Notice
          <br />
          Last modified: August 11, 2023
          <br /> <br />
          Mise en Place Meals LLC DBA Mise en Place (collectively, “Mise en Place”, “we”, “us”, or “our”) respects your privacy and is committed to protecting your personal information that may be collected. This privacy policy and notice (“Notice”) describes how we collect, use, and share your personal information when you use https://miseenplacemeals.com/, and any other Mise en Place website or client portal (collectively, the “Websites”), any Mise en Place mobile or desktop apps (the “Apps”), and any content, functionality, technology solutions and services offered on or through the Websites or Apps, including the free and paid subscription services (“Services”), and the purchase of products or goods (the “Goods”) as well as your choices in connection with such use.  The Websites, Apps and Services are collectively referred to as the “Mise en Place Platform.” Mise en Place Platform.
          <br /> <br />
          INFORMATION THAT WE COLLECT
          <br /> <br />
          Through your use of the Mise en Place Platform we may collect personal information, which is information that identifies you as an individual, relates to you as an identifiable individual, or that, along with other information, could identify you. We collect the following types of personal information:
          <br /> <br />
          Information you provide to us:
          <br /> <br />
          We collect personal information from you when you use the Mise en Place Platform. We do not require you to provide any personal information in order to navigate the Mise en Place Platform. We collect information from you when you:
          <br /> <br />
          Sign up: In order to use the Mise en Place Platform, you would need to provide account information, including contact information, such as your email address, password, your phone number, and dietary preferences. If you sign up for our premium Services we will need your payment information. We use Stripe, a third-party provider, to process credit card information and only retain the last four digits of the credit card. We need this information in order to provide you with the Mise en Place Platform. If you do not provide this information, we would not be able to provide the Mise en Place Platform to you.
          <br /> <br />
          Interact with the Mise en Place Platform: When you participate in a sweepstakes or contest or any other promotion, post a product review, engage with us on our various social media platforms, participate in one of our surveys, or send us any feedback, questions, comments, suggestions, ideas, or interact with us in any way, you provide us with personal information such as your name, email address, social media username and IP address.
          <br /> <br />
          Information provided through feedback, surveys, suggestions, etc., except the specific information that identifies you, is considered non-confidential and non-proprietary. We may use and share such information on an anonymous and aggregated basis with third parties.
          <br /> <br />
          Information collected automatically:
          <br /> <br />
          We may automatically collect the following types of information as you use the Mise en Place Platform:
          <br /><br />
          Usage Information: which pages on the Mise en Place Platform you access, the frequency of access, what you click on while on the Mise en Place Platform and when you accessed the website.
          <br />
          Location Information: information about your location, which may be determined through your IP address, GPS and other sensors that may reveal information on nearby devices, Wi-Fi access points, and cell towers.
          <br /> 
          Device Information: information about the device you are using, such as hardware model, operating system, application version number, browser, and IP addresses.
          <br />
          Mobile Device Information: mobile network information, such as the unique device identifier assigned to that device, mobile carrier, operating system, telephone number, and other device attributes.
          <br />
          Google Analytics:  We use a tool called “Google Analytics” to collect some information we listed above about your use of the Mise en Place Platform. We use the information we get from Google Analytics to improve the Mise en Place Platform.  In order to collect this information, Google Analytics may set cookies on your browser or mobile device or read cookies that are already there.  See “Cookies" and Other Tracking Technologies.” Google Analytics may also receive information about you from apps you have downloaded that partner with Google. We do not combine the information collected through the use of Google Analytics with personally identifiable information. Google’s ability to use and share information collected by Google Analytics about your visits to the Mise en Place Platform to another application which partners with Google is restricted by the Google Analytics Terms of Use and the Google Privacy Policy. Please review those and see http://www.google.com/policies/privacy/partners/ for information about how Google uses the information provided to Google Analytics and how you can control the information provided to Google. To prevent your data from being used by Google Analytics, you can download the Google Analytics opt-out browser add-on for Google Analytics which can be found here https://tools.google.com/dlpage/gaoptout/.
          <br />
          “Cookies” and Other Tracking Technologies
          <br /> <br />
          “Cookies” are small pieces of information that are stored by your web browser software on your computer’s hard drive or temporarily in your computer’s memory, or on your mobile phone or other device when you visit a website. We or our third-party providers place and store Internet cookies on your hard drive or device. Cookies can save any of the types of information noted above. Cookies enable us to personalize your viewing experience. When you revisit the Mise en Place Platform, we can recognize you by the cookie and customize your experience accordingly. For example, we use cookies to recognize your access privileges to the Websites, track Website usage and traffic patterns, identify you and keep track of your preferences, prevent fraudulent activity or improve Website security, assess the performance of the Websites, add advertisements for goods or services of interest, and estimate the Websites’ audience size.
          <br /> <br />
          You can find out more about the cookies we use from each of the categories below.
          <br /><br />
          Category: Strictly Necessary
          <br />
          Purpose: Security
          <br />
          Description: Some cookies help us detect, prevent, and mitigate malicious and fraudulent activities on the Mise en Place Platform. Security cookies also support and enable other security features.
          <br /><br />
          Category: Functional.
          <br />
          Purpose: Analytics and Research
          <br />
          Description: We use some cookies to collect information about how people are using the Mise en Place Platform. For example, these cookies allow us to know which pages are visited the most often, our site’s page load times and page response times. Overall, these cookies provide us with analytical information about how the Mise en Place Platform is performing and how we can improve them.
          <br /> <br />
          Category: Functional <br />
          Purpose: Preferences and Settings<br />
          Description: Your preferences may be stored in our cookies so you do not have to reset them every time you visit the Mise en Place Platform. These cookies allow us to remember the choices you make to tailor this website and provide enhanced features and content to you. For example, these cookies can be used to remember your user name and region information.
          <br /> <br />
          Emails we send contain a bit of code known as a “web beacon.” This code allows us to understand the time and date when you have opened an email. If you wish to disable our web beacons, you should do so by turning images “off” in your email client (e.g., Outlook, Gmail). Please see your email client for more information.
          <br /> <br />
          To learn how you can control information collected about you using cookies and other technologies see Choices about your information.
          <br /> <br />
          HOW WE USE INFORMATION
          <br /> <br />
          We use your personal information to provide the Mise en Place Platform to you, to improve our Mise en Place Platform, and to protect our legal rights. We limit the collection of personal information to that which is relevant for these purposes. We will not store, access, transmit, retain or otherwise use personal information in any way that is incompatible with the purposes for which it has been collected or stored as described in this Notice without your consent. We use the personal information we collect in the following ways:
          <br /> <br />
          We process your information for the following purposes as necessary to provide the Mise en Place Platform to you and perform our contract with you:
          <br /> <br />
          Facilitate your purchase: to facilitate your purchase of premium Services or Goods.<br />
          Communicate with you: to communicate with you about the Mise en Place Platform (including the notifications that are part of the Mise en Place Platform), to send you account updates or other communications regarding your account or to inform you of any changes to the Mise en Place Platform.
          <br />
          Provide Customer Support: to provide you support or other services you request. For example, we may need your information in order to provide technical support or answer questions about the Mise en Place Platform.
          <br />
          We process your information for the following purposes as part of our legitimate interest in the improvement and marketing of the Mise en Place Platform, and in the security of the Mise en Place Platform. We apply appropriate safeguards to protect your information including as described in Security, Choices about your information and here Rights of residents of the European Union (EU):
          <br /> <br />
          Communicate about new features: We send you notifications about new features or information available on the Mise en Place Platform or that we feel might be of interest to you. You can opt-out by emailing us at support@miseenplacemeals.com or clicking the Unsubscribe link in the email notifications you receive.
          <br />
          Promotions and giveaways: We send you emails about special promotions or giveaways that we feel might be of interest to you. You can opt-out by emailing us at
          <br />
          support@miseenplacemeals.com or clicking the Unsubscribe link in the email notifications you receive.
          <br />
          To maintain and improve the Mise en Place Platform: We analyze how our users interact with the Mise en Place Platform in order to maintain and improve the Mise en Place Platform. We also use information that we collect to diagnose any problems with the Mise en Place Platform.
          Benchmarking: We aggregate your information in order to gain insights into the Mise en Place Platform. For example, to calculate the percentage of users in a particular city or country.
          <br />
          To create audience lists: We process and use your email information to create custom audience lists for our targeted marketing purposes on advertising platforms such as Facebook, Instagram, Twitter, Google, Bing and Pinterest, or to create lookalike audiences to target with marketing and advertising materials.
          <br />
          In our processing of your information, we also share your personal information to defend our rights and the rights of others, to efficiently maintain our business, and to comply with the law as described in How We Share Information.
          <br /> <br />
          HOW WE SHARE INFORMATION
          <br /> <br />
          We share your information with our service providers, among our entities, in connection with a corporate restructuring, to prevent harm, to comply with the law, for marketing and advertising purposes, and to protect our legal rights. The legal basis for this is our legitimate interest in providing the Mise en Place Platform, complying with the law, and protecting our rights and those of others. We apply appropriate safeguards for this sharing of your information including as described below and in Security, Choices about your information, and Rights of residents of the European Union (EU).
          <br /> <br />
          Service Providers: We share information with service providers that help us perform Mise en Place Platform functions and process your transactions.
          <br />
          We Use Google Analytics To Conduct Data Analytics On Usage Of The Mise en Place Platform. A Link To The Google Privacy Notice Is Https://Policies.Google.Com/Privacy.
          <br /> <br />
          Corporate Structure: We would share information if we are involved or intend to be involved in a merger, acquisition, consolidation, change of control, or sale of all or a portion of our assets or in connection with bankruptcy or liquidation proceedings.
          <br />
          To Prevent Harm: We may share information if we believe it is necessary in order to detect, investigate, prevent, or take action against illegal activities, fraud, or situations involving potential threats to the rights, property, or personal safety of any person.
          <br />
          To Protect Our Rights and As Required by Law: We will share information where we are legally required to do so, such as in response to court orders or legal process, to exercise our legal rights, to defend against legal claims or demands, or to comply with the requirements of any mandatory applicable law. We will also share information as necessary to enforce the terms of this Notice, the Mise en Place Platform Terms of Use and Service (<a href="terms" style={{ color: 'blue' }}>Terms Page</a>), and any other terms that you have agreed to, including to protect the rights, property, or safety of Mise en Place, its users, or any other person, or the copyright-protected content of the Mise en Place Platform.
          <br />
          Marketing and Advertising of the Mise en Place Platform: We will share your email information with major advertising platform providers such as Facebook, Instagram, Twitter, Google, Bing, and Pinterest, to create custom audiences that may be used by us to engage in targeted marketing. On Facebook, the process works as follows. Other sites are similar. Our customer list is hashed locally on our browser and then sent to Facebook. There, it is matched against Facebook’s existing list of Facebook’s users’ hashed IDs and the matches are added to Mise en Place Custom Audience. Hashing turns the data in Mise en Place's customer list into short fingerprints that can’t be reversed. It happens before the data is sent to Facebook. After the Custom Audience is created by Facebook, the matched and unmatched hashes are deleted.<br />
          With Your Consent: We will request your permission to use or share your personal information for a specific purpose that is not compatible with the purposes listed in the Privacy Policy. We will notify you and request consent before you provide the personal information or before the personal information you have already provided is shared for such purpose. With your consent, we may also share your quotes and reviews on the Mise en Place Platform for promotional purposes. If we rely on your consent to use or share your information, your consent may be revoked at any time by contacting us at support@miseenplacemeals.com.
          <br /> <br />
          SECURITY
          <br /> <br />
          The security of your personal information is important to us. We maintain reasonable physical, technical, and administrative security measures to protect and limit access to your personal information. No method of transmission over the Internet or electronic storage technology, however, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
          <br /> <br />
          CHOICES ABOUT YOUR INFORMATION
          <br /> <br />
          Access, correct or delete your information: You may use your account to access or correct the information you provided to us and which is associated with your account by logging into your account on the Mise en Place Platform at <a href="/login" style={{ color: 'blue' }}>Login Page</a> . You can also request this, as well as request deletion of your information, by writing us at support@miseenplacemeals.com.  Even if you terminate your account, keep in mind that deletion of your personal information by our third-party providers may not be immediate and that deleted personal information may persist in backup copies for a reasonable period of time and as required by applicable laws and regulations. See: How Long Do We Keep Your Information.
          <br /> <br />
          In the event that we refuse a request under rights of access, we will provide the individual with a reason as to why. Individuals residing in the EU also have the rights described in Rights of Residents of the European Union (EU).
          <br /> <br />
          Advertising cookies: To opt out of interest-based advertising or to learn more about the use of this information by our service providers you can visit the Network Advertising Initiative website (http://www.networkadvertising.org/managing/opt_out.asp) or the Digital Advertising Alliance website (http://www.aboutads.info/choices/) , or if you are in the EU, the EDAA (http://www.youronlinechoices.eu/). If you choose to opt out, we will place an “opt-out cookie” on your computer or device. The “opt-out cookie” is browser specific and device specific and only lasts until cookies are cleared from your browser or device. The opt-out cookie will not work for some cookies that are important to how the Mise en Place Platform works (“essential cookies”). If the cookie is removed or deleted, if you upgrade your browser, or if you visit us from a different computer or device, you will need to return to the applicable website to re-select your preferences.
          <br /> <br />
          Cookies: If you would like to opt out of accepting cookies altogether, you can generally set your browser to not accept cookies or to notify you when you are sent a cookie, giving you the chance to decide whether or not to accept it. Please note that certain functions of the Mise en Place Platform may not function properly if your web browser does not accept cookies. If you choose to opt out, we will place an “opt-out cookie” on your computer or device. The “opt-out cookie” is browser specific and device specific and only lasts until cookies are cleared from your browser or device. The opt-out cookie will not work for some cookies that are important to how the Mise en Place Platform works (“essential cookies”). If the cookie is removed or deleted, if you upgrade your browser, or if you visit us from a different computer, you will need to return and update your preferences.
          <br /> <br />
          Google Analytics:  See http://www.google.com/policies/privacy/partners/ for information about how Google uses the information provided to Google Analytics and how you can control the information provided to Google. To prevent your data from being used by Google Analytics, you can download the Google Analytics opt-out browser add-on for Google Analytics which can be found here: https://tools.google.com/dlpage/gaoptout
          <br /> <br />
          Promotional emails: To unsubscribe from promotional emails please click the unsubscribe link included in the footer of all of promotional emails or log in to update your Notification Settings. By unsubscribing, you will be removed from our promotional email list and you will no longer receive any communications from us, including emails pertaining to the Mise en Place Platform.
          <br /> <br />
          Email tracking: To disable our web beacons you may turn images “off” in your email client (e.g., Outlook, Gmail).
          <br /> <br />
          Advertising platforms: To edit your information and ad preferences on major marketing platforms, you will need to visit each platform. The following is a list of locations where this can be done:
          <br /> <br />
          Facebook/Instagram:<br />
         https://www.facebook.com/help/247395082112892<br />
   https://www.facebook.com/ads/about/<br />
          https://www.facebook.com/ads/preferences/<br />
          https://help.instagram.com/1415228085373580<br />
          <br />
          Twitter:
          https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads<br />
         https://twitter.com/settings/your_twitter_data<br />
          https://twitter.com/settings/personalization<br />
          <br />
          Pinterest:<br />
          https://help.pinterest.com/en/article/personalization-and-data<br />
      https://help.pinterest.com/en/article/personalized-ads-on-pinterest<br />
         https://help.pinterest.com/en/article/edit-personalization-settings<br />
          <br />
          Google:<br />
          https://adssettings.google.com<br />
          https://support.google.com/ads/answer/2662922<br />
          <br />
          Bing:<br />
         https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads<br />
          RIGHTS OF RESIDENTS OF THE EUROPEAN UNION (EU)<br />
          <br />
          If you use the Mise en Place Platform and reside in the EU, you are entitled by law to access, correct, amend, or delete personal information about you that we hold. A list of these rights is below. Please note that these rights are not absolute and certain exemptions apply. To exercise these rights please contact us at support@miseenplacemeals.com.  For your protection, we may need to verify your identity before responding to your request, such as verifying that the email address from which you send the request matches your email address that we have on file. In the event that we refuse a request under rights of access, we will provide you a reason as to why.
          <br /> <br />
          The right to access: You have the right to ask us for copies of your personal information. This right has some exemptions, which means you may not always receive all the information we process. When making a request, please provide an accurate description of the data you want access to.
          <br />
          The right to rectification: You have the right to ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.
          The right to erasure: You have the right to ask us to erase your personal information in certain circumstances, including (i) when your personal information is no longer necessary for the purposes for which it was collected or processed or (ii) your information must be erased to comply with a legal obligation in EU Union or Member State law.
          <br />
          The right to restrict processing: You have the right to ask us to restrict the processing of your information in certain circumstances, including (i) when the accuracy of the information is brought into question or (ii) when we no longer need the information for purposes of the processing but you require such information for the establishment, exercise, or defense of a legal claim. See “choices about your information” for some ways you can restrict processing.
          <br />
          The right to object to processing: You have the right to object to processing if we are able to process your information because the process is in our legitimate interests. See “choices about your information” for some ways you can object to processing.
          <br />
          The right to data portability: This only applies to information you have given us. You have the right to ask that we transfer the information you gave us from one organization to another, or give it to you.
          <br />
          The right to lodge a complaint with the supervisory authority. A list of Supervisory Authorities is available here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
          <br />  <br />
          HOW LONG DO WE KEEP YOUR INFORMATION
          <br />  <br />
          We will retain your personal information until the later of (i) the information is no longer necessary to accomplish the purpose for which it was provided, (ii) there is no ongoing business reason to keep the information, or (iii) until you request that your account be deleted.
          <br />  <br />
          We retain your information for longer periods for specific purposes to the extent that we are obliged to do so in accordance with applicable laws and regulations and/or as necessary to protect our legal rights or for certain business requirements. For example, when we process your payments, we will retain this data for longer periods of time as required for tax or accounting purposes. More specifically, we would retain some data for longer periods of time for the following reasons:
          <br />  <br />
          Security, fraud & abuse prevention: i.e. To protect you, other people, and us from fraud, abuse, and unauthorized access.
          <br />
          Financial record-keeping: When you make a payment to us we are often required to retain this information for long period of time for purposes of accounting, dispute resolution and compliance with tax, anti-money laundering, and other financial regulations.
          <br />
          Complying with legal or regulatory requirements: To meet any applicable law, regulation, legal process or enforceable governmental request, as required to enforce our terms of service, including investigation of potential violations.
          <br />
          Direct communications with us: If you have directly communicated with us, through a customer support channel, feedback form, or a bug report, we may retain reasonable records of those communications.
          <br />
          Even if you delete your account, keep in mind that the deletion by our third-party providers may not be immediate and that the deleted information may persist in backup copies for a reasonable period of time. For any privacy or data-protection-related questions, please write to support@miseenplacemeals.com.
          <br />  <br />
          RIGHTS OF RESIDENTS OF CALIFORNIA
          <br />
          If you are a visitor or user of the Mise en Place Platform and reside in the State of California, you are entitled in accordance with the California Consumer Privacy Act (or “CCPA”) to access or delete personal information about you that we hold.
          <br />  <br />
          In the last 12 months, we collected the following categories of personal information: identifiers (such as name, email address, IP Address), internet or other electronic network activity information (such as activity on the Websites), commercial information (such as airport preference), and geolocation data. For more details about the personal information we collect as well as where we share this information with third parties, please see the “INFORMATION THAT WE COLLECT," "HOW WE USE INFORMATION," and "HOW WE SHARE INFORMATION" sections above. We collect this information for the business and commercial purposes described in the “HOW WE USE INFORMATION” section above.
          <br />  <br />
          We do not sell the personal information we collect and will not sell it in the future without providing a right to opt out.
          <br />  <br />
          You may request to exercise your access and deletion rights by emailing us at support@miseenplace.com.  For your protection, we will need to verify your identity before responding to your request. We may do so by verifying that the email address from which you send the request matches your email address on file. Mise en Place will endeavor to respond to your request within forty-five (45) days. We cannot process your request if you do not provide us with sufficient detail to allow us to understand and respond to it. Only you, or someone legally authorized to act on your behalf may make a verifiable consumer request related to your personal information.
          <br />  <br />
          CHILDREN’S PRIVACY
          <br />  <br />
          The Mise en Place Platform is not intended for use by children. We do not knowingly collect personal information from children under the age of 16 years. If we become aware that a child under 16 has provided us with personal information, we take steps to delete such personal information without undue delay. California users under the age of 18 may request the removal of their content or information publicly posted on the website by emailing us at the appropriate email address identified below.
          <br />  <br />
          UPDATES TO THIS NOTICE
          <br />  <br />
          We may update or modify this Privacy Policy and Notice from time to time. We will post the changes to this page and will indicate the date they go into effect. We encourage you to review our Privacy Policy Notice on a regular basis to stay informed.  If we make changes that materially affect your privacy rights we will notify you of the changes by posting a prominent notice on our Websites or using other methods that we select, such as sending you an email.
          <br />  <br />
          “DO NOT TRACK”
          <br />  <br />
          The Mise en Place Platform is not currently configured to respond to Do Not Track signals sent by Internet browsers.
          <br />  <br />
          CONTACT US
          <br />  <br />
          Any questions or concerns about the interpretation or operation of this Privacy Policy and Notice or about what may or may not be done with regard to your information should be directed to:
          <br />  <br />
          Mise en Place
          <br />
          490 Walnut Dr,
          <br />
          Northampton, PA 18067
          <br />
        support@miseenplace.com
        </Typography>
      </div>
    </div>
  );
};
export default Privacy;
