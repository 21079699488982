/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonUI from "src/components/buttonUi";
import DaysModal from "src/components/daysModal";
import FavoriteModal from "src/components/favoriteModal";
import PaymentCard from "src/components/payment/home";
import SubcriptionModal from "src/components/subscriptionModal";
import APIS from "src/config/api";
import { GET, POST } from "src/config/https";
import Color from "src/theme/color";
import FontStyles from "src/theme/font";
import CheckSubscription from "src/utils/checkSubscription";
import getUserData from "src/utils/getLocalStorageData";

import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { usePersistentState } from "src/contexts/GlobalContext";
import { cuurentWeeKFirstDateAndDay } from "src/utils/dateMethod";
import CardUiTest from "../demo";

const AllPasteRecipes = () => {
  const [addFavoriteModal, setAddFavoriteModal] = useState(false);
  const [daysModal, setDaysModal] = useState(false);
  const [allRecipe, setAllRecipe] = useState<any>([]);
  const [searchRecipeData, setSearchRecipeData] = useState<any>([]);
  const [state, dispatch]: any = usePersistentState();
  const [page, setPage] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isNotSearching, setIsNotSearching] = useState(false);
  const [noLoadMore, setNoLoadMore] = useState(true);
  const [recipeDetailPageId, setRecipeDetailPageid] = useState("");
  const [recipeName, setRecipeName] = useState("");
  const [recipeCategory, setRecipeCategory] = useState("");
  const [paymentStatus, setPaymentStuts] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [categories, setCategories] = useState({
    IsLunch: false,
    IsDinner: false,
    IsBreakfast: false,
  });
  const [subcriptionModal, setSubcriptiomModal] = useState(false);
  const navigate = useNavigate();
  const location: any = useLocation();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));
  useEffect(() => {
    dispatch({ isSearch: true });
    categoryValue();
    let temp = location.state?.recipesPage;
    let titleName = location.state?.title;
    if (!temp) {
      navigate("/dashboard/pastrecipes", {
        state: {
          recipesPage: true,
        },
      });
    }
    setRecipeCategory(titleName);
  }, []);

  useEffect(() => {
    if (state.search.trim().length === 0) {
      const tempPage = 0;
      setPage(tempPage);
      setNoLoadMore(true);
      setAllRecipe([]);
      setIsNotSearching(true);
    }
    if (state.search.trim().length > 0) {
      const tempPage = 0;
      setPage(tempPage);
      setNoLoadMore(true);
      setSearchRecipeData([]);
      setIsSearching(true);
    }
  }, [state.search]);

  useEffect(() => {
    if (searchRecipeData.length === 0 && isSearching) {
      const tempPage = 0;
      fetchMoreRecipeData(tempPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRecipeData, isSearching]);

  useEffect(() => {
    if (allRecipe.length === 0 && isNotSearching) {
      const tempPage = 0;
      fetchRecipeData(tempPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRecipe, isNotSearching]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 125) {
      if (!loadMore && noLoadMore) {
        const tempPage = page + 1;
        setPage(tempPage);
        // setLoadMore(true);
        fetchMoreRecipeData(tempPage);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const fetchRecipeData = async (pageNo: number) => {
    let userData = getUserData();
    if (!userData) return;
    let date = cuurentWeeKFirstDateAndDay();
    setIsLoading(true);
    setLoadMore(true);
    let params: any = {
      Date: date,
      IsBreakfast: categories.IsBreakfast,
      IsLunch: categories.IsLunch,
      IsDinner: categories.IsDinner,
      PageNumber: pageNo.toString(),
      PageSize: "18",
      // SearchProperty: "Name",
      SearchValue: state.search.toString(),

      // SearchOption: "contains",
    };

    await GET(APIS.PAST_RECIPES, params, userData.jwtToken)
      .then((res: any) => {
        setLoadMore(false);
        if (res.isSuccess) {
          let newData = [];
          if (categories.IsBreakfast) {
            newData = res.result.breakfast;
          }
          if (categories.IsLunch) {
            newData = res.result.lunch;
          }
          if (categories.IsDinner) {
            newData = res.result.dinner;
          }
          setAllRecipe(newData);
        }
      })
      .catch(() => {
        setLoadMore(false);
      })
      .finally(() => {
        setLoadMore(false);
        setIsSearching(false);
        setIsNotSearching(false);
        setIsLoading(false);
      });
  };

  const fetchMoreRecipeData = async (pageNo: number) => {
    let userData = getUserData();
    if (!userData) return;
    setLoadMore(true);
    let date = cuurentWeeKFirstDateAndDay();
    let params: any = {
      Date: date,
      IsBreakfast: categories.IsBreakfast,
      IsLunch: categories.IsLunch,
      IsDinner: categories.IsDinner,
      PageNumber: pageNo.toString(),
      PageSize: "18",
      // SearchProperty: "Name",
      SearchValue: state.search.toString(),
      // SearchOption: "contains",
    };

    await GET(APIS.PAST_RECIPES, params, userData.jwtToken)
      .then((res: any) => {
        setIsSearching(false);
        setLoadMore(false);
        if (res.isSuccess) {
          let newData = [];
          if (categories.IsBreakfast) {
            newData = res.result.breakfast;
          }
          if (categories.IsLunch) {
            newData = res.result.lunch;
          }
          if (categories.IsDinner) {
            newData = res.result.dinner;
          }
          if (newData.length === 0) {
            setNoLoadMore(false);
          }
          if (state.search.trim().length > 0) {
            setSearchRecipeData([]);
            const newRecipeData = [...searchRecipeData, ...newData];
            const unique = _.uniqBy(newRecipeData, "id");
            setSearchRecipeData(unique);
            setAllRecipe([]);
          } else {
            const newRecipeData = [...allRecipe, ...newData];
            const unique = _.uniqBy(newRecipeData, "id");
            setAllRecipe(unique);
            setSearchRecipeData([]);
          }
        }
      })
      .catch(() => {
        setLoadMore(false);
      })
      .finally(() => {
        setLoadMore(false);
        setIsSearching(false);
        setIsNotSearching(false);
      });
  };

  const addFavoriteRecipe = async () => {
    let userData = getUserData();
    if (!userData) return;

    let params = {
      id: recipeDetailPageId,
      isFavorite: true,
    };

    await POST(APIS.ADD_FAVORITE_RECIPE, params, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          toast.success("Successfully add in favorite list");
          setAddFavoriteModal(false);
        }
        if (!res.isSuccess && res.errors) {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setAddFavoriteModal(false);
        setRecipeDetailPageid("");
      });
  };

  const setSubcriptionStuts = async () => {
    let temp = await CheckSubscription();
    setPaymentStuts(temp?.isSubscriptionRequired);
  };
  const handaleSubcription = () => {
    navigate("/dashboard/subscriptionplan");
    // setSubcriptiomModal(false);
    // setPaymentModal(true);
  };

  const categoryValue = () => {
    let titleName = location.state?.title;
    let temp = { ...categories };
    if (titleName === "DINNER") {
      temp.IsDinner = true;
    }
    if (titleName === "LUNCH") {
      temp.IsLunch = true;
    }
    if (titleName === "BREAKFAST") {
      temp.IsBreakfast = true;
    }
    setCategories(temp);
  };

  // const recipeData = (category: string) => {
  //   let newData: any = [];
  //   if (searchRecipeData?.dinner && searchRecipeData?.dinner.length > 0) {
  //     newData = searchRecipeData.dinner;
  //   }
  //   if (location.state?.title === "BREAKFAST") {
  //     newData = allRecipe.breakfast;
  //   }
  //   if (location.state?.title === "DINNER") {
  //     newData = allRecipe.dinner;
  //   }
  //   if (location.state?.title === "LUNCH") {
  //     newData = allRecipe.IsLunch;
  //   }
  //   return newData;
  // };

  return (
    <Container
      component="main"
      sx={{
        marginTop: "20px",
        width: "100%",
        color: Color.color10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Button sx={{ width: "205px" }}> */}{" "}
        <Box style={{ width: isMatch ? "50px" : "205px" }}>
          <div onClick={() => navigate("/dashboard/pastrecipes")}>
            <KeyboardBackspaceOutlinedIcon style={{ cursor: "pointer" }} />
          </div>
        </Box>
        {/* </Button> */}
        <div
          onClick={() => {
            navigate("/dashboard/pastrecipes");
          }}
        >
          <Typography
            sx={{
              ...FontStyles.font40Prociono,
              fontSize: isMatch ? "24px" : "40px",
              fontFamily: "Playfair Display SC",
              color: Color.color10,
              cursor: "pointer",
            }}
          >
            Past Recipes
          </Typography>
        </div>
        {isMatch ? (
          <Box
            sx={{ backgroundColor: "red" }}
            style={{ width: isMatch ? "50px" : "205px" }}
          ></Box>
        ) : (
          <ButtonUI
            variant={"outlined"}
            label={"THIS WEEKS RECIPES"}
            style={{
              backgroundColor: Color.color1,
              borderColor: Color.color1,
              width: isMatch ? "100px" : "205px",
            }}
            lableStyle={{
              ...FontStyles.font16Prociono,
              fontSize: isMatch ? "10px" : "16px",
              color: Color.white,
            }}
            action={() => {
              navigate("/dashboard/myrecipes");
            }}
          />
        )}
      </div>
      <Box
        sx={{
          border: 1,
          borderColor: Color.color17,
          height: 1,
          marginTop: 1,
          marginBottom: isMatch ? 1 : 0,
        }}
      ></Box>
      {isMatch ? (
        <ButtonUI
          variant={"outlined"}
          label={"THIS WEEKS RECIPES"}
          style={{
            backgroundColor: Color.color1,
            borderColor: Color.color1,
            width: isMatch ? "150px" : "205px",
          }}
          lableStyle={{
            ...FontStyles.font16Prociono,
            fontSize: isMatch ? "10px" : "16px",
            color: Color.white,
          }}
          action={() => {
            navigate("/dashboard/myrecipes");
          }}
        />
      ) : null}
      <Box
        sx={{
          border: 1,
          borderColor: Color.color17,
          height: 1,
          marginTop: isMatch ? 1 : 3,
          marginBottom: 1,
        }}
      ></Box>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box style={{ width: isMatch ? "50px" : "205px" }}></Box>
        <Typography
          sx={{
            ...FontStyles.font36Prociono,
            fontSize: isMatch ? "18px" : "36px",
            fontFamily: "Playfair Display SC",
            color: Color.color10,
          }}
        >
          {`Past ${recipeCategory}`}
        </Typography>
        <Box style={{ width: isMatch ? "50px" : "205px" }}></Box>
      </div>
      <Box
        sx={{
          border: 1,
          borderColor: Color.color17,
          height: 1,
          marginTop: 1,
        }}
      ></Box>
      <div>
        {/* {recipeCategory && (
          <PastRecpiesCard
            cardData={[]}
            categories={recipeCategory}
            handleAdd={(id) => {
              setRecipeDetailPageid(id);
              if (!paymentStatus) {
                setDaysModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            handleFavourite={(id, recipeTitle) => {
              setRecipeDetailPageid(id ? id : "");
              setRecipeName(recipeTitle ? recipeTitle : "");
              if (!paymentStatus) {
                setAddFavoriteModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
          />
        )} */}
        {allRecipe ? (
          <CardUiTest
            headerAlignTop={true}
            cardData={
              state.search.trim().length > 0 ? searchRecipeData : allRecipe
            }
            handleAdd={(id) => {
              setRecipeDetailPageid(id);
              if (!paymentStatus) {
                setDaysModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            handleFavourite={(id, titleRecipe) => {
              setRecipeDetailPageid(id ? id : "");
              setRecipeName(titleRecipe ? titleRecipe : "");
              if (!paymentStatus) {
                setAddFavoriteModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            recipeDetail={(id) => {
              setRecipeDetailPageid(id ? id : "");
              navigate("/dashboard/recipedetail", {
                state: {
                  recipePage: true,
                  recipeId: id,
                },
              });
            }}
            isloading={loadMore}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "red",
              height: "159px",
            }}
          >
            <Typography
              sx={{ ...FontStyles.font20Prociono }}
              textAlign={"center"}
            >
              No Record
            </Typography>
          </div>
        )}
      </div>
      {loadMore && (
        <Box
          sx={{ height: "100px" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress style={{ color: Color.color15 }} />
        </Box>
      )}
      <FavoriteModal
        open={addFavoriteModal}
        onClickAddFavorite={() => addFavoriteRecipe()}
        onClickRemove={() => {
          setAddFavoriteModal(false);
          setRecipeDetailPageid("");
        }}
        onCloseHandle={() => setAddFavoriteModal(false)}
        titleRecipeName={recipeName}
      />
      <DaysModal
        open={daysModal}
        onClose={(close) => {
          setDaysModal(false);
          if (close) {
            // fetchRecipeInDetail();
          }
        }}
        data={recipeDetailPageId}
        pageTitle="recipes"
      />
      <SubcriptionModal
        open={subcriptionModal}
        onCloseHandle={() => setSubcriptiomModal(false)}
        upgradeNowAction={handaleSubcription}
      />
      <PaymentCard
        open={paymentModal}
        handleClose={() => {
          setPaymentModal(false);
          setSubcriptionStuts();
        }}
      />
      {/* <FullScreenLoadder open={isLoading} /> */}
    </Container>
  );
};
export default AllPasteRecipes;
