import { MenuItem, OutlinedInputProps, TextField, Typography } from "@mui/material";
import { Box } from "@mui/material";
import Color from "../theme/color";
import iconTypeCheck from "./displayIcon";
import { useState } from "react";
import { countryList } from "src/utils/countrtData";

const TextBoxDropDown = ({
  id,
  name,
  label,
  required,
  fullWidth,
  styles,
  icon,
  placeholder,
  onChange,
  type = "text",
  extraLableStyle,
  value,
  passwordVisibaleData,
  select = false,
  iconOnClick,
}: TextFiledUiProps) => {
  let lableStyle = { ...Styles.lableStyle, ...extraLableStyle };

  const [passwordVisible, setPasswordVisibale] = useState(true);

  return (
    <>
      <Typography
        textAlign={"start"}
        alignItems={"flex-start"}
        style={lableStyle}
      >
        {label}
      </Typography>
      <Box
        sx={{
          boxShadow: 2,
          width: "100%",
          borderRadius: 2,
          borderColor: Color.color11,
          height: 40,
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "center",
          mt: "4px",
        }}
        display="inline-flex"
        justifyContent="center"
        paddingLeft={1}
      >
        {iconTypeCheck(icon)}
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
          display="inline-flex"
          justifyContent="center"
          paddingTop={2}
          paddingLeft={0.5}
        >
          <TextField
            size="small"
            variant="standard"
            select={select}
            placeholder={placeholder}
            required={required}
            fullWidth={fullWidth}
            type={passwordVisibaleData && passwordVisible ? "password" : type}
            value={value}
            onChange={onChange}
            id={id}
            name={name}
            style={styles ? styles : Styles.fontFamilyStyles}
            InputProps={{
              disableUnderline: true,
              style: { fontSize: 14 },

            }}
          >        {countryList.map((option: any, index) => (
            <MenuItem key={index} value={option} >
              {option}
            </MenuItem>
          ))}</TextField>
        </Box>

        {passwordVisibaleData ? (
          <Typography
            sx={{ marginRight: 1 }}
            onClick={() => setPasswordVisibale(!passwordVisible)}
          >
            {iconTypeCheck(passwordVisible ? "PasswordHide" : "PasswordShow")}
          </Typography>
        ) : null}
      </Box>
    </>
  );
};
export default TextBoxDropDown;

interface TextFiledUiProps {
  id?: string;
  name?: string;
  label?: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
  margin?: "dense" | "normal" | "none";
  required?: boolean;
  fullWidth?: boolean;
  icon?: any;
  placeholder?: string;
  styles?: React.CSSProperties;
  onChange?: OutlinedInputProps["onChange"];
  extraLableStyle?: any;
  passwordVisibaleData?: boolean;
  iconOnClick?: () => void;
  value?: unknown;
  select?: boolean
}

const Styles = {
  fontFamilyStyles: {
    fontFamily: "Prociono",
    padding: 0,
    margin: 0,
    paddingLeft: 0,
    marginLeft: 0,
  },
  lableStyle: {
    fontFamily: "Prociono",
    fontSize: 14,
    fontWeight: 400,
    width: "100%",
    marginTop: "16px",
    color: Color.black,
  },
};
