import APIS from "src/config/api";
import getUserData from "./getLocalStorageData";
import { GET } from "src/config/https";

const CheckSubscription = async () => {
  let userData = getUserData();
  if (!userData) return;
  let params = {};
  let status = await GET(APIS.CHECK_SUBCRIPTION, params, userData.jwtToken)
    .then((res: any) => {
      if (res.isSuccess) {
        return res.result;
      }
    })
    .catch((error) => {
    });

  return status;
};
export default CheckSubscription;
