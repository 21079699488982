import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Color from "../theme/color";

const IconTypeCheck = (icon: string) => {
  switch (icon) {
    case "Email":
      return (
        <MailOutlineIcon
          fontSize={"small"}
          style={{
            color: Color.color4,
          }}
        />
      );
      
    case "LockIcon":
      return (
        <LockOutlinedIcon
          fontSize={"small"}
          style={{
            color: Color.color4,
          }}
        />
      );
    case "Smile":
      return (
        <SentimentSatisfiedAltIcon
          fontSize={"small"}
          style={{
            color: Color.color4,
          }}
        />
      );
    case "Phone":
      return (
        <PhoneIphoneIcon
          fontSize={"small"}
          style={{
            color: Color.color4,
          }}
        />
      );
      case "PasswordShow":
      return (
        <VisibilityOffOutlinedIcon
          fontSize={"small"}
          style={{
            color: Color.color4,
          }}
        />
      );
      case "PasswordHide":
        return (
          <VisibilityOutlinedIcon
            fontSize={"small"}
            style={{
              color: Color.color4,
            }}
          />
        );
    default:
      return null;
  }
};

export default IconTypeCheck;
