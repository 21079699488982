
import {
    Box,
    Modal,
    Typography,
    useMediaQuery,
    useTheme,
    Grid
} from "@mui/material";
import Color from "src/theme/color";
import FontStyles from "src/theme/font";
import IsPhone from "src/utils/phoneDesktopSize";
import ButtonUI from "../buttonUi";
import imageCheck from "../../aasets/images/regularcheck-circle (1).png";

type cartDataProps = {
    canOrder: boolean
    products: any[]
}

interface GroceryOrderConfirmationProps {
    handleClose: () => void
    open: boolean
    cartData: cartDataProps
    onPressOrder: (orderEverything: boolean) => void
    isLoadingOrderEveything: boolean
    isLoadingOrderRemaining: boolean
}

const firstCharUperCase = (str: string) => {
    const data = str.charAt(0).toUpperCase() + str.slice(1);
    return data;
};

function GroceryOrderConfirmation({ handleClose, open, cartData, onPressOrder, isLoadingOrderEveything, isLoadingOrderRemaining }: GroceryOrderConfirmationProps): JSX.Element {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

    const isPhone = IsPhone();

    const isExistproducts = cartData?.products?.length

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isExistproducts ? '60%' : '50%',
        bgcolor: "background.paper",
        border: "2px solid white",
        boxShadow: 24,
        p: 2,
        height: 'auto',
    };

    const ScrollStyle = {
        width: '100%',
        height: 'auto',
        maxHeight: isPhone ? 300 : 500,
        marginTop: 2
    };

    const RenderButton = () => {
        if (isExistproducts) {
            return (
                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 12, sm: 12, md: 12, }}
                    sx={{
                        justifyContent: 'center',
                        marginTop: 0.1,
                    }}
                >

                    <Grid container item
                        sm={6}
                        md={12 / 3}
                        // xs={12}
                        sx={{}}
                    >
                        <ButtonUI
                            style={{
                                backgroundColor: Color.color1,
                                borderColor: Color.color1,
                                width: "100%",
                            }}
                            lableStyle={{
                                ...FontStyles.font16Prociono,
                                color: Color.white,
                            }}
                            variant="outlined"
                            label="Order Everything"
                            action={() => {
                                onPressOrder(true);
                            }}
                            isLoading={isLoadingOrderEveything}
                        />
                    </Grid>
                    <Grid container item
                        sm={6}
                        md={12 / 3}
                    // xs={12}
                    >
                        <ButtonUI
                            style={{
                                backgroundColor: Color.color7,
                                borderColor: Color.color7,
                                width: "100%",
                            }}
                            lableStyle={{
                                ...FontStyles.font16Prociono,
                                color: Color.white,
                            }}
                            variant="outlined"
                            label="Order Remaining Items"
                            action={() => {
                                onPressOrder(false);
                            }}
                            isLoading={isLoadingOrderRemaining}
                        />
                    </Grid>
                    <Grid container item
                        sm={6}
                        md={12 / 3}
                    //  xs={12}
                    >
                        <ButtonUI
                            style={{
                                backgroundColor: Color.color6,
                                borderColor: Color.color6,
                                width: "100%",
                            }}
                            lableStyle={{
                                ...FontStyles.font16Prociono,
                                color: Color.white,
                            }}
                            variant="outlined"
                            label="Cancel"
                            action={() => {
                                handleClose();
                            }}
                        />
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: 50,
                        marginBottom: 10
                    }}
                >
                    <ButtonUI
                        style={{
                            backgroundColor: Color.color1,
                            borderColor: Color.color1,
                            width: "40%",
                        }}
                        lableStyle={{
                            ...FontStyles.font20Prociono,
                            color: Color.white,
                        }}
                        variant="outlined"
                        label="Yes"
                        action={() => {
                            onPressOrder(true);
                        }}
                        isLoading={isLoadingOrderEveything}
                    />
                    <ButtonUI
                        style={{
                            backgroundColor: Color.color6,
                            borderColor: Color.color6,
                            width: "40%",
                        }}
                        lableStyle={{
                            ...FontStyles.font20Prociono,
                            color: Color.white,
                        }}
                        variant="outlined"
                        label="Cancel"
                        action={() => {
                            handleClose();
                        }}
                        disabled={isLoadingOrderEveything}
                    />
                </div>
            )
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => { }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={style}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                        textAlign={"center"}
                        sx={{
                            ...FontStyles.font20Prociono,
                            marginTop: 2
                        }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        {isExistproducts === 0 ? `It looks like these items were already purchased, would you like to reorder?` : "Would you like to reorder everything or just the remaining items?"}
                    </Typography>
                </div>

                {isExistproducts > 0 ?
                    <Box
                        sx={ScrollStyle}
                        style={{
                            overflow: "hidden",
                            overflowY: 'scroll'
                        }}>
                        <Grid
                            container
                            spacing={2}
                            columns={{ xs: 4, sm: 4, md: 12 }}
                        >
                            {cartData?.products.map((item) => {
                                return (
                                    <Grid container item sm={6} md={12 / 5} xs={6}>
                                        <Grid xs={3}>
                                            <Typography
                                                alignItems={"center"}
                                                display={"flex"}
                                                justifyContent={"center"}
                                                sx={{ marginTop: "2px" }}
                                            >
                                                <img
                                                    src={imageCheck}
                                                    style={{ width: "20px", height: "20px" }}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid xs={9}>
                                            <Grid xs={12}>
                                                <Typography
                                                    sx={{
                                                        ...FontStyles.font16Prociono,
                                                        color: Color.color4,
                                                        paddingLeft: isMatch ? 0.5 : 0,
                                                    }}
                                                >
                                                    {firstCharUperCase(item.name.split(",")[0])}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12}>
                                                <Typography
                                                    sx={{
                                                        ...FontStyles.font16Prociono,
                                                        color: Color.color16,
                                                        paddingLeft: isMatch ? 0.5 : 0,
                                                    }}
                                                >
                                                    {`${item.toBeOrderedQuantity} ${item.unit}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    : null}
                {RenderButton()}
            </Box>
        </Modal>
    );
}

export default GroceryOrderConfirmation;
