import { Box, Grid, Typography, Link } from "@mui/material";

import Styles from "./styles";
import IsPhone from "../../../utils/phoneDesktopSize";
import Color from "../../../theme/color";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const navigateMenu = (key: string) => {
    if (key === "All Recipes") {
      navigate("/dashboard/allrecipes");
    }
    if (key === "My Recipes") {
      navigate("/dashboard/myrecipes");
    }
    if (key === "Grocery List") {
      navigate("/dashboard/grocery");
    }
    if (key === "Favorites") {
      navigate("/dashboard/favorite");
    }
    if (key === "Contact Us") {
      navigate("/dashboard/contactus");
    }
    if (key === "Terms") {
      navigate("/terms");
    }
    if (key === "Privacy") {
      navigate("/privacy");
    }
  };
  return (
    <>
      <Box sx={Styles.continer}>
        <Grid container direction="column" alignItems="center" padding={5}>
          <Grid item xs={12}>
            <Typography color="black" variant="h4" style={Styles.headerTxt}>
              Mise en Place
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <Grid
              container
              spacing={{ xs: 1, md: 1 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              {categoryList.map((x, index) => {
                return (
                  <Grid item key={index}>
                    <Link
                      // href="#"
                      variant="body2"
                      style={Styles.categortItem}
                      marginLeft={1}
                      onClick={() => navigateMenu(x.name)}
                      sx={{ cursor: "pointer" }}
                    >
                      {x.name}
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "white",
              width: "100%",
              marginTop: 3,
              marginBottom: 2,
            }}
          >
            <Box sx={{ backgroundColor: Color.color14, height: 2 }}></Box>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography style={Styles.copyRight}>
                © 2023 Mise en Place. All rights reserved.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              textAlign={IsPhone() ? "left" : "right"}
              sx={{ marginTop: IsPhone() ? 1 : undefined }}
            >
              {list.map((x, index) => {
                return (
                  <Link
                    key={index}
                    variant="body2"
                    style={Styles.copyRight}
                    marginLeft={index === 0 ? undefined : 1}
                    onClick={() => navigateMenu(x.name)}
                    sx={{ cursor: "pointer" }}
                  >
                    {x.name}
                  </Link>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Footer;

const categoryList = [
  { id: 0, name: "All Recipes" },
  { id: 1, name: "My Recipes" },
  { id: 2, name: "Grocery List" },
  { id: 3, name: "Favorites" },
  { id: 4, name: "Contact Us" },
];

const list = [
  { id: 1, name: "Terms" },
  { id: 2, name: "Privacy" },
  // { id: 3, name: "Cookies" },
];
