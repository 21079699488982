/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _, { add } from "lodash";

import FontStyles from "../theme/font";
import Color from "../theme/color";

import recipeBackground from "../aasets/images/recipeBackground.png";
import UnderLine from "../components/underLine";
import HederPageWithCard from "../components/homePageCardWithHeder";

import { GET, POST } from "../config/https";
import APIS from "../config/api";
import { usePersistentState } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import LoginModal from "../components/login";
import DaysModal from "../components/daysModal";
import getUserData from "../utils/getLocalStorageData";
import { toast } from "react-hot-toast";
import FavoriteModal from "../components/favoriteModal";
import SubcriptionModal from "../components/subscriptionModal";
import { addDateDays } from "../utils/dateMethod";
import CheckSubscription from "src/utils/checkSubscription";
import PaymentCard from "src/components/payment/home";
import CardUiTest from "./demo";

const cardSize = {
  xs: 6,
  sm: 4,
  md: 2,
};
const gridSize = {
  xs: 4,
  sm: 8,
  md: 12,
};

const DashboardAppPage = () => {
  const [openDayModal, setOpenDayModal] = useState(false);
  const [openFavouriteModal, setOpenFavouriteModal] = useState(false);
  const handleCloseFavourite = () => {
    setOpenFavouriteModal(false);
    setRecipeDetailPageid("");
  };
  const [allRecipe, setAllRecipe] = useState([]);
  const [searchRecipeData, setSearchRecipeData] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadder, setLoader] = useState(false);

  const [noLoadMore, setNoLoadMore] = useState(true);

  const [recipeName, setRecipeName] = useState("");

  const [paymentModal, setPaymentModal] = useState(false);

  const theme = useTheme();

  const [openModalLogin, setOpenModalLogin] = useState({
    loginModalData: false,
    isDeatil: false,
    isAdd: false,
    isFavorite: false,
  });

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch]: any = usePersistentState();

  const [recipeDetailPageId, setRecipeDetailPageid] = useState("");

  const [page, setPage] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isNotSearching, setIsNotSearching] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [paymentStatus, setPaymentStuts] = useState(false);

  const [subcriptionModal, setSubcriptiomModal] = useState(false);

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();

  const diff = currentDayOfWeek > 0 ? currentDayOfWeek - 1 : 6;

  let firstDayOfCurrentWeek = new Date(currentDate);
  firstDayOfCurrentWeek.setDate(currentDate.getDate() - diff);

  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  useEffect(() => {
    setSubcriptionStuts();
    dispatch({ isSearch: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch({ isLogin: false });
    let tempUserData = getUserData();
    if (tempUserData) {
      let data = addDateDays(14);
      if (data === true) {
        // setSubcriptiomModal(true);
      }
    }
  }, []);

  useEffect(() => {
    if (state.isLogin) {
      setOpenModalLogin({
        loginModalData: true,
        isDeatil: false,
        isAdd: false,
        isFavorite: false,
      });
    }
  }, [state.isLogin]);

  useEffect(() => {
    if (state.search.trim().length === 0) {
      const tempPage = 0;
      setPage(tempPage);
      setNoLoadMore(true);
      setAllRecipe([]);
      setIsNotSearching(true);
    }
    if (state.search.trim().length > 0) {
      const tempPage = 0;
      setPage(tempPage);
      setNoLoadMore(true);
      setSearchRecipeData([]);
      setIsSearching(true);
    }
  }, [state.search]);

  useEffect(() => {
    if (searchRecipeData.length === 0 && isSearching) {
      const tempPage = 0;
      fetchMoreRecipeData(tempPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRecipeData, isSearching]);

  useEffect(() => {
    if (allRecipe.length === 0 && isNotSearching) {
      const tempPage = 0;
      fetchRecipeData(tempPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRecipe, isNotSearching]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 120) {
      if (!loadMore && noLoadMore) {
        const tempPage = page + 1;
        setPage(tempPage);
        // setLoadMore(true);
        fetchMoreRecipeData(tempPage);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const fetchRecipeData = async (pageNo: number) => {
    let userData = await getUserData();
    setLoadMore(true);
    let params: any = {
      PageNumber: pageNo.toString(),
      PageSize: "12",
      // SearchProperty: "Name",
      SearchValue: state.search.toString(),
      // SearchOption: "contains",
      UserId: userData?.id ? userData?.id : 0,
    };

    await GET(APIS.GET_ALL_RECIPE, params)
      .then((res: any) => {
        setLoadMore(false);
        if (res.isSuccess) {
          setAllRecipe(res.result);
        }
      })
      .catch(() => {
        setLoadMore(false);
      })
      .finally(() => {
        setLoadMore(false);
        setIsSearching(false);
        setIsNotSearching(false);
      });
  };

  const fetchMoreRecipeData = async (pageNo: number) => {
    let userData = await getUserData();
    setLoadMore(true);
    let params = {
      PageNumber: pageNo.toString(),
      PageSize: "12",
      // SearchProperty: "Name",
      SearchValue: state.search.toString(),
      // SearchOption: "contains",
      UserId: userData?.id ? userData?.id : 0,
    };

    await GET(APIS.GET_ALL_RECIPE, params)
      .then((res: any) => {
        setIsSearching(false);
        setLoadMore(false);
        if (res.isSuccess) {
          const newData: [] = res.result;
          if (newData.length === 0) {
            setNoLoadMore(false);
          }
          if (state.search.trim().length > 0) {
            setSearchRecipeData([]);
            const newRecipeData = [...searchRecipeData, ...newData];
            const unique = _.uniqBy(newRecipeData, "id");
            setSearchRecipeData(unique);
            setAllRecipe([]);
          } else {
            const newRecipeData = [...allRecipe, ...newData];
            const unique = _.uniqBy(newRecipeData, "id");
            setAllRecipe(unique);
            setSearchRecipeData([]);
          }
        }
      })
      .catch(() => {
        setLoadMore(false);
      })
      .finally(() => {
        setLoadMore(false);
        setIsSearching(false);
        setIsNotSearching(false);
      });
  };

  const redirect = (id: string) => {
    if (id) {
      const temp = localStorage.getItem("userData");
      setRecipeDetailPageid(id);

      if (temp) {
        navigate("/dashboard/recipedetail", {
          state: {
            recipePage: true,
            recipeId: id,
          },
        });
      } else {
        setOpenModalLogin({
          loginModalData: true,
          isDeatil: true,
          isAdd: false,
          isFavorite: false,
        });
      }
    }
  };

  const handleAddrecipeRedirect = async (id: string) => {
    const temp = localStorage.getItem("userData");

    setRecipeDetailPageid(id);
    // setOpenDayModal(true); // tempory direct code to open modal
    if (temp) {
      if (!paymentStatus) {
        setOpenDayModal(true);
      }
      if (paymentStatus) {
        setSubcriptiomModal(true);
      }
    } else {
      setOpenModalLogin({
        loginModalData: true,
        isDeatil: false,
        isAdd: true,
        isFavorite: false,
      });
    }
  };

  const addFavoriteRecipe = async () => {
    let userData = getUserData();
    if (!userData) return;
    setIsAddLoading(true);
    let params = {
      id: recipeDetailPageId,
      isFavorite: true,
    };

    await POST(APIS.ADD_FAVORITE_RECIPE, params, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          toast.success("Recipe successfully add in favorite");
          setOpenFavouriteModal(false);
        }
        if (!res.isSuccess && res.errors) {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setOpenFavouriteModal(false);
        setRecipeDetailPageid("");
        setIsAddLoading(false);
      });
  };

  const handleFavouriterecipeRedirect = (id: any, titleRecipe: any) => {
    const temp = localStorage.getItem("userData");
    setRecipeDetailPageid(id);
    setRecipeName(titleRecipe);
    if (temp) {
      if (!paymentStatus) {
        setOpenFavouriteModal(true);
      }
      if (paymentStatus) {
        setSubcriptiomModal(true);
      }
    } else {
      setOpenModalLogin({
        loginModalData: true,
        isDeatil: false,
        isAdd: false,
        isFavorite: true,
      });
    }
  };

  const setSubcriptionStuts = async () => {
    setLoader(true);
    let temp = await CheckSubscription();
    setPaymentStuts(temp?.isSubscriptionRequired);
    setLoader(false);
  };

  const handaleSubcription = () => {
    // setSubcriptiomModal(false);
    // setPaymentModal(true);
    navigate("/dashboard/subscriptionplan");
  };

  return (
    <>
      <Container
        component="main"
        sx={{
          marginTop: "11px",
          marginBottom: "11px",
          width: "100%",
          color: Color.color10,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            textAlign={"center"}
            style={{
              ...FontStyles.font40Prociono,
              fontSize: isMatch ? "28px" : "40px",
              color: Color.color10,
              fontFamily: "Playfair Display SC",
            }}
          >
            What to Cook This week
          </Typography>
        </Box>
      </Container>
      <Box
        sx={{
          width: "100%",
          height: 1,
          border: 1,
          marginTop: "11px",
          color: Color.color17,
        }}
      ></Box>
      <Box
        sx={{
          marginTop: "11px",
          marginBottom: "11px",
          width: "100%",
          color: Color.color10,
        }}
      >
        <Container
          // style={{ width: "81.25%" }}
          sx={{
            marginBottom: 5,
            marginTop: 5,
            // width: "1300px",
            maxWidth: "1300px",
            // backgroundColor: "pink",
          }}
        >
          <Box
            sx={{ width: "100%" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              alt="recipe"
              src={recipeBackground}
              style={{ width: "100%", borderRadius: 10 }}
            />
          </Box>
          <UnderLine
            sx={{
              width: "100%",
              height: 1,
              border: 1,
              marginTop: 3,
              color: Color.color17,
            }}
          />

          <HederPageWithCard
            cardSize={cardSize}
            gridSize={gridSize}
            headerAlignTop={true}
            title="Recipes"
            isloadder={loadMore}
          />

          <CardUiTest
            headerAlignTop={true}
            cardSize={cardSize}
            gridSize={gridSize}
            cardData={
              state.search.trim().length > 0 ? searchRecipeData : allRecipe
            }
            handleAdd={(id) => handleAddrecipeRedirect(id)}
            handleFavourite={(id, titleRecipe) =>
              handleFavouriterecipeRedirect(id, titleRecipe)
            }
            recipeDetail={(id) => redirect(id ? id : "")}
            isloading={loadMore}
          />
          {/* <CardUi
            headerAlignTop={true}
            cardSize={cardSize}
            gridSize={gridSize}
            cardData={
              state.search.trim().length > 0 ? searchRecipeData : allRecipe
            }
            handleAdd={(id) => handleAddrecipeRedirect(id)}
            handleFavourite={(id, titleRecipe) =>
              handleFavouriterecipeRedirect(id, titleRecipe)
            }
            recipeDetail={(id) => redirect(id ? id : "")}
            isloading={loadMore}
          /> */}

          {loadMore && (
            <Box
              sx={{ height: "100px" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress style={{ color: Color.color15 }} />
            </Box>
          )}
          <DaysModal
            open={openDayModal}
            onClose={() => setOpenDayModal(false)}
            data={recipeDetailPageId}
            pageTitle="dashborad"
          />
        </Container>
      </Box>

      <FavoriteModal
        open={openFavouriteModal}
        onClickAddFavorite={() => addFavoriteRecipe()}
        onClickRemove={() => {
          handleCloseFavourite();
          setRecipeDetailPageid("");
        }}
        onCloseHandle={() => handleCloseFavourite()}
        titleRecipeName={recipeName}
        isLoading={isAddLoading}
      />

      <SubcriptionModal
        open={subcriptionModal}
        onCloseHandle={() => setSubcriptiomModal(false)}
        upgradeNowAction={handaleSubcription}
      />
      <PaymentCard
        open={paymentModal}
        handleClose={() => {
          setPaymentModal(false);
          setSubcriptionStuts();
        }}
      />

      {openModalLogin ? (
        <LoginModal
          open={openModalLogin}
          onClose={() => {
            setRecipeDetailPageid("");
            setOpenModalLogin({
              loginModalData: false,
              isDeatil: false,
              isAdd: false,
              isFavorite: false,
            });
            dispatch({ isLogin: false });
          }}
          data={recipeDetailPageId}
          openFavoriteModal={() => {
            setOpenModalLogin({
              loginModalData: false,
              isDeatil: false,
              isAdd: false,
              isFavorite: false,
            });
            setTimeout(() => {
              setOpenFavouriteModal(true);
            }, 300);
          }}
          openWeekModal={() => {
            setOpenModalLogin({
              loginModalData: false,
              isDeatil: false,
              isAdd: false,
              isFavorite: false,
            });
            setTimeout(() => {
              setOpenDayModal(true);
            }, 300);
          }}
        />
      ) : null}
    </>
  );
};
export default DashboardAppPage;
