import Color from "../../../theme/color";
import FontStyles from "../../../theme/font";
import palette from "../../../theme/palette";

const Styles = {
  appbarContiner: {
    // backgroundColor: "pink",
    // height: "auto",
    // borderBottom: 1,
    // borderBottomColor: "black",
  },

  headerTitle: {
    ...FontStyles.font28PlayfairDisplaySC,
    color: palette.mise_in_place.fontColor,
    fontWeight: "600px",
  },
  searchIcon: {
    color: Color.color1,
    fontSize: "30px",
  },
  menuIconContiner: {
    mr: 2,
    marginLeft: "1%",
  },
  menuIcon: {
    color: Color.color1,
  },
  headerTabContiner: {
    // display: { xs: "none", sm: "block" },
    display: "inline-flex",

    flexGrow: 1,
    marginBottom: 0,
    paddingBottom: 0,
  },
  headerButtonBody: {
    color: Color.color10,
    ...FontStyles.font16PlayfairDisplaySC,
    textAlign: "center",
    borderTop: 1,
    borderRight: 1,
    borderLeft: 1,
    borderRadius: 0,
    marginLeft: 3,
    marginBottom: 0,

    borderColor: "#919699",
    paddingLeft: 2,
    paddingRight: 2,
  },
};
export default Styles;
