import { Box, Button, Grid, Typography } from "@mui/material";
import * as React from "react";
import Color from "src/theme/color";
import friends from "../../aasets/images/friends.png";

const GetStarted = () => {
  return (
    <>
      <Grid container>
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          xs={12}
          md={6}
          order={{
            xs: 1,
            md: 0,
          }}
        >
          <Button
            variant="contained"
            sx={{
              color: Color.color4,
              bgcolor: Color.color1,
              fontFamily: "Prociono",
              fontSize: "1.25rem",
              fontWeight: 400,
              lineHeight: "normal",
              py: 1,
              px: 4,
              mt: {
                xs: 2,
                md: 8,
              },
              borderRadius: "0.5rem",
              ":hover": {
                bgcolor: Color.color1,
              },
            }}
            href="signup"
          >
            GET STARTED TODAY
          </Button>
          <Typography
            color={Color.color10}
            fontFamily={"Playfair Display"}
            fontSize={{
              xs: "1.5rem",
              md: "2.5rem",
            }}
            fontWeight={400}
            lineHeight={"normal"}
            align="center"
            mt={4}
          >
            14-Day FREE TRIAL!
          </Typography>
          <Typography
            color={Color.color10}
            fontFamily={"Playfair Display"}
            fontSize={{
              xs: "1.3rem",
              md: "1.5rem",
            }}
            fontWeight={400}
            lineHeight={"normal"}
            align="center"
            mt={4}
          >
            No payment info required. <br /> Only $9.99 a month to subscribe.
            Cancel at any time
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 0,
            md: 1,
          }}
        >
          <Box
            height={{
              xs: "18rem",
              md: "22.75rem",
            }}
          >
            <img
              src={friends}
              alt="profile"
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default GetStarted;
