import Color from "../../theme/color";

const Styles = {
  continer: {
    marginTop: 6,
    marginBottom: 6,
  },
  body: {
    boxShadow: 20,
    borderRadius: 2,
    px: 2,
    py: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  googleSignIn: {
    mt: 1,
    mb: 1,
    borderColor: Color.color1,
    fontFamily: "Prociono",
    color: Color.black,
  },
};
export default Styles;
