import { Avatar, Button, CircularProgress, Typography } from "@mui/material";
import Color from "../theme/color";
import FontStyles from "../theme/font";

const ButtonUI = ({
  type,
  fullWidth,
  variant,
  sx,
  style,
  label,
  image,
  lableStyle,
  action,
  isLoading,
  size,
  lodderColor,
  disabled = false
}: ButtonUiProps) => {
  return (
    <Button
      sx={[styles.buttonContainer, sx]}
      variant={variant}
      fullWidth={fullWidth}
      type={type}
      style={style}
      onClick={isLoading ? undefined : action}
      size={size}
      disabled={disabled}
    >
      {image && (
        <Avatar src={image} sx={{ width: 24, height: 24, marginRight: 1 }} />
      )}
      <Typography
        style={lableStyle || styles.label}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
      >
        {isLoading ? (
          <CircularProgress size={"1.4rem"} style={{ color: lodderColor ? lodderColor : Color.white }} />
        ) : (
          label
        )}
      </Typography>
    </Button>
  );
};
export default ButtonUI;

const styles = {
  buttonContainer: {
    textTransform: "none",
    height: 44,
    fontSize: 20,
    borderRadius: "8px",
  },
  label: {
    ...FontStyles.font14Prociono,
    color: Color.color4,
    fontWeight: "400",
  },
};

type ButtonUiProps = {
  type?: "submit" | "reset" | "button" | undefined;
  fullWidth?: boolean;
  variant: "text" | "outlined" | "contained";
  sx?: any;
  style?: React.CSSProperties;
  label: string;
  lodderColor?: string
  image?: string;
  lableStyle?: React.CSSProperties;
  action?: () => void;
  isLoading?: boolean;
  size?: "small" | "medium" | "large";
  disabled?: boolean
};
