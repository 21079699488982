import { Typography, useMediaQuery, useTheme } from "@mui/material";
import UnderLine from "../underLine";
import Color from "../../theme/color";
import FontStyles from "../../theme/font";

const HederPageWithCard = ({
  title,

  handleAdd,
  handleFavourite,
  recipeDetail,
}: homepageCardProps) => {
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  return (
    <>
      <Typography
        sx={{
          width: "100%",
          marginTop: 2,
          ...FontStyles.font36Prociono,
          fontSize: isMatch ? "28px" : "36px",
          color: Color.color10,
        }}
        textAlign={"center"}
      >
        {title}
      </Typography>
      <UnderLine
        sx={{
          width: "100%",
          height: 1,
          border: 1,
          marginTop: 2,
          color: Color.color17,
        }}
      />

      {/* <CardUi
        headerAlignTop={headerAlignTop}
        cardSize={cardSize}
        gridSize={gridSize}
        cardData={cardData}
        handleAdd={(id) => handleAdd && handleAdd(id ? id : "")}
        handleFavourite={handleFavourite}
        recipeDetail={(id) => recipeDetail && recipeDetail(id ? id : "")}
        isloading={isloadder}
      /> */}
    </>
  );
};
export default HederPageWithCard;
interface homepageCardProps {
  headerAlignTop?: boolean;
  cardSize?: cardSize;
  gridSize?: gridSize;
  title: string;
  // cardData: cardDataProps[];
  isloadder?: boolean;
  cardData?: any;
  isInfinitLodder?: boolean;
  handleAdd?: (id: string) => void;
  handleFavourite?: () => void;
  recipeDetail?: (id?: string) => void;
}
type cardSize = {
  xs: number;
  sm: number;
  md: number;
};
type gridSize = {
  xs: number;
  sm: number;
  md: number;
};
