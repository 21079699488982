import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Color from "src/theme/color";
import FontStyles from "src/theme/font";
import "./styles.css";
import ButtonUI from "src/components/buttonUi";
import { useState } from "react";
import PaymentCard from "src/components/payment/home";
import { FreeSubcriptionPrice, SubcriptionPrice } from "src/utils/constant";

const SubcriptionPlan = () => {
  const [pymentModal, setPymentModal] = useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const PymentModalAction = () => {
    setPymentModal(true);
  };
  return (
    <>
      <Box
        display={"flex"}
        justifyItems={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Box
          sx={{ backgroundColor: Color.color1, height: "200px", width: "100%" }}
        >
          <Box
            sx={{
              width: "100",
              marginTop: 5,
              height: "30px",
            }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              textAlign={"center"}
              sx={{ ...FontStyles.font24PlayfairDisplaySC, color: Color.white }}
            >
              {" "}
              SUBSCRIPTION PLAN
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: isMatch ? "350px" : "800px",
            height: isMatch ? "750px" : "400px",
            // backgroundColor: Color.color11,
            backgroundColor: "#eeeff9",
            borderRadius: 1,
            boxShadow: 3,
            position: "absolute",
            top: isMatch ? 155 : 177,
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                width: "50%",
                height: isMatch ? "750px" : "400px",
                borderRight: 2,
                borderColor: Color.color17,
              }}
            >
              {" "}
              <div
                style={{
                  width: "100%",
                  height: "20%",
                  borderColor: Color.color17,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  textAlign={"center"}
                  sx={{
                    ...FontStyles.font20PlayfairDisplaySC,
                    color: Color.color10,
                  }}
                >
                  14-Day Free Trial
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "60%",
                  backgroundColor: Color.white,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  textAlign={"left"}
                  sx={{
                    ...FontStyles.font16Prociono,
                    color: Color.color4,
                    padding: 2,
                  }}
                >
                  Discover the Joy of Cooking with Mise en Place!
                  <br /> <br /> 🌟 No credit card required. <br />
                  🍽️ Access to all features for 14 days. <br /> 🛒 Effortless
                  meal planning and grocery lists.
                  <br /> 🍅 Digital pantry for stress-free cooking.
                  <br />
                  <br /> Unleash your culinary creativity! Start your free trial
                  today.
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "20%",
                  backgroundColor: Color.white,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            </Box>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "20%",
                  borderColor: Color.color17,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  textAlign={"center"}
                  sx={{
                    ...FontStyles.font20PlayfairDisplaySC,
                    color: Color.color10,
                  }}
                >
                  Month Payment Option <br />$9.99/Month Plan
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "60%",
                  backgroundColor: Color.white,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  textAlign={"left"}
                  sx={{
                    ...FontStyles.font16Prociono,
                    color: Color.color4,
                    padding: 2,
                  }}
                >
                  Upgrade to Mise en Place Premium!
                  <br /> <br />
                  🍴 Unlimited access to all features. <br /> 🔁 Monthly
                  subscription for just $9.99. <br /> ❌ Cancel anytime - no
                  strings attached. <br /> 🎉 Elevate your cooking game with
                  ease.
                  <br />
                  <br />
                  Join the Mise en Place community and make mealtime
                  extraordinary.
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "20%",
                  backgroundColor: Color.white,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonUI
                  label="Upgrade to Monthly Plan"
                  variant="outlined"
                  style={{
                    width: isMatch ? "90%" : "50%",
                    backgroundColor: Color.color1,
                    borderColor: Color.color1,
                    whiteSpace: "nowrap",
                  }}
                  action={PymentModalAction}
                />
              </div>
            </Box>
          </div>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: isMatch ? "800px" : "600px",
          }}
        ></Box>
      </Box>
      <PaymentCard
        open={pymentModal}
        handleClose={() => {
          setPymentModal(false);
          // setSubcriptionStuts();
        }}
      />
    </>
  );
};
export default SubcriptionPlan;
