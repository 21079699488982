import Color from "../../../theme/color";
import FontStyles from "../../../theme/font";

const Styles = {
  continer: {
    width: "100%",
    backgroundColor: Color.color7,
    paddingTop: "1rem",
    paddingBottom: 0,
    margin: 0,
    marginBottom: 0,
  },
  headerTxt: {
    ...FontStyles.font28Prociono,
    fontFamily: "Playfair Display SC",
    color: Color.color1,
  },
  categortItem: {
    ...FontStyles.font16Prociono,
    color: Color.color3,
    textDecoration: "none",
  },
  copyRight: {
    ...FontStyles.font16Prociono,
    color: Color.color9,
    textDecoration: "none",
  },
};
export default Styles;
