/* eslint-disable react-hooks/exhaustive-deps */
import {
  cuurentWeeKFirstDateAndDay,
  getFirstDayOfCurrentWeek,
  getFirstDayOfNextWeek,
  nextWeekFistDay,
  selectedDateToTwoDate,
  twoWeekDateSelected,
} from "../../utils/dateMethod";
import Color from "../../theme/color";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  // useMediaQuery,
  // useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { POST } from "../../config/https";
import APIS from "../../config/api";
import getUserData from "../../utils/getLocalStorageData";
import RecipeCard from "../../components/recipeCard";
import FullScreenLoadder from "../../components/fullScreenLoadder";

import { useNavigate } from "react-router-dom";
import FavoriteModal from "../../components/favoriteModal";
import { toast } from "react-hot-toast";
import DaysModal from "../../components/daysModal";
// import Tabsa from "../../components/tab";
import { usePersistentState } from "../../contexts/GlobalContext";
import FontStyles from "../../theme/font";
import { makeStyles } from "@material-ui/core";
import LoginModal from "../../components/login";
import SubcriptionModal from "../../components/subscriptionModal";
import CheckSubscription from "src/utils/checkSubscription";
import PaymentCard from "src/components/payment/home";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  button: {
    background: Color.color1,
    color: "white",
    border: "2px solid black",
    "&:hover": {
      background: Color.color1,
      color: Color.color10,
      border: "2px solid #BFBF60",
    },
    "&:disabled": {
      background: "gray",
      color: "white",
      border: "2px solid darkgray",
    },
    "&:disabled:hover": {
      background: "gray",
      color: "white",
      border: "2px solid darkgray",
      cursor: "not-allowed",
    },
  },
}));

const Recipes = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedDate, setSelcetedDate] = useState(new Date());
  const [loadder, setLoader] = useState(false);
  const [, dispatch]: any = usePersistentState();
  const [allRecipe, setAllRecipe] = useState([]);
  const [recipeDetailPageId, setRecipeDetailPageid] = useState("");
  const [daysModal, setDaysModal] = useState(false);
  const [addFavoriteModal, setAddFavoriteModal] = useState(false);
  const [recipeName, setRecipeName] = useState("");
  const [dateData, setDateData] = useState([]);
  const [paymentModal, setPaymentModal] = useState(false);
  const [tabsId, setTabs] = useState(1);
  const [nextWeek, setNextWeek] = useState(selectedDate);
  const [currentStart, setCurrentStart] = useState(selectedDate);
  const [paymentStatus, setPaymentStuts] = useState(false);
  const [openModalLogin, setOpenModalLogin] = useState({
    loginModalData: false,
    isRecipes: false,
  });
  const [subcriptionModal, setSubcriptiomModal] = useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const classes = useStyles();

  const navigate = useNavigate();

  // const theme = useTheme();

  useEffect(() => {
    dispatch({ isSearch: false });
    // let checkLoginData = CheckLogin();
    // if (!checkLoginData) {
    //   let temp = {
    //     loginModalData: true,
    //     isRecipes: true,
    //   };
    //   setOpenModalLogin(temp);
    // }
    // let tempUserData = getUserData();
    // if (tempUserData) {
    //   let data = addDateDays(14);
    //   if (data && !openModalLogin.loginModalData) {
    //     // setSubcriptiomModal(true);
    //   }
    // }

    setSubcriptionStuts();

    let firstDate = getFirstDayOfCurrentWeek();
    let nextFirstDate = getFirstDayOfNextWeek();
    setCurrentStart(firstDate);
    setNextWeek(nextFirstDate);

    fetchRecipeInDetail();
  }, []);

  useEffect(() => {
    if (allRecipe.length === 0) {
      let selectdata: any = selectedDateToTwoDate(selectedDate);
      const getNextWeekFirstDate = (selectedDate: any) => {
        const firstDate = new Date(selectedDate);
        firstDate.setDate(selectedDate.getDate() + (7 - selectedDate.getDay()));

        return firstDate;
      };
      let datetest = getNextWeekFirstDate(selectedDate);

      setCurrentStart(selectdata.currentWeekStartDate);
      setNextWeek(datetest);
      let nodateData: any = twoWeekDateSelected(selectedDate);
      let finalDate = nodateData.map((item: any) => {
        return {
          date: item,
          recipeId: "",
          recipe: [],
        };
      });

      setDateData(finalDate);
    }
  }, [allRecipe]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (e: any) => {
    const selectedDate = new Date(e);

    setSelcetedDate(selectedDate);
    let selectdata: any = selectedDateToTwoDate(selectedDate);
    const getNextWeekFirstDate = (selectedDate: any) => {
      const firstDate = new Date(selectedDate);
      firstDate.setDate(selectedDate.getDate() + (7 - selectedDate.getDay()));

      return firstDate;
    };
    let datetest = getNextWeekFirstDate(selectedDate);

    setCurrentStart(selectdata.currentWeekStartDate);
    setNextWeek(datetest);
    let nodateData: any = twoWeekDateSelected(selectedDate);
    let finalDate = nodateData.map((item: any) => {
      return {
        date: item,
        recipeId: "",
        recipe: [],
      };
    });

    setDateData(finalDate);
    setTimeout(() => {
      handleClose();
      fetchRecipeInDetail(selectedDate);
    }, 500);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const fetchRecipeInDetail = async (selectDate?: any) => {
    let userData = getUserData();
    if (!userData) return;
    // let selectdata = selectDate && selectedDateToTwoDate(selectDate);

    // let firstDate = cuurentWeeKFirstDateAndDay();
    // let endDate = nextWeekFistDay();
    setLoader(true);
    // let param = {
    //   startDate:
    //     selectdata && selectdata?.currentWeekStartDate
    //       ? selectdata?.currentWeekStartDate
    //       : firstDate,
    //   endDate:
    //     selectdata && selectdata?.nextWeekLastDate
    //       ? selectdata?.nextWeekLastDate
    //       : endDate,
    // };

    let currentDate = selectedDateToTwoDate(new Date());
    let params = {
      startDate: currentDate.currentWeekStartDate,
      endDate: currentDate.nextWeekLastDate,
    };

    await POST(APIS.GET_USER_RECIPE_DETAIL, params, userData?.jwtToken)
      .then((res: any) => {
        if (!res.result) {
          setAllRecipe([]);
        }
        if (res.result) {
          setAllRecipe(res.result.recipes);
        }
      })
      .catch((error) => { })
      .finally(() => {
        setLoader(false);
      });
  };

  const redirect = (id: string) => {
    if (id) {
      const temp = localStorage.getItem("userData");
      if (temp) {
        setRecipeDetailPageid(id);

        navigate("/dashboard/recipedetail", {
          state: {
            recipePage: true,
            recipeId: id,
          },
        });
      }
    }
  };

  const addFavoriteRecipe = async () => {
    let userData = getUserData();
    if (!userData) return;

    let params = {
      id: recipeDetailPageId,
      isFavorite: true,
    };

    await POST(APIS.ADD_FAVORITE_RECIPE, params, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          toast.success("Successfully add in favorite list");
          setAddFavoriteModal(false);
        }
        if (!res.isSuccess && res.errors) {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setAddFavoriteModal(false);
        setRecipeDetailPageid("");
      });
  };

  const loginOnClose = () => {
    let temp = getUserData();
    if (temp) {
      setOpenModalLogin({
        loginModalData: false,
        isRecipes: false,
      });
    }
    if (!temp) {
      navigate("/dashboard/app");
    }
  };

  const navigatePasteRecipes = () => {
    navigate("/dashboard/pastrecipes", {
      state: {
        recipesPage: true,
      },
    });
  };

  const setSubcriptionStuts = async () => {
    let temp = await CheckSubscription();
    setPaymentStuts(temp?.isSubscriptionRequired);
  };
  const handaleSubcription = () => {
    // setSubcriptiomModal(false);
    // setPaymentModal(true);
    navigate("/dashboard/subscriptionplan");
  };

  const filterRecipe = (tabsId: any) => {
    if (tabsId === 1 && allRecipe.length > 1) {
      const firstWeek = allRecipe.filter((x, index) => x && index < 7);
      return firstWeek;
    }
    if (tabsId === 2 && allRecipe.length > 1) {
      const secondWeek = allRecipe.filter((x, index) => x && index > 6);
      return secondWeek;
    }
    if (tabsId === 1 && allRecipe.length === 0) {
      const firstWeek = dateData.filter((x, index) => x && index < 7);
      return firstWeek;
    }
    if (tabsId === 2 && allRecipe.length === 0) {
      const secondWeek = dateData.filter((x, index) => x && index > 6);
      return secondWeek;
    }
  };

  filterRecipe(tabsId);

  return (
    <div>
      <Container
        component="main"
        sx={{
          marginTop: "80px",
          maxWidth: "1300px",
          color: Color.color10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: isMatch ? "center" : "space-between",
          }}
        >
          {isMatch ? null : <Box sx={{ height: "34px", width: "190px" }}></Box>}
          <Typography
            sx={{
              width: "auto",
              whiteSpace: "nowrap",

              ...FontStyles.font24PlayfairDisplaySC,
              fontSize: isMatch ? "18px" : "28px",

              cursor: "pointer",
            }}
          >
            {`Week of ${moment(currentStart).format("MMMM Do")} Recipes`}
          </Typography>
          {isMatch ? null : (
            <Button
              // onClick={(e: any) => handleClick && handleClick(e)}
              onClick={() => navigatePasteRecipes()}
              className={classes.button}
              sx={{
                height: "34px",
                width: "190px",
                borderColor: Color.color1,
                backgroundColor: Color.color1,
                ...FontStyles.font24Prociono,
                fontSize: isMatch ? "18px" : "20px",
                color: Color.white,
              }}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
              }}
            >
              PAST RECIPES
            </Button>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 1,
            border: 1,
            marginTop: "11px",
            color: Color.color17,
          }}
        ></Box>

        {/* <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ marginTop: "22px", marginBottom: 1 }}
        >
          <Box sx={{ width: "100%" }}>
            <Tabsa
              onClickTab={(id) => setTabs(id)}
              firstDate={currentStart}
              lastDate={nextWeek}
              handleClick={() => navigatePasteRecipes()}
            />
          </Box>

          <Divider />

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar onChange={(e: any) => handleDateChange(e)} />
            </LocalizationProvider>
          </Popover>
        </Box> */}
        <FavoriteModal
          open={addFavoriteModal}
          onClickAddFavorite={() => addFavoriteRecipe()}
          onClickRemove={() => {
            setAddFavoriteModal(false);
            setRecipeDetailPageid("");
          }}
          onCloseHandle={() => setAddFavoriteModal(false)}
          titleRecipeName={recipeName}
        />
        <DaysModal
          open={daysModal}
          onClose={(close) => {
            setDaysModal(false);
            if (close) {
              fetchRecipeInDetail();
            }
          }}
          data={recipeDetailPageId}
          pageTitle="recipes"
        />
        {isMatch ? (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Button
              // onClick={(e: any) => handleClick && handleClick(e)}
              onClick={() => navigatePasteRecipes()}
              className={classes.button}
              sx={{
                height: "34px",
                width: "190px",
                marginTop: 1,
                borderColor: Color.color1,
                backgroundColor: Color.color1,
                ...FontStyles.font24Prociono,
                fontSize: isMatch ? "18px" : "20px",
                color: Color.white,
              }}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
              }}
            >
              PAST RECIPES
            </Button>
          </Box>
        ) : null}
        {/* {allRecipe.length > 0 ? ( */}
        {allRecipe.length > 0 ? (
          <RecipeCard
            // data={filterRecipe(tabsId)}
            data={allRecipe}
            recipeDetail={(id) => redirect(id ? id : "")}
            lastDate={nextWeek}
            addDays={(id) => {
              setRecipeDetailPageid(id);
              if (!paymentStatus) {
                setDaysModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            addFavorite={(id, recipeTitle) => {
              setRecipeDetailPageid(id);
              setRecipeName(recipeTitle ? recipeTitle : "");
              if (!paymentStatus) {
                setAddFavoriteModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            tabsId={tabsId}
            isDummyCard={false}
          />
        ) : (
          <RecipeCard
            // data={filterRecipe(tabsId)}
            data={dateData}
            recipeDetail={(id) => redirect(id ? id : "")}
            lastDate={nextWeek}
            addDays={(id) => {
              setRecipeDetailPageid(id);
              if (!paymentStatus) {
                setDaysModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            addFavorite={(id, recipeTitle) => {
              setRecipeDetailPageid(id);
              setRecipeName(recipeTitle ? recipeTitle : "");
              if (!paymentStatus) {
                setAddFavoriteModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            tabsId={tabsId}
            isDummyCard={true}
          />
        )}
        {/* ) : (
          <RecipeCard
            test={true}
            data={dateData}
            notData={true}
            addDays={(id) => {
              setRecipeDetailPageid(id);
              setDaysModal(true);
            }}
            addFavorite={(id) => {
              setRecipeDetailPageid(id);
              setAddFavoriteModal(true);
            }}
            tabsId={tabsId}
          />
        )} */}
        {/* {allRecipe.length > 0 ? (
          <RecipeCardDemo
            data={allRecipe}
            recipeDetail={(id) => redirect(id ? id : "")}
            addDays={(id) => {
              setRecipeDetailPageid(id);
              if (!paymentStatus) {
                setDaysModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            addFavorite={(id, recipeTitle) => {
              setRecipeDetailPageid(id);
              setRecipeName(recipeTitle ? recipeTitle : "");
              if (!paymentStatus) {
                setAddFavoriteModal(true);
              }
              if (paymentStatus) {
                setSubcriptiomModal(true);
              }
            }}
            tabsId={tabsId}
          />
        ) : (
          <RecipeCard
            test={true}
            data={dateData}
            notData={true}
            addDays={(id) => {
              setRecipeDetailPageid(id);
              setDaysModal(true);
            }}
            addFavorite={(id) => {
              setRecipeDetailPageid(id);
              setAddFavoriteModal(true);
            }}
            tabsId={tabsId}
          />
        )} */}
        {openModalLogin ? (
          <LoginModal
            open={openModalLogin}
            onClose={() => {
              loginOnClose();
            }}
          />
        ) : null}
        <SubcriptionModal
          open={subcriptionModal}
          onCloseHandle={() => setSubcriptiomModal(false)}
          upgradeNowAction={handaleSubcription}
        />
        <PaymentCard
          open={paymentModal}
          handleClose={() => {
            setPaymentModal(false);
            setSubcriptionStuts();
          }}
        />
      </Container>
      <FullScreenLoadder open={loadder} />
    </div>
  );
};
export default Recipes;
