import { Box, Button, Grid, Typography } from "@mui/material";
import * as React from "react";
import Color from "src/theme/color";
import family from "../../aasets/images/family.png";

const LandingHeader = () => {
  return (
    <>
      <Grid container>
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          xs={12}
          md={6}
          order={{
            xs: 1,
            md: 0,
          }}
        >
          <Typography
            color={Color.color10}
            fontFamily={"Playfair Display SC"}
            fontSize={{
              xs: "1.3rem",
              md: "2.5rem",
            }}
            fontWeight={400}
            fontStyle={"normal"}
            lineHeight={"normal"}
            mt={{
              md: 6,
            }}
          >
            MAKE COOKING AT HOME EASIER
          </Typography>
          <Typography
            color={Color.color10}
            fontFamily={"Playfair Display"}
            fontSize={{
              xs: "1.25rem",
              md: "2rem",
            }}
            fontWeight={400}
            lineHeight={"normal"}
            align="center"
            mt={2}
          >
            Spend less time planning and shopping <br /> and more time enjoying
            meals at home
          </Typography>
          <Button
            variant="contained"
            sx={{
              color: Color.color4,
              bgcolor: Color.color1,
              fontFamily: "Prociono",
              fontSize: "1.25rem",
              fontWeight: 400,
              lineHeight: "normal",
              py: 1,
              px: 4,
              mt: 3,
              borderRadius: "0.5rem",
              ":hover": {
                bgcolor: Color.color1,
              },
            }}
            href="signup"
          >
            SIGN UP
          </Button>
          <Typography
            color={Color.color10}
            fontFamily={"Playfair Display"}
            fontSize={{
              xs: "1.25rem",
              md: "2rem",
            }}
            fontWeight={400}
            lineHeight={"normal"}
            align="center"
            mt={{
              xs: 4,
              md: 8,
            }}
          >
            Start your 14-Day FREE TRIAL today!
            <br /> Only $9.99 a month to subscribe
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 0,
            md: 1,
          }}
        >
          <Box
            height={{
              xs: "20rem",
              md: "30rem",
            }}
          >
            <img
              src={family}
              alt="profile"
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default LandingHeader;
