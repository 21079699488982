import { useMediaQuery, useTheme } from "@mui/material";

const IsPhone = () => {
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return isMatch ? true : false;
};
export default IsPhone;
