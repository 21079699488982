import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "./App.css";
import Router from "./routes";
import Compose from "./utils/Compose";
import { ProvideGlobalState } from "./contexts/GlobalContext";
import { Toaster } from "react-hot-toast";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLoginApiKey, StripePublicKey, StripePublicKeyPro } from "./utils/constant";
const stripePromise = loadStripe(StripePublicKey);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <GoogleOAuthProvider clientId={GoogleLoginApiKey}>
        <Compose components={[ProvideGlobalState]}>
          <HelmetProvider>
            <BrowserRouter>
              <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                  className: "",
                  duration: 5000,
                  style: {
                    background: "#363636",
                    color: "#fff",
                  },
                }}
              />
              <Router />
            </BrowserRouter>
          </HelmetProvider>
        </Compose>
      </GoogleOAuthProvider>
    </Elements>
  );
}

export default App;
