import { AxiosError } from "axios";

export const parseAxiosError = (e: AxiosError): string => {
  const { response }: any = e;
  // let message: string = response?.data?.errors[0];

  // const res = response.config;
  // message = response?.data?.errors[0];
  return response;
};
