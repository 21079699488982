import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import Color from "src/theme/color";
import personCircle from "../../aasets/images/person-circle.png";
import calendar from "../../aasets/images/calendar.png";
import ingredients from "../../aasets/images/ingredients.png";
import groceryShelf from "../../aasets/images/grocery-shelf.png";
import checkout from "../../aasets/images/checkout.png";
import chefHat from "../../aasets/images/chef-hat.png";

const howItWorks = [
  {
    icon: personCircle,
    text: "Update your Profile with your Zip Code and Food preferences",
  },
  {
    icon: calendar,
    text: "Choose your Recipes for the week",
  },
  {
    icon: ingredients,
    text: "Purchase your Grocery List with our Grocery Store  Integration",
  },
  {
    icon: groceryShelf,
    text: "Remove any items from the cart you already have in your pantry",
  },
  {
    icon: checkout,
    text: "Schedule your Pick Up or Delivery at your grocery store",
  },
  {
    icon: chefHat,
    text: "Cook your meals throughout the week and enjoy!",
  },
];

const HowItWorks = () => {
  return (
    <>
      <Box>
        <Divider />
        <Typography
          color={Color.color10}
          fontFamily={"Playfair Display SC"}
          fontSize={{
            xs: "1.5rem",
            md: "2.5rem",
          }}
          fontWeight={400}
          fontStyle={"normal"}
          lineHeight={"normal"}
          my={2}
          textTransform={"capitalize"}
          align="center"
        >
          How it works
        </Typography>
        <Divider />
      </Box>
      <Grid container my={4} px={4}>
        {howItWorks.map(({ icon, text }, index) => (
          <Grid
            key={index}
            item
            xs={6}
            md={2}
            px={1}
            display={"flex"}
            direction={"column"}
            alignItems={"center"}
          >
            <Box
              height={{
                xs: "3.5rem",
                md: "6.5rem",
              }}
              width={{
                xs: "3.5rem",
                md: "6.5rem",
              }}
            >
              <img
                src={icon}
                alt="profile"
                style={{ height: "100%", width: "100%" }}
              />
            </Box>
            <Typography
              color={Color.color10}
              fontFamily={"Playfair Display SC"}
              fontSize={{
                xs: "0.7rem",
                md: "1.2rem",
              }}
              fontStyle={"normal"}
              fontWeight={400}
              lineHeight={"normal"}
              align="center"
              textTransform={"uppercase"}
              my={2}
            >
              {text}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default HowItWorks;
