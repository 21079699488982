import getUserData from "./getLocalStorageData";

export function CheckLogin() {
  let temp = getUserData();
  if (temp) {
    return true;
  }
  if (!temp) {
    return false;
  }
}
