import { Typography } from "@mui/material";

const CustomTyphogrphy = ({ lable, price, styles }: CustomTyphogrphyProps) => {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ width: "80%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            paddingLeft: 0.5,

            wdith: "100%",
          }}
          style={styles}
        >
          {lable}
        </Typography>
      </div>
      <div style={{ width: "20%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            wdith: "100%",
          }}
          textAlign={"end"}
          style={styles}
        >
          {price}
        </Typography>
      </div>
    </div>
  );
};
export default CustomTyphogrphy;

interface CustomTyphogrphyProps {
  lable: string;
  price: any;
  styles?: React.CSSProperties;
}
