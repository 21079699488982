import { Typography } from "@mui/material";
import Color from "../../theme/color";
import FontStyles from "../../theme/font";

const ValidationError = ({ formErrors }: ValidationErrorProps) => {
  return (
    <>
      {formErrors ? (
        <Typography style={styles.validationError}>{formErrors}</Typography>
      ) : null}
    </>
  );
};
export default ValidationError;

const styles = {
  validationError: {
    marginTop: 1,
    color: Color.red,
    width: "100%",
    ...FontStyles.font12Prociono,
  },
};

type ValidationErrorProps = {
  formErrors: string;
};
