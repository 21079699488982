import { useEffect, useState } from "react";
import { GET, POST } from "../../config/https";
import APIS from "../../config/api";
import getUserData from "../../utils/getLocalStorageData";
import { Box, CircularProgress, Container } from "@mui/material";
import Color from "../../theme/color";
import { usePersistentState } from "../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _, { add } from "lodash";
import HederPageWithCard from "../../components/homePageCardWithHeder";
import DaysModal from "../../components/daysModal";
import FavoriteModal from "../../components/favoriteModal";
import { toast } from "react-hot-toast";
import { CheckLogin } from "../../utils/checkLoign";
import LoginModal from "../../components/login";
import { addDateDays } from "../../utils/dateMethod";
import SubcriptionModal from "../../components/subscriptionModal";
import CheckSubscription from "src/utils/checkSubscription";
import PaymentCard from "src/components/payment/home";
import CardUiTest from "../demo";

const cardSize = {
  xs: 6,
  sm: 4,
  md: 2,
};
const gridSize = {
  xs: 4,
  sm: 8,
  md: 12,
};

const Favorites = () => {
  const [allRecipe, setAllRecipe] = useState([]);
  const [searchRecipeData, setSearchRecipeData] = useState([]);
  const [recipeDetailPageid, setRecipeDetailPageid] = useState("");
  const [state, dispatch]: any = usePersistentState();
  const [page, setPage] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isNotSearching, setIsNotSearching] = useState(false);
  const [noLoadMore, setNoLoadMore] = useState(true);
  const navigate = useNavigate();
  const [openDayModal, setOpenDayModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [openFavouriteModal, setOpenFavouriteModal] = useState(false);
  const [recipeName, setRecipeName] = useState("");
  const [removeLoading, setRemoveLoading] = useState(false);
  const [subcriptionModal, setSubcriptiomModal] = useState(false);
  const [paymentStatus, setPaymentStuts] = useState(false);
  // const theme = useTheme();
  // const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModalLogin, setOpenModalLogin] = useState({
    loginModalData: false,
    isFavorite: false,
    isDeatil: false,
    isAdd: false,
    isLogin: false,
  });
  const handleCloseFavourite = () => {
    setOpenFavouriteModal(false);
    setRecipeDetailPageid("");
  };

  useEffect(() => {
    dispatch({ isSearch: true });
    let checkLoginData = CheckLogin();
    setSubcriptionStuts();
    if (!checkLoginData) {
      let temp = {
        loginModalData: true,
        isFavorite: true,
        isDeatil: false,
        isAdd: false,
        isLogin: true,
      };
      setOpenModalLogin(temp);
    }
    let tempUserData = getUserData();
    if (tempUserData) {
      let data = addDateDays(14);
      if (data && !openModalLogin.loginModalData) {
        // setSubcriptiomModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.search.trim().length === 0) {
      const tempPage = 0;
      setPage(tempPage);
      setNoLoadMore(true);
      setAllRecipe([]);
      setIsNotSearching(true);
    }
    if (state.search.trim().length > 0) {
      const tempPage = 0;
      setPage(tempPage);
      setNoLoadMore(true);
      setSearchRecipeData([]);
      setIsSearching(true);
    }
  }, [state.search]);

  useEffect(() => {
    if (searchRecipeData.length === 0 && isSearching) {
      const tempPage = 0;
      fetchMoreRecipeData(tempPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRecipeData, isSearching]);

  useEffect(() => {
    if (allRecipe.length === 0 && isNotSearching) {
      const tempPage = 0;
      fetchRecipeData(tempPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRecipe, isNotSearching]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 125) {
      if (!loadMore && noLoadMore) {
        const tempPage = page + 1;
        setPage(tempPage);
        // setLoadMore(true);
        fetchMoreRecipeData(tempPage);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const fetchRecipeData = async (pageNo: number) => {
    let userData = getUserData();
    if (!userData) return;
    setLoadMore(true);
    let params = {
      PageNumber: pageNo.toString(),
      PageSize: "18",
      // SearchProperty: "Name",
      SearchValue: state.search.toString(),
      // SearchOption: "contains",
    };

    await GET(APIS.GET_FAVORITE_RECIPES, params, userData.jwtToken)
      .then((res: any) => {
        setLoadMore(false);
        if (res.isSuccess) {
          setAllRecipe(res.result);
        }
      })
      .catch(() => {
        setLoadMore(false);
      })
      .finally(() => {
        setLoadMore(false);
        setIsSearching(false);
        setIsNotSearching(false);
      });
  };

  const fetchMoreRecipeData = async (pageNo: number) => {
    let userData = getUserData();
    if (!userData) return;
    setLoadMore(true);
    let params = {
      PageNumber: pageNo.toString(),
      PageSize: "18",
      // SearchProperty: "Name",
      SearchValue: state.search.toString(),
      // SearchOption: "contains",
    };

    await GET(APIS.GET_FAVORITE_RECIPES, params, userData.jwtToken)
      .then((res: any) => {
        setIsSearching(false);
        setLoadMore(false);
        if (res.isSuccess) {
          const newData: [] = res.result;
          if (newData.length === 0) {
            setNoLoadMore(false);
          }
          if (state.search.trim().length > 0) {
            setSearchRecipeData([]);
            const newRecipeData = [...searchRecipeData, ...newData];
            const unique = _.uniqBy(newRecipeData, "id");
            setSearchRecipeData(unique);
            setAllRecipe([]);
          } else {
            const newRecipeData = [...allRecipe, ...newData];
            const unique = _.uniqBy(newRecipeData, "id");
            setAllRecipe(unique);
            setSearchRecipeData([]);
          }
        }
      })
      .catch(() => {
        setLoadMore(false);
      })
      .finally(() => {
        setLoadMore(false);
        setIsSearching(false);
        setIsNotSearching(false);
      });
  };
  const removeFavoriteRecipe = async () => {
    let userData = getUserData();
    if (!userData) return;
    setRemoveLoading(true);
    let params = {
      id: recipeDetailPageid,
      isFavorite: false,
    };

    await POST(APIS.ADD_FAVORITE_RECIPE, params, userData.jwtToken)
      .then(async (res: any) => {
        if (res.isSuccess) {
          toast.success("Recipe successfully remove in favorite");
          setOpenFavouriteModal(false);
          const tempPage = 0;
          setPage(tempPage);
          setNoLoadMore(true);
          setIsNotSearching(true);
          // setAllRecipe([]);
          // await navigate("/dashboard/favorite");

          fetchRecipeData(0);
        }
        if (!res.isSuccess && res.errors) {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setOpenFavouriteModal(false);
        setRecipeDetailPageid("");
        setRemoveLoading(false);
      });
  };

  const handleAddrecipeRedirect = async (id: string) => {
    const temp = localStorage.getItem("userData");

    setRecipeDetailPageid(id);

    if (temp) {
      if (!paymentStatus) {
        setOpenDayModal(true);
      }
      if (paymentStatus) {
        setSubcriptiomModal(true);
      }
    } else {
      setOpenModalLogin({
        loginModalData: true,
        isDeatil: false,
        isAdd: true,
        isFavorite: false,
        isLogin: false,
      });
    }
  };

  const handleFavouriterecipeRedirect = (id: any, titleRecipe: any) => {
    const temp = localStorage.getItem("userData");
    setRecipeDetailPageid(id);
    setRecipeName(titleRecipe);

    if (temp) {
      if (!paymentStatus) {
        setOpenFavouriteModal(true);
      }
      if (paymentStatus) {
        setSubcriptiomModal(true);
      }
    } else {
      setOpenModalLogin({
        loginModalData: true,
        isDeatil: false,
        isAdd: false,
        isFavorite: true,
        isLogin: false,
      });
    }
  };

  const redirect = (id: string) => {
    if (id) {
      const temp = localStorage.getItem("userData");
      setRecipeDetailPageid(id);

      if (temp) {
        navigate("/dashboard/recipedetail", {
          state: {
            recipePage: true,
            recipeId: id,
          },
        });
      } else {
        setOpenModalLogin({
          loginModalData: true,
          isDeatil: true,
          isAdd: false,
          isFavorite: false,
          isLogin: false,
        });
      }
    }
  };

  const loginOnClose = () => {
    let temp = getUserData();
    if (temp) {
      setOpenModalLogin({
        loginModalData: false,
        isFavorite: false,
        isDeatil: false,
        isAdd: false,
        isLogin: false,
      });
    }
    if (!temp) {
      navigate("/dashboard/app");
    }
  };

  const setSubcriptionStuts = async () => {
    let temp = await CheckSubscription();
    setPaymentStuts(temp?.isSubscriptionRequired);
  };
  const handaleSubcription = () => {
    // setSubcriptiomModal(false);
    // setPaymentModal(true);
    navigate("/dashboard/subscriptionplan");
  };

  return (
    <div>
      <Container
        // style={{ width: "81.25%" }}
        sx={{
          marginBottom: "30px",
          maxWidth: "1300px",
          marginTop: "11px",
        }}
      >
        <HederPageWithCard
          cardSize={cardSize}
          gridSize={gridSize}
          headerAlignTop={true}
          title="Favorites"
          isloadder={loadMore}
        />

        <CardUiTest
          headerAlignTop={true}
          cardSize={cardSize}
          gridSize={gridSize}
          cardData={
            state.search.trim().length > 0 ? searchRecipeData : allRecipe
          }
          handleAdd={(id) => handleAddrecipeRedirect(id)}
          handleFavourite={(id, titleRecipe) =>
            handleFavouriterecipeRedirect(id, titleRecipe)
          }
          recipeDetail={(id) => redirect(id ? id : "")}
          isloading={loadMore}
        />
        {/* <CardUi
          headerAlignTop={true}
          cardSize={cardSize}
          gridSize={gridSize}
          cardData={
            state.search.trim().length > 0 ? searchRecipeData : allRecipe
          }
          handleAdd={(id) => handleAddrecipeRedirect(id)}
          handleFavourite={(id, titleRecipe) =>
            handleFavouriterecipeRedirect(id, titleRecipe)
          }
          recipeDetail={(id) => redirect(id ? id : "")}
          isloading={loadMore}
        /> */}

        {loadMore && (
          <Box
            sx={{ marginTop: 15 }}
            display={"flex"}
            justifyContent={"center"}
          >
            <CircularProgress style={{ color: Color.color15 }} />
          </Box>
        )}
        {openModalLogin ? (
          <LoginModal
            open={openModalLogin}
            onClose={() => {
              loginOnClose();
            }}
          />
        ) : null}
        <FavoriteModal
          open={openFavouriteModal}
          onClickAddFavorite={() => removeFavoriteRecipe()}
          onClickRemove={() => {
            handleCloseFavourite();
            setRecipeDetailPageid("");
          }}
          onCloseHandle={() => handleCloseFavourite()}
          titleRecipeName={recipeName}
          isRemove={true}
          isLoading={removeLoading}
        />
        <DaysModal
          open={openDayModal}
          onClose={() => setOpenDayModal(false)}
          data={recipeDetailPageid}
          pageTitle="dashborad"
        />
        <SubcriptionModal
          open={subcriptionModal}
          onCloseHandle={() => setSubcriptiomModal(false)}
          upgradeNowAction={handaleSubcription}
        />
        <PaymentCard
          open={paymentModal}
          handleClose={() => {
            setPaymentModal(false);
            setSubcriptionStuts();
          }}
        />
      </Container>
    </div>
  );
};
export default Favorites;
